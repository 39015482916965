import React, { useEffect, useState } from 'react';
import { format } from 'date-fns/fp';
import { DashInput, DashDatePicker } from '../DashFields';
import { FilteringSelect } from 'shared-components';
import { Individual } from './Individual';

export const AttributeForm = ({ attr, fieldChangeHandler, onDelete, attributeErrors, isSubmitting }) => {
  const [attribute, setAttribute] = useState(attr);

  useEffect(() => {
    setAttribute(attr);
  }, [attr]);

  let field = '';
  switch (attribute.type) {
    case 'text':
      if (attribute.choices && attribute.choices.length > 0) {
        const choices = [{
          label: '-- Choose One --',
          value: ''
        }];
        attribute.choices.forEach((item, i) => {
          choices.push({
            label: attr?.labels?.length > 0 ? attribute.labels[i] : item,
            value: item
          });
        });
        field = (
          <FilteringSelect
            id={'attr-' + attribute.slug}
            name={'attr-' + attribute.slug}
            value={attribute.value}
            valueLabel={attribute.label || attribute.value}
            label={attribute.title}
            options={choices}
            onChange={(val, label) => {
              fieldChangeHandler(attribute.slug, val, label);
            }}
            isSubmitting={isSubmitting}
          />
        );
      } else {
        field = (
          <DashInput
            id={'attr-' + attribute.slug}
            name={'attr-' + attribute.slug}
            value={attribute.value}
            label={attribute.title}
            type="text"
            onChange={ev => { fieldChangeHandler(attribute.slug, ev.target.value); }}
            disabled={isSubmitting}
          />
        );
      }
      break;
    case 'number':
      field = (
        <DashInput
          id={'attr-' + attribute.slug}
          name={'attr-' + attribute.slug}
          value={attribute.value}
          label={attribute.title}
          type="number"
          onChange={ev => { fieldChangeHandler(attribute.slug, ev.target.value); }}
          disabled={isSubmitting}
        />
      );
      break;
    case 'date':
      field = (
        <DashDatePicker
          id={'attr-' + attribute.slug}
          name={'attr-' + attribute.slug}
          value={attribute.value}
          label={attribute.title}
          onChange={value => {
            fieldChangeHandler(attribute.slug, format('MM/dd/yyyy', value));
          }}
          disabled={isSubmitting}
        />
      );
      break;
    default:
      break;
  };

  const fieldError = attributeErrors[attribute.slug] || null;

  return (
    <Individual
      error={fieldError}
      required={attribute.required}
      deleteHandler={() => {
        onDelete(attribute.slug);
      }}
    >
      {field}
    </Individual>
  );
};

export default AttributeForm;

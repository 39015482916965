import * as R from 'ramda';
import { format, parseISO } from 'date-fns/fp';
import parse from 'url-parse';
import { auth } from 'lib/firebase';

export const isNotNil = R.complement(R.isNil);

export const isAuthed = user => isNotNil(user);

export const shortDate = format('P');

export const shortDateWithShortYear = format('MM/dd/yy');

export const getUrlExtension = url => parse(url).pathname.match(/(\w+)$/g)[0];

export const blockIfAgent = fn => async (...args) => {
  const idTokenResult = await auth.currentUser.getIdTokenResult();
  if (idTokenResult?.claims?.isAgent === true) {
    throw new Error('Agent Unauthorized');
  } else {
    return fn(...args);
  }
};

export const numberFormat = (number, decimals) => {
  return number.toLocaleString(
    'en-US',
    { minimumFractionDigits: decimals, maximumFractionDigits: decimals }
  );
};

export const slugify = text => {
  const cleanedText = text.replace(/[^a-zA-Z0-9 ]/g, '');
  return cleanedText.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};

export const areObjectsSame = (obj, source) => {
  return JSON.stringify(obj) === JSON.stringify(source);
};

export const padWithZeros = (num, length = 7) => {
  const zerosNeeded = length - num.toString().length;
  let zeroString = '';
  for (let i = 0; i < zerosNeeded; i++) {
    zeroString += '0';
  }
  return zeroString + num.toString();
};

export const traceZone = (zones, zoneId, list = []) => {
  const curZone = zones[zoneId];
  if (curZone) {
    list.push({
      ...curZone || {},
      zoneId
    });
    if (curZone?.parentId) {
      return traceZone(zones, curZone.parentId, list);
    }
  }
  return list;
};

export const traceZoneDown = (zones, zoneId, list = []) => {
  list.push(zoneId);
  for (const [key, val] of Object.entries(zones)) {
    if (val.parentId === zoneId && val.active !== false && !list.includes(key)) {
      list.push(key);
      list = traceZoneDown(zones, key, list);
    }
  }
  return [...new Set(list)];
};

export const convertPxToRem = pxAmount => {
  const docFontSize = document.body.currentStyle ?
    document.body.currentStyle['fontSize'] :
    document.defaultView.getComputedStyle(document.body)['font-size'];
  const fontPx = docFontSize.replace('px', '');
  return (Number(pxAmount) / Number(fontPx)).toFixed(2);
};

export const celsiusToFarenheit = (celsius, decimals = 2) => {
  return ((celsius * 9 / 5) + 32).toFixed(decimals);
};

export const cmToInches = (cm) => {
  return Number(cm * 0.393701).toFixed(2);
};

export const vpdCalc = (temp, vpair) => {
  let e = (7.5 * temp) / (237.3 + temp);
  let vpSat = (610 * (10 ** e)) / 1000;
  let vpd = vpSat - vpair;
  return vpd.toFixed(2);
};

export const sqFtCalc = (l, w) => {
  return parseFloat((Number(l) * Number(w)).toFixed(2));
};

// Merge multiple data sets to align by time series
export const mergeDataSets = (sets = [], dataRange, shownKeys) => {
  const mergedSets = [];
  const timeMerge = {};
  sets.forEach(dataSet => {
    dataSet.forEach(row => {
      switch (dataRange) {
        case 'hour':
          // related by minute
          const timeParse = format('yyyy-MM-dd_HH:mm', parseISO(row.time));
          if (!timeMerge[timeParse]) {
            timeMerge[timeParse] = [];
          }
          timeMerge[timeParse].push(row);
          break;
        case 'day':
          // related by hour
          if (!timeMerge[row.hour]) {
            timeMerge[row.hour] = [];
          }
          timeMerge[row.hour].push(row);
          break;
        case 'week':
        case 'month':
        case 'year':
          // related by day
          if (!timeMerge[row.date]) {
            timeMerge[row.date] = [];
          }
          timeMerge[row.date].push(row);
          break;
        default:
          break;
      }
    });
  });

  // Go through each key in the timeMerge object
  Object.keys(timeMerge).forEach(timeSet => {
    // Set the default return row data based on the first entry
    let curRow = {
      ...timeMerge[timeSet][0]
    };
    // Go through all the fields of the first dataset to look for keys we want to merge
    Object.keys(timeMerge[timeSet][0]).forEach(field => {
      if (shownKeys.includes(field)) {
        shownKeys.forEach(shownKey => {
          const minKey = `min_${shownKey}`;
          const maxKey = `max_${shownKey}`;
          const avgKey = `avg_${shownKey}`;
          let tmpMinVal = [];
          let tmpMaxVal = [];
          let tmpAvgVal = [];
          timeMerge[timeSet].forEach(set => {
            if (set[minKey]) {
              tmpMinVal.push(set[minKey]);
            }
            if (set[maxKey]) {
              tmpMaxVal.push(set[maxKey]);
            }
            if (set[avgKey]) {
              tmpAvgVal.push(set[avgKey]);
            }
          });

          curRow[minKey] = Math.min(...tmpMinVal);
          curRow[maxKey] = Math.max(...tmpMaxVal);
          curRow[avgKey] = tmpAvgVal.reduce((a, b) => Number(a) + Number(b), 0) / tmpAvgVal.length;
        });
      }
    });
    mergedSets.push(curRow);
  })

  return mergedSets;
};

export const lineColorByIndex = index => {
  const colors = ['#619ED6', '#6BA547', '#F7D027', '#E48F1B', '#B77EA3', '#E64345', '#60CEED', '#9CF168', '#F7EA4A', '#FBC543', '#FFC9ED', '#E6696E'];
  return colors[index % colors.length];
};

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || null;
};

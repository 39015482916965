import React from 'react';
import { Only } from 'atomic-layout';
import { Tabs } from './Tabs';
import { AccordionTabs } from './AccordionTabs';

export function DashTabs({ config, selectedId, onTabClick }) {
  return (
    <>
      <Only from="lg">
        <Tabs config={config} selectedId={selectedId} onTabClick={onTabClick} />
      </Only>
      <Only to="lg">
        <AccordionTabs
          config={config}
          selectedId={selectedId}
          onTabClick={onTabClick}
        />
      </Only>
    </>
  );
}

export default DashTabs;

import React from 'react';
import Tree from 'react-d3-tree';
import { useSelector } from 'react-redux';
import { FallBackLoader } from 'shared-components';
import 'styled-components/macro';
import { Icon } from '@grownode/ui';
import { Building4 } from '@styled-icons/remix-line/Building4';
import { Sensors } from '@styled-icons/material-outlined/Sensors';
import { Router } from '@styled-icons/remix-fill/Router';
import './HardwareTree.css';

export const HardwareTree = () => {
  const userState = useSelector((state) => state?.userRecord);
  const gateways = useSelector(state => state?.models?.gateways || null);
  const sensors = useSelector(state => state?.models?.nodes || null);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};

  if (!gateways || !sensors || !userState || !companyState) {
    return <FallBackLoader isPage={true} />;
  }

  const handleNodeClick = node => {
    console.log('NODE CLICKED');
    const attrs = node?.data?.attributes || {};
    console.log(attrs);
  };

  const customNode = props => {
    const attr = props.nodeDatum.attributes;
    let ItemIcon = Building4;
    if (attr?.item === 'gateway') {
      ItemIcon = Router;
    } else if (attr?.item === 'sensor') {
      ItemIcon = Sensors;
    }
    return (
      <g
        title={props.nodeDatum.name}
        className="icon-container"
        onClick={ev => {
          props.onNodeClick(ev);
          props.toggleNode(ev);
        }}
        width={200}
      >
        <rect></rect>
        <text>
          {props.nodeDatum.name}
        </text>
        <g transform="translate(-16, 0)">
          <Icon
            icon={ItemIcon}
            size={32}
          />
        </g>
      </g>
    );
  };

  const buildTree = () => {
    const gwKeys = Object.keys(gateways).sort();
    const sensorKeys = Object.keys(sensors).sort();
    const treeData = {
      name: currentCompany?.name,
      children: gwKeys.map(gw => {
        const gwData = gateways[gw];
        return {
          name: gwData.name,
          attributes: {
            id: gw,
            item: 'gateway',
            ts: gwData.ts
          },
          children: sensorKeys.map(sensor => {
            const item = sensors[sensor];
            const sensorGwAssignment = item?.lastSensorValues?.gatewayId || item?.gatewayId || null;
            if (sensorGwAssignment === gw) {
              return {
                name: item.name,
                attributes: {
                  id: sensor,
                  item: 'sensor',
                  isAuthed: item.isAuthed || false,
                  type: item.type,
                  ts: item.ts.seconds
                }
              }
            }
            return undefined;
          }).filter(item => { return item; })
        }
      })
    };
    return treeData;
  };

  const treeData = buildTree();

  return (
    <Tree
      data={treeData}
      collapsible={true}
      zoomable={false}
      pathFunc="diagonal"
      orientation="vertical"
      /*
      translate={{
        x: 200,
        y: 40
      }}
      */
      nodeSize={{
        x: 150,
        y: 150
      }}
      onNodeClick={handleNodeClick}
      renderCustomNodeElement={customNode}
      css={`
        .rd3t-node {
          cursor: pointer;
          fill: #777;
          stroke: none !important;
          stroke-width: 2;
        }
      `}
    />
  );
};

export default HardwareTree;

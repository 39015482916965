import React, { useRef } from 'react';
import { NewsletterSignup } from 'shared-components';
import 'styled-components/macro';
import { Button, Image, styles } from '@grownode/ui';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { Parallax } from 'react-parallax';
import { animateScroll as scroll } from 'react-scroll';

const imagePlant =
  "https://firebasestorage.googleapis.com/v0/b/grownode.appspot.com/o/assets%2Fhomepage%2FPlantPhoto.jpg?alt=media";

const promoMap = [
  {
    tagLine: 'Optimize Growth and Yield',
    blurb: 'Optimizing the growth rate and yield of your crops with GrowNode leads to a more consistent product. By utilizing real-time data, you can ensure that your yields consistently meet expectations for both quality and output, making it easier to run a successful and profitable operation.',
    isLeft: true,
    bgColor: '#5D98B8',
    color: '#FFF',
    image: 'https://firebasestorage.googleapis.com/v0/b/grownode.appspot.com/o/assets%2Fhomepage%2FSpacesChart.png?alt=media',
    left: '50%',
    top: '50%',
    circleColor: 'rgb(33, 176, 232)'
  },
  {
    tagLine: 'Identify Potential Equipment Failures',
    blurb: 'Equipment failures are often the leading cause of crop failures. The quicker the issue is addressed, the less impact it has on the yield of the crop. With GrowNode\'s real-time monitoring, you are alerted to potential equipment failures immediately, allowing you to respond quickly and minimize the impact on your crops and overall yield.',
    isLeft: false,
    bgColor: '#F1FBFF',
    color: '#474A53',
    left: '0%',
    top: '50%',
    circleColor: '#E6F4F1'
  },
  {
    tagLine: 'Join Us for our Alpha Release!',
    blurb: 'Apply to become an Alpha tester, and help us design the perfect platform to meet your specific needs. You will have VIP access to all new features - and your voice will be heard when it comes to which features offer the best user experience, and what features could improve it.',
    isLeft: true,
    bgColor: '#E6F4F1',
    color: '#474A53',
    left: '100%',
    top: '0%',
    circleColor: 'rgb(33, 176, 232)'
  },
  {
    tagLine: 'Compatible with Open-Source Hardware Platforms',
    blurb: 'GrowNode supports the open-source initiative. We started out as DIY growers ourselves, so we appreciate those who want to build their own sensors, and provide all of the necessary details regarding hardware, setup, and implementing the GrowNode communication protocol in your projects.',
    isLeft: false,
    bgColor: '#5D98B8',
    color: '#FFF',
    left: '0%',
    top: '0%',
    circleColor: 'rgb(33, 176, 232)'
  }
];

const LearnMoreBtn = () => <Button variant="tertiary" onClick={() => scroll.scrollToBottom()}>Learn More</Button>;

export function Home() {
  const newsletterRef = useRef(null);
  const isLarge = useResponsiveQuery({ from: 'md' });

  return (
    <>
      {promoMap.map((promo, i) => {
        return (
          <Parallax
            key={i}
            style={{
              background: promo.bgColor,
              borderBottom: `1px solid ${styles.colors.grayLight}`,
              boxShadow: 'rgb(63 63 68 / 5%) 40px 2px 4px 4px'
            }}
            bgImageStyle={{
              height: 'auto',
              width: 'auto'
            }}
            strength={200}
            renderLayer={(percentage) => {
              let widthHeight = 800 - (percentage * 300);
              if ((i % 2) === 1) {
                widthHeight = (percentage * 500);
              }
              return (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      background: promo.circleColor,
                      opacity: (percentage * 1),
                      left: promo.left,
                      top: promo.top,
                      borderRadius: "50%",
                      transform: "translate(-50%,-50%)",
                      width: widthHeight,
                      height: widthHeight
                    }}
                  />
                </div>
              );
            }}
          >
            <div
              css={`
                max-width: 1200px;
                margin-right: auto;
                margin-left: auto;
              `}
            >
              <Composition
                key={i}
                areas={`Left Right`}
                templateCols="50%"
                gap={64}
                padding={64}
              >
                {({ Left, Right }) => (
                  <>
                    <Left>
                      {promo.isLeft ? (
                        <div
                          css={`
                            color: ${promo.color};
                          `}
                        >
                          <h2>{promo.tagLine}</h2>
                          <p>
                            {promo.blurb}
                          </p>
                          <div css={`text-align: right; margin-top: 24px;`}>
                            <LearnMoreBtn />
                          </div>
                        </div>
                      ) : (
                        <div
                          css={`text-align: left;`}
                        >
                          {promo.image && (
                            <img alt={promo.tagLine} src={promo.image} css={`max-width: 100%;`} />
                          )}
                        </div>
                      )}
                    </Left>
                    <Right>
                      {!promo.isLeft ? (
                        <div
                          css={`
                            color: ${promo.color};
                          `}
                        >
                          <h2>{promo.tagLine}</h2>
                          <p>
                            {promo.blurb}
                          </p>
                          <div css={`text-align: left; margin-top: 24px;`}>
                            <LearnMoreBtn />
                          </div>
                        </div>
                      ) : (
                        <div
                          css={`text-align: right;`}
                        >
                          {promo.image && (
                            <img alt={promo.tagLine} src={promo.image} css={`max-width: 100%;`} />
                          )}
                        </div>
                      )}
                    </Right>
                  </>
                )}
              </Composition>
            </div>
          </Parallax>
        );
      })}
      <Parallax bgImage={imagePlant} strength={500}>
        <div style={{ height: 500 }}>
          <Composition
            ref={newsletterRef}
            areas={`
              Teaser
              Signup
            `}
            gap={8}
            marginTop={32}
            marginHorizontal="auto"
            width="1fr"
            maxWidth={isLarge ? '500px' : '100%'}
          >
            {({ Teaser, Signup }) => (
              <>
                <Teaser>
                  <Image src="https://firebasestorage.googleapis.com/v0/b/grownode.appspot.com/o/assets%2Flogo.svg?alt=media" css={`height: 100px;`} />
                </Teaser>
                <Signup>
                  <NewsletterSignup />
                </Signup>
              </>
            )}
          </Composition>
        </div>
      </Parallax>
    </>
  );
}

export default Home;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'lib/hooks';
import { useSelector } from 'react-redux';
import {
  styles,
  Link,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon,
  NavItem,
  Image
} from '@grownode/ui';
import 'styled-components/macro';
import { Composition, Box, Only, useResponsiveQuery } from 'atomic-layout';
import { MobileNav } from '../Nav/MobileNav';
import { Settings } from '@styled-icons/feather/Settings';
import { LogOut } from '@styled-icons/entypo/LogOut';

const areas = `
  mobilenavigation logo session
`;

const areasLg = `
  logo session
`;

export function Header({ mobileDrawerOpen = false, setMobileDrawerOpen }) {
  const { user, signout } = useAuth();
  const firstName = useSelector(state => state.userRecord?.firstName);
  const isSmall = useResponsiveQuery({ to: 'md' });
  const isLoggedIn = user !== null;
  return (
    <div
      css={`
        background: ${styles.colors.primaryBlue};
        width: 100%;
        height: 64px;
        position: ${isSmall ? 'fixed' : 'inherit'};
        z-index: 1000;
      `}
    >
      <Composition
        areas={areas}
        areasLg={areasLg}
        templateCols="auto 1fr auto"
        templateColsLg="1fr auto"
        templateRows="64px"
        alignItems="middle"
      >
        {({ Mobilenavigation, Logo, Session }) => (
          <>
            <Only to="lg">
              <Mobilenavigation>
                <MobileNav open={mobileDrawerOpen} setMobileDrawerOpen={setMobileDrawerOpen} />
              </Mobilenavigation>
            </Only>
            <Logo>
              <Box
                marginTop={8}
                marginLeft={16}
              >
                <NavLink
                  to={isLoggedIn ? '/dashboard' : '/'}
                  css={`display: inline-block;`}
                >
                  <Image src="https://firebasestorage.googleapis.com/v0/b/grownode.appspot.com/o/assets%2Flogo.svg?alt=media" css={`height: 48px;`} />
                </NavLink>
              </Box>
            </Logo>
            {!isLoggedIn && (
              <>
                <Session>
                  <Box
                    flex
                    alignItems="center"
                    height="100%"
                    marginRight={16}
                  >
                    <NavLink
                      to="/login"
                      css={`
                        font-size: 13px;
                        font-weight: 400;
                        padding-left: 0;
                        color: #FFF;
                        text-decoration: none;

                        &:hover {
                          opacity: 0.7;
                        }
                      `}
                    >
                      Login
                    </NavLink>
                    {/*
                    <span
                      css={`margin-left: 8px; margin-right: 8px; color: #FFF;`}
                    >|</span>
                    <NavLink
                      to="/activate"
                      css={`
                        font-size: 13px;
                        font-weight: 400;
                        padding-left: 0;
                        color: #FFF;
                        text-decoration: none;

                        &:hover {
                          opacity: 0.7;
                        }
                      `}
                    >
                      Sign Up
                    </NavLink>
                    */}
                  </Box>
                </Session>
              </>
            )}
            {isLoggedIn && (
              <>
                <Session>
                  {firstName && (
                    <Dropdown
                      content={
                        <>
                          <DropdownItem as={NavLink} to="/settings">
                            <Icon
                              icon={Settings}
                              size={20}
                              color="primaryBlue"
                              css={`
                                cursor: pointer;
                              `}
                            />
                            <span style={{ marginLeft: 8 }}>Account Settings</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={signout}
                            css={`cursor: pointer;`}
                          >
                            <Icon
                              icon={LogOut}
                              size={20}
                              color="primaryBlue"
                              css={`cursor: pointer;`}
                            />
                            <span style={{ marginLeft: 8 }}>Logout</span>
                          </DropdownItem>
                          <DropdownSeparator />
                        </>
                      }
                      placement="bottom-end"
                    >
                      <Box
                        flex
                        alignItems="center"
                        css={`cursor: pointer;`}
                        height="100%"
                      >
                        <Link
                          as={NavItem}
                          css={`
                            font-size: 13px;
                            font-weight: 400;
                            margin-left: 8px;
                            padding-left: 0;
                            color: #FFF;
                          `}
                        >
                          {firstName}
                        </Link>
                      </Box>
                    </Dropdown>
                  )}
                </Session>
              </>
            )}
          </>
        )}
      </Composition>
    </div>
  );

}

export default Header;

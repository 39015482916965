import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { Icon } from '@grownode/ui';
import { ArrowsAngleExpand } from '@styled-icons/bootstrap/ArrowsAngleExpand';
import { ArrowsAngleContract } from '@styled-icons/bootstrap/ArrowsAngleContract';

export const Tile = ({
  xlWidth = '13.3%',
  desktopWidth = '23.3%',
  tabletWidth = '32.3%',
  mobileWidth = '47%',
  isExpandable = true,
  title,
  defaultExpanded = false,
  onClick,
  content = '',
  expandedContent = '',
  isTitleBlock = false,
  hasSub = true
}) => {
  const isSmall = useResponsiveQuery({ to: 'md' });
  const isMedium = useResponsiveQuery({ to: 'lg' });
  const isLarge = useResponsiveQuery({ to: 'xl' });
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [tileWidth, setTileWidth] = useState(xlWidth);

  useEffect(() => {
    if (isSmall) {
      setTileWidth(mobileWidth);
    } else if (isMedium) {
      setTileWidth(tabletWidth);
    } else if (isLarge) {
      setTileWidth(desktopWidth);
    } else {
      setTileWidth(xlWidth);
    }
  }, [isSmall, isMedium, isLarge, mobileWidth, tabletWidth, desktopWidth, xlWidth]);

  return (
    <div
      css={`
        display: inline-block;
        width: ${expanded ? 'calc(100% - 4px)' : tileWidth};
        transition: width 250ms ease-in-out;
      `}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      <Composition
        areas={`
          Title
          Content
        `}
        templateCols="auto"
        templateRows="24px auto"
        gap={0}
        marginBottom={8}
        marginTop={8}
        padding={8}
        minHeight={hasSub ? '80px' : '40px'}
        css={`
          border-radius: 4px;
          background: #FFF;
          box-shadow: rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(63 63 68 / 15%) 0px 1px 2px 0px;
        `}
      >
        {({ Title, Content }) => (
          <>
            <Title>
              <div
                css={`
                  display: inline-block;
                  position: absolute;
                  margin-top: -22px;
                  font-size: 18px;
                `}
              >
                {title}
              </div>
              <div
                css={`
                  text-align: right;
                  z-index: 2;
                  position: relative;
                `}
              >
                {isExpandable && (
                  <Icon
                    icon={expanded ? ArrowsAngleContract : ArrowsAngleExpand}
                    size={12}
                    css={`
                      cursor: pointer;
                    `}
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  />
                )}
                {(!isExpandable && !isTitleBlock) && (<>&nbsp;</>)}
              </div>
            </Title>
            <Content>
              <div
                css={`
                  min-width: 100%;
                  margin-top: -16px;
                `}
              >
                {!expanded && (
                  <>{content}</>
                )}
                {expanded && (
                  <>{expandedContent}</>
                )}
              </div>
            </Content>
          </>
        )}
      </Composition>
    </div>
  );
};

export default Tile;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@grownode/ui';

const messages = {
	'auth/user-not-found': 'Incorrect Email/Password, please try again.',
	'auth/wrong-password': 'Incorrect Email/Password, please try again.',
	CLIENT_ALREADY_REGISTERED: (
		<span>
			A GrowNode user already exists for that email, did you{' '}
			<Link as={NavLink} to="/forgot-password">
				forget your password
			</Link>
			?
		</span>
	),
	UNHANDLED_ERROR: 'Oops! Something went wrong!'
};

export const FormError = ({ errorCode }) => {
	return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

import React, { useState } from 'react';
import {
  Button,
  Icon,
  DialogModal,
  DialogModalHeader,
  DialogModalBody,
  DialogModalCloser,
  styles
} from '@grownode/ui';
import { FallBackLoader } from 'shared-components';
import { Composition, Box } from 'atomic-layout';
import { useNavigate } from 'react-router';
import { deleteZone } from 'lib/api';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Close } from '@styled-icons/evaicons-solid/Close';

export function ActionToolbar({
  onSelect,
  spaces,
  thisSpace = null,
  shown = ['spaces', 'plants', 'hardware', 'people', 'tasks', 'editSpace', 'archive'],
  subSpaces
}) {
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.userRecord);
  const [modalOpen, setModalOpen] = useState(false);
  const [isZoneDeleting, setIsZoneDeleting] = useState(false);
  const allItems = ['spaces', 'plants', 'hardware', 'people', 'tasks', 'editSpace', 'archive'];

  const currentSpace = spaces ? {
    id: thisSpace,
    ...spaces[thisSpace]
  } : {};

  const toggleDeleteConfirm = () => {
    setModalOpen(!modalOpen);
  };

  const handleClick = tool => {
    if (tool === 'EditSpace') {
      navigate('/spaces/' + currentSpace?.id + '/edit');
    } else if (tool === 'Archive') {
      setModalOpen(true);
    } else if (typeof onSelect === 'function') {
      onSelect(tool);
    }
  };

  const rows = [];
  allItems.forEach(item => {
    if (shown.includes(item)) {
      rows.push('auto');
    } else {
      rows.push('0px');
    }
  });

  const smallAreas = subSpaces?.length > 0 ? `
    Spaces
    Plants
    Hardware
    People
    Tasks
    EditSpace
    Archive
  ` : `Spaces Plants Hardware People Tasks EditSpace Archive`;

  return (
    <Composition
      className="ActionToolbar"
      areas={smallAreas}
      areasMd="Spaces Plants Hardware People Tasks EditSpace Archive"
      templateRows={rows.join(' ')}
      templateCols="auto"
      templateColsMd={rows.join(' ')}
      justifyItems="center"
      gap={0}
      css={`
        font-size: ${subSpaces?.length > 0 ? 12 : 11}px;
        margin-bottom: 8px;
        & > div {
          background: ${styles.colors.grayLightest};
          cursor: pointer;
          padding: 2px;
          text-align: center;
          width: 100%;

          &:hover {
            background: rgb(228 223 233 / 80%);
          }
        }
      `}
    >
      {({ Spaces, Plants, Hardware, People, Tasks, EditSpace, Archive }) => (
        <>
          {shown.includes('spaces') && (
            <Spaces width="100%">
              <div
                onClick={() => handleClick('Spaces')}
              >
                Add Spaces
              </div>
            </Spaces>
          )}
          {shown.includes('plants') && (
            <Plants width="100%">
              <div
                onClick={() => handleClick('Plants')}
              >
                Add Plants
              </div>
            </Plants>
          )}
          {shown.includes('hardware') && (
            <Hardware width="100%">
              <div
                onClick={() => handleClick('Hardware')}
              >
                Add Hardware
              </div>
            </Hardware>
          )}
          {/*shown.includes('people') && (
            <People width="100%">
              <div
                onClick={() => handleClick('People')}
              >
                Add People
              </div>
            </People>
          )*/}
          {shown.includes('tasks') && (
            <Tasks width="100%">
              <div
                onClick={() => handleClick('Tasks')}
              >
                Add Tasks
              </div>
            </Tasks>
          )}
          {(currentSpace && shown.includes('editSpace')) && (
            <EditSpace width="100%">
              <div
                onClick={() => handleClick('EditSpace')}
              >
                Edit Space
              </div>
            </EditSpace>
          )}
          {(currentSpace && shown.includes('archive')) && (
            <Archive width="100%">
              {currentSpace.active === false && (
                <Box
                  css={`font-size: 11px; text-align: right;`}
                >
                  <em>This space has been archived.</em>
                </Box>
              )}
              {currentSpace.active !== false && (
                <div
                  onClick={() => handleClick('Archive')}
                >
                  Archive Space
                </div>
              )}
            </Archive>
          )}
          <DialogModal
            isOpen={modalOpen}
            onBackgroundClick={toggleDeleteConfirm}
            onEscapeKeydown={toggleDeleteConfirm}
            css={`width: 30rem; height: 11rem;`}
          >
            <DialogModalHeader>
              Are you sure?
              <DialogModalCloser onClick={() => setModalOpen(false)}>
                <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
              </DialogModalCloser>
            </DialogModalHeader>
            <DialogModalBody
              css={`padding: 16px;`}
            >
              {isZoneDeleting && (
                <FallBackLoader />
              )}
              {!isZoneDeleting && (
                <>
                  Are you sure you want to archive this space and all it's child spaces? (This cannot be undone)
                  <Box css={`text-align: right; padding-right: 8px !important; padding-bottom: 8px !important; margin-top: 20px;`}>
                    <span css={`margin-right: 8px;`}>
                      <Button
                        type="button"
                        variant="tertiary"
                        onClick={() => {
                          toggleDeleteConfirm();
                        }}
                      >
                        Cancel
                      </Button>
                    </span>
                    <Button
                      type="button"
                      variant="tertiary"
                      onClick={async () => {
                        setIsZoneDeleting(true);
                        await deleteZone({
                          zoneId: currentSpace.id,
                          companyId: userState?.defaultCompany
                        });
                        setIsZoneDeleting(false);
                        toggleDeleteConfirm();
                      }}
                    >
                      Archive Now
                    </Button>
                  </Box>
                </>
              )}
            </DialogModalBody>
          </DialogModal>
        </>
      )}
    </Composition>
  );
};

export default ActionToolbar;

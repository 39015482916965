import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { colors } from "../shared/styles";
export var Tooltip = _styled(ReactTooltip).attrs(function (props) {
  return _objectSpread({
    type: "dark",
    scrollHide: true,
    effect: "solid",
    delayHide: 1000,
    borderColor: props.theme.colors.secondaryGreen,
    backgroundColor: props.theme.colors.plain,
    clickable: true
  }, props);
}).withConfig({
  displayName: "Tooltip",
  componentId: "sc-10cvlki-0"
})(["color:", " !important;background:", " !important;font-size:14px !important;border:2px solid ", " !important;padding:10px;border-radius:7px;max-width:300px;&.place-bottom{&::before{border-left:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-right:none !important;top:-8px;margin-left:-7px;}}&.place-top{&::before{border-right:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-left:none !important;margin-left:-6px;bottom:-7px;}}&.place-left{&::before{border-right:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-left:none !important;margin-top:-6px;right:-7px;}}&.place-right{&::before{border-left:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-right:none !important;margin-top:-6px;left:-7px;}}&::before{z-index:100;width:10px;height:10px;background:", ";transform:rotate(45deg);}"], function (props) {
  return props.theme.colors.primaryGray;
}, function (props) {
  return props.theme.colors.plain;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.secondaryGreen;
}, function (props) {
  return props.theme.colors.plain;
});
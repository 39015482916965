import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import 'styled-components/macro';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { savePlant } from 'lib/api/inventory';
import { areObjectsSame, padWithZeros } from 'lib/utils';
import { FallBackLoader, DashInput, DashForm, Attributes, ZoneParentSelector } from 'shared-components';
import { useSlideOut } from 'lib/hooks';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { FormError } from './components';
import { PLANT_ATTRIBUTES } from '../../constants';
import {
  LoadingButton,
  FormMessage,
  Icon,
  Heading
} from '@grownode/ui';

export const EditPlants = ({
  plantParam = 'new',
  defaultSpace,
  onSave
}) => {
  const { setSlideOutOpen } = useSlideOut();
  const [plantData, setPlantData] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [attributeErrors, setAttributeErrors] = useState({});
  const [error, setError] = useState();
  const userState = useSelector((state) => state?.userRecord);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const plants = useSelector((state) => state?.models?.inventory);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};
  const [currentPlant, setCurrentPlant] = useState(plantParam);

  useEffect(() => {
    setCurrentPlant(plantParam);
  }, [plantParam]);

  useEffect(() => {
    if (plants && currentCompany) {
      if (plantParam !== 'new') {
        setPlantData({
          plantId: plantParam,
          spaceId: plants[plantParam].spaceId,
          strainName: plants[plantParam].strainName,
          growthPhase: plants[plantParam].growthPhase,
          plantType: plants[plantParam].plantType,
          batchId: plants[plantParam].batchId,
          numPlants: 1,
          attributes: plants[plantParam].attributes
        });
      } else {
        const plantId = uuidv4();
        const batchIdPrefix = currentCompany?.batchIdPrefix || '?';
        const batchIdIncrement = currentCompany?.batchIdIncrement || 0;
        setPlantData({
          plantId: plantId,
          spaceId: defaultSpace ? defaultSpace : "",
          strainName: "",
          growthPhase: "",
          plantType: "",
          batchId: `${batchIdPrefix}-${padWithZeros(batchIdIncrement + 1)}`,
          numPlants: 1,
          attributes: {}
        });
      }
    }
  }, [plants, plantParam, currentCompany, defaultSpace]);

  const handleAttributeChange = useCallback(attrs => {
    if (plantData) {
      const sameObj = areObjectsSame(attrs, plantData.attributes);
      if (!sameObj) {
        setPlantData({
          ...plantData,
          attributes: attrs
        });
      }
    }
  }, [plantData]);

  const areAttributesValid = () => {
    const attributes = plantData.attributes;
    let hasErrors = false;
    const attributeErr = {};
    for (const [key, value] of Object.entries(attributes)) {
      if (value?.choices?.length > 0 && !value?.choices.includes(value.value)) {
        attributeErr[key] = 'Invalid Selection';
        hasErrors = true;
      } else if (value.value.trim() === '') {
        attributeErr[key] = 'Required';
        hasErrors = true;
      }
    }
    setAttributeErrors(attributeErr);
    return !hasErrors;
  };

  // Combining custom company attributes with canned ones and sorting
  const finalAttributeList = currentCompany ? [
    ...currentCompany.plantCustomAttributes,
    ...PLANT_ATTRIBUTES
  ] : PLANT_ATTRIBUTES;

  const sortedAttributeList = finalAttributeList.sort((a, b) => {
    if (a.title === b.title) { return 0; }
    return a.title > b.title ? 1 : -1;
  });

  if (!plantData || !companyState || !userState) {
    return (
      <FallBackLoader isPage />
    );
  }

  return (
    <>
      <Heading>
        {plantParam === 'new' ? 'Add New' : 'Edit'} Plant
      </Heading>
      <Formik
        initialValues={{
          ts: new Date().toDateString(),
          spaceId: plantData.spaceId,
          batchId: plantData.batchId,
          plantId: plantData.plantId,
          plantType: plantData.plantType,
          strainName: plantData.strainName,
          growthPhase: plantData.growthPhase,
          numPlants: plantData.numPlants,
        }}
        validationSchema={Yup.object().shape({
          strainName: Yup.string().required("Required"),
          plantType: Yup.string().required("Required")
        })}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          setIsSaved(false);
          try {
            if (areAttributesValid()) {
              await savePlant({
                spaceId: values.spaceId,
                batchId: values.batchId,
                plantId: values.plantId,
                plantType: values.plantType,
                strainName: values.strainName,
                growthPhase: values.growthPhase,
                companyId: userState.defaultCompany,
                numPlants: values.numPlants,
                attributes: plantData.attributes,
                isNewPlant: currentPlant === 'new'
              });
              setIsSaved(true);
              if (typeof onSave === 'function') {
                onSave(currentPlant, values);
              }

              window.setTimeout(() => {
                setSlideOutOpen(false);
                setIsSaved(false);
              }, 5000);
            }
            actions.setSubmitting(false);
          } catch (e) {
            setError(e.message);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => {
          return (
            <DashForm>
              {isSaved && (
                <FormMessage variant="success">
                  Your plant has been successfully saved!
                </FormMessage>
              )}
              {error && (
                <FormMessage variant="error">
                  <FormError errorCode={error} />
                </FormMessage>
              )}
              <DashInput
                id="strainName"
                name="strainName"
                label="Strain Name"
                disabled={isSubmitting}
              />
              <DashInput
                id="plantType"
                name="plantType"
                label="Plant Type"
                disabled={isSubmitting}
              />
              <DashInput
                id="batchId"
                name="batchId"
                label="Batch ID"
                disabled={isSubmitting}
              />
              <DashInput
                id="growthPhase"
                name="growthPhase"
                label="Growth Phase"
                disabled={isSubmitting}
              />
              <DashInput
                id="numPlants"
                name="numPlants"
                label="Number of Plants"
                type="number"
                disabled={isSubmitting || currentPlant !== 'new'}
              />
              <ZoneParentSelector
                selectedParent={values.spaceId}
                setter={(val) => {
                  setFieldValue('spaceId', val);
                }}
                isSubmitting={isSubmitting}
                titleText="Space"
              />
              <hr css={`width: 100%;`} />
              <strong>Plant Attributes</strong>
              {plantData && (
                <Attributes
                  plantData={plantData}
                  attributeList={sortedAttributeList}
                  onChange={handleAttributeChange}
                  attributeErrors={attributeErrors}
                />
              )}
              <LoadingButton
                type="submit"
                variant="tertiary"
                height={40}
                width="100%"
                isLoading={isSubmitting}
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                disabled={!isValid}
              >
                Save Plant
              </LoadingButton>
            </DashForm>
          )
        }}
      </Formik>
    </>
  );

};

export default EditPlants;

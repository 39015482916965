import React, { useState } from 'react';
import 'styled-components/macro';
import { styles } from '@grownode/ui';
import { lineColorByIndex } from 'lib/utils';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import PinControl from 'shared-components/PinControl';

export function SeriesChart({
  data,
  lines = [],
  dataKey = 'time',
  valueKey = '',
  chartRange = '',
  min = 0,
  max = 'auto',
  unit = '',
  space,
  index,
  individual = false
}) {
  const [unSelectedSensors, setUnSelectedSensors] = useState([]);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          css={`
            background: #FFF;
            opacity: 1;
            padding: 4px;
            font-size: 12px;
            border: 1px solid #CCC;
            border-radius: 4px;
          `}
        >
          <div css={`text-align: center;`}>
            {payload[0].payload?.simpleDateYear} - <strong>{label}</strong>
          </div>
          <table>
            <tbody>
              {payload.map((item, i) => {
                const { sensorNames } = item?.payload;
                const sensorId = item?.name.replace(`-${chartRange}-${valueKey}`, '');
                const sensorName = sensorNames[sensorId] || sensorId;
                const finalValue = item.name.indexOf('mVPD') > -1 ? Number(item.value).toFixed(2) : Number(item.value).toFixed(1);
                return (
                  <tr
                    key={i}
                    css={`
                      color: ${item.stroke};
                    `}
                  >
                    <td>
                      <strong>{sensorName}</strong>:{' '}
                    </td>
                    <td>
                      {finalValue}{unit}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={12} x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y - 12.5})`}>
        <text fontSize={12} x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {Number(payload.value).toFixed(1)}{unit}
        </text>
      </g>
    );
  };

  const CustomizedDot = ({ cx, cy, stroke, payload, value }) => {
    return null;
  };

  const handleSensorSelection = sensorId => {
    if (unSelectedSensors.includes(sensorId)) {
      const newSensorLines = unSelectedSensors.filter(sensor => sensor !== sensorId);
      setUnSelectedSensors(newSensorLines);
    } else {
      setUnSelectedSensors([
        ...unSelectedSensors,
        sensorId
      ]);
    }
  };

  lines.sort((a, b) => {
    if (a?.sensorName === b?.sensorName) {
      return 0;
    }
    return a?.sensorName > b?.sensorName ? 1 : -1;
  });

  data.sort((a, b) => {
    if (a?.time === b?.time) {
      return 0;
    }
    return a?.time > b?.time ? 1 : -1;
  });

  return (
    <div className="SeriesChart" style={{ background: '#FFF' }}>
      <div
        css={`
          text-align: right;
        `}
      >
        {lines.map((line, i) => (
          <span
            css={`
              display: inline-block;
              margin: 4px;
              cursor: pointer;
              opacity: ${unSelectedSensors.includes(line?.sensorId) ? 0.5 : 1};

              &:hover {
                opacity: ${unSelectedSensors.includes(line?.sensorId) ? 0.6 : 0.8};
              }
            `}
            onClick={() => handleSensorSelection(line?.sensorId)}
            key={i}
          >
            <span
              css={`
                display: inline-block;
                border: 1px solid ${styles.colors.primaryGray};
                background: ${lineColorByIndex(i)};
                height: 12px;
                width: 12px;
                margin-right: 4px;
              `}
            />
            <span
              css={`
                display: inline-block;
                font-size: 12px;
                line-height: 14px;
                vertical-align: top;
                margin-top: 2px;
              `}
            >
              {line?.sensorName}
            </span>
          </span>
        ))}
        <PinControl individual={individual} spaceId={space} sensorIds={lines.map(line => line.sensorId)} sensorDataKey={valueKey} index={index} />
      </div>
      <ResponsiveContainer
        width="99%"
        height={150}
      >
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          {lines.map((line, i) => {
            if (unSelectedSensors.includes(line?.sensorId)) {
              return null;
            }
            return (
              <Line
                key={i}
                type="monotone"
                dataKey={line.key}
                stroke={lineColorByIndex(i)}
                dot={<CustomizedDot />}
              />
            );
          })}
          <CartesianGrid
            stroke="#ccc"
            strokeDasharray="5 5"
          />
          <XAxis
            dataKey={dataKey}
            tick={<CustomizedXAxisTick />}
            xAxisId="0"
          />
          <YAxis
            tick={<CustomizedYAxisTick />}
            type="number"
            domain={[min, max]}
            unit={unit}
          />
          <Tooltip
            allowEscapeViewBox={{ x: false, y: false }}
            content={<CustomTooltip />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SeriesChart;

import _taggedTemplateLiteral from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from "styled-components";
import { colors } from "../shared/styles";
export var Pagination = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\ttext-align: center;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: center;\n\tfont-family: \"Open Sans\", sans-serif;\n"])));
export var PaginationItem = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\ttext-align: center;\n\tcursor: ", ";\n\theight: 32px;\n\tline-height: 32px;\n\tfont-size: 11px;\n\tcolor: ", ";\n\tbackground: ", ";\n\tmargin: 0px 2px;\n\twidth: 32px;\n\tborder: 1px solid\n\t\t", ";\n\t", "\n"])), function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  if (props.selected === true) {
    return colors.plain;
  } else if (props.disabled === true) {
    return colors.grayRegular;
  } else {
    return colors.secondaryBlueLight;
  }
}, function (props) {
  return props.selected ? colors.secondaryBlueLight : colors.plain;
}, function (props) {
  return props.selected ? colors.secondaryBlueLight : colors.grayRegular;
}, function (props) {
  if (props.ellipsis === true) {
    return "border: none;width: auto;font-size: 13px;";
  }
});
export var PaginationNav = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\ttext-align: center;\n\tcursor: ", ";\n\theight: 32px;\n\tline-height: 32px;\n\tfont-size: 11px;\n\tcolor: ", ";\n\tmargin: 0px 5px;\n"])), function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.disabled ? "#dcdcdc" : "#21b0e8";
});
export const MODELS = ['gateways', 'nodes', 'zones', 'inventory'];

export const SENSORS = {
  1: 'Temperature/Humidity/Light Sensor Node (ARD)',
  2: 'Temperature/Humidity/Light Sensor Node (ESP32)',
  3: 'Temperature/Humidity/CO2 Sensor Node (ARD)',
  4: 'Fanstel Temperature/Humidity BLE Sensor',
  5: 'Canopy Height Node (ARD)',
  6: 'Canopy Control Node',
  7: 'Canopy Height Node (ESP32)',
  8: 'Temperature/Humidity/CO2 Sensor Node (ESP32)',
  10: 'Switch Node'
};

export const LABELS = {
  temp: 'Temperature',
  hum: 'Humidity',
  co2: 'CO²',
  eco2: 'CO²',
  vpd: 'Vapor Pressure Deficit',
  distance: 'Canopy Height',
  lux: 'Light Intensity',
  hin: 'Heat Index',
  vpair: 'Ambient Vapor Pressure',
  mVPD: 'Vapor Pressure Deficit (modified)',
  dp: 'Dew Point',
  rssi: 'Relative Signal Strength Indicator',
  signal: 'Connection Quality',
  batt: 'Battery Level',
  tvoc: 'Total Volatile Organic Chemicals',
};

export const UNITS = {
  temp: '°',
  hum: '%',
  co2: 'ppm',
  eco2:'ppm',
  vpd: 'kPa',
  mVPD: 'kPa',
  distance: 'cm',
  lux: 'Lux',
  hin: '°',
  vpair: 'kPa',
  dp: '°',
  rssi: 'Decibels',
  signal: 'Retries',
  batt: 'Volts',
  tvoc: 'ppb'
};


export const MIN_VALUES = {
  temp: 0,
  hum: 0,
  co2: 0,
  eco2: 0,
  vpd: 0,
  distance: 0,
  lux: 0,
  hin: 0,
  vpair: 0,
  dp: 0,
  rssi: -100,
  signal: 15,
  batt: 0,
  tvoc: 0
};

export const MAX_VALUES = {
  temp: 100,
  hum: 100,
  co2: 3000,
  eco2: 8192,
  vpd: 5,
  distance: 100,
  lux: 50000,
  hin: 100,
  vpair: 10,
  dp: 80,
  rssi: 0,
  signal: 0,
  batt: 3.05,
  tvoc: 1187
};

export const SPACE_TYPES = [
  {
    label: 'Primary Location',
  },
  {
    label: 'Grow Room'
  },
  {
    label: 'Table',
  },
  {
    label: 'Raised Bed',
  },
  {
    label: 'Floor Space'
  },
  {
    label: 'Other',
  }
];

export const PLANT_ATTRIBUTES = [
  {
    type: 'text',
    slug: 'containerType',
    title: 'Container Type',
    choices: [
      'Red Cup',
      'Plastic Pot',
      'Fabric Grow Bag',
      'Mesh Grow Bag',
      'Air Pot',
      'Plastic Mesh/Net Pot',
      'Cloner',
      'Other'
    ]
  },
  {
    type: 'text',
    slug: 'growMedium',
    title: 'Grow Medium',
    choices: [
      'Soil',
      'Organic Soil',
      'Soilless',
      'Hydroponic',
      'Other'
    ]
  },
  {
    type: 'text',
    slug: 'mediumType',
    title: 'Medium Type',
    choices: [
      'Peat Moss',
      'Coco Coir',
      'Peat/Coco',
      'Grow Rock',
      'Expanded Clay',
      'Pro Mix'
    ]
  },
  {
    type: 'text',
    slug: 'hydroponicsType',
    title: 'Hydroponics Type',
    choices: [
      'Ebb and Flow',
      'DWC/RDWC',
      'NFT',
      'Aeroponic',
      'Drip'
    ]
  },
  {
    type: 'text',
    slug: 'containerSize',
    title: 'Container Size',
    choices: [
      'Red Cup',
      '2" Round',
      '3" Round',
      '4" Round',
      '5.5" Round',
      '6" Round',
      '8" round',
      '10" round',
      '12" round 1 gal',
      '2 gal',
      '3 gal',
      '5 gal',
      '7 gal',
      '10 gal',
      '15 gal',
      '20 gal',
      '25 gal',
      '30 gal',
      '40 gal',
      '45 gal',
      '65 gal',
      '100 gal',
      '150 gal',
      '200 gal',
      'Raised Bed',
      'Other'
    ]
  },
  {
    type: 'text',
    slug: 'motherPlantName',
    title: 'Mother Plant Name'
  },
  {
    type: 'text',
    slug: 'motherPlantID',
    title: 'Mother Plant ID'
  },
  {
    type: 'text',
    slug: 'seedBatchName',
    title: 'Seed Batch Name'
  },
  {
    type: 'text',
    slug: 'seedBatchID',
    title: 'Seed Batch ID'
  },
  {
    type: 'date',
    slug: 'transplantDate',
    title: 'Transplant Date'
  },
  {
    type: 'text',
    slug: 'transplantContainerType',
    title: 'Transplant Container Type',
    choices: [
      'Red Cup',
      'Plastic Pot',
      'Fabric Grow Bag',
      'Mesh Grow Bag',
      'Air Pot',
      'Plastic Mesh/Net Pot',
      'Cloner',
      'Other'
    ]
  },
  {
    type: 'text',
    slug: 'transplantContainerSize',
    title: 'Transplant Container Size',
    choices: [
      'Red Cup',
      '2" Round',
      '3" Round',
      '4" Round',
      '5.5" Round',
      '6" Round',
      '8" round',
      '10" round',
      '12" round 1 gal',
      '2 gal',
      '3 gal',
      '5 gal',
      '7 gal',
      '10 gal',
      '15 gal',
      '20 gal',
      '25 gal',
      '30 gal',
      '40 gal',
      '45 gal',
      '65 gal',
      '100 gal',
      '150 gal',
      '200 gal',
      'Raised Bed',
      'Other'
    ]
  },
  {
    type: 'date',
    slug: 'geneticStartDate',
    title: 'Genetic Start Date',
  }
];

export const TIME_ZONES = {
  'Etc/GMT+12': ['-12:00', '-12'],
  'Etc/GMT+11': ['-11:00', '-11'],
  'Pacific/Honolulu': ['-10:00', '-10'],
  'America/Anchorage': ['-09:00', '-9'],
  'America/Santa_Isabel': ['-08:00', '-8'],
  'America/Los_Angeles': ['-08:00', '-8'],
  'America/Chihuahua': ['-07:00', '-7'],
  'America/Phoenix': ['-07:00', '-7'],
  'America/Denver': ['-07:00', '-7'],
  'America/Guatemala': ['-06:00', '-6'],
  'America/Chicago': ['-06:00', '-6'],
  'America/Regina': ['-06:00', '-6'],
  'America/Mexico_City': ['-06:00', '-6'],
  'America/Bogota': ['-05:00', '-5'],
  'America/Indiana/Indianapolis': ['-05:00', '-5'],
  'America/New_York': ['-05:00', '-5'],
  'America/Caracas': ['-04:30', '-4.5'],
  'America/Halifax': ['-04:00', '-4'],
  'America/Asuncion': ['-04:00', '-4'],
  'America/La_Paz': ['-04:00', '-4'],
  'America/Cuiaba': ['-04:00', '-4'],
  'America/Santiago': ['-04:00', '-4'],
  'America/St_Johns': ['-03:30', '-3.5'],
  'America/Sao_Paulo': ['-03:00', '-3'],
  'America/Godthab': ['-03:00', '-3'],
  'America/Cayenne': ['-03:00', '-3'],
  'America/Argentina/Buenos_Aires': ['-03:00', '-3'],
  'America/Montevideo': ['-03:00', '-3'],
  'Etc/GMT+2': ['-02:00', '-2'],
  'Atlantic/Cape_Verde': ['-01:00', '-1'],
  'Atlantic/Azores': ['-01:00', '-1'],
  'Africa/Casablanca': ['+00:00', '+0'],
  'Atlantic/Reykjavik': ['+00:00', '+0'],
  'Europe/London': ['+00:00', '+0'],
  'Etc/GMT': ['+00:00', '+0'],
  'Europe/Berlin': ['+01:00', '+1'],
  'Europe/Paris': ['+01:00', '+1'],
  'Africa/Lagos': ['+01:00', '+1'],
  'Europe/Budapest': ['+01:00', '+1'],
  'Europe/Warsaw': ['+01:00', '+1'],
  'Africa/Windhoek': ['+01:00', '+1'],
  'Europe/Istanbul': ['+02:00', '+2'],
  'Europe/Kiev': ['+02:00', '+2'],
  'Africa/Cairo': ['+02:00', '+2'],
  'Asia/Damascus': ['+02:00', '+2'],
  'Asia/Amman': ['+02:00', '+2'],
  'Africa/Johannesburg': ['+02:00', '+2'],
  'Asia/Jerusalem': ['+02:00', '+2'],
  'Asia/Beirut': ['+02:00', '+2'],
  'Asia/Baghdad': ['+03:00', '+3'],
  'Europe/Minsk': ['+03:00', '+3'],
  'Asia/Riyadh': ['+03:00', '+3'],
  'Africa/Nairobi': ['+03:00', '+3'],
  'Asia/Tehran': ['+03:30', '+3.5'],
  'Europe/Moscow': ['+04:00', '+4'],
  'Asia/Tbilisi': ['+04:00', '+4'],
  'Asia/Yerevan': ['+04:00', '+4'],
  'Asia/Dubai': ['+04:00', '+4'],
  'Asia/Baku': ['+04:00', '+4'],
  'Indian/Mauritius': ['+04:00', '+4'],
  'Asia/Kabul': ['+04:30', '+4.5'],
  'Asia/Tashkent': ['+05:00', '+5'],
  'Asia/Karachi': ['+05:00', '+5'],
  'Asia/Colombo': ['+05:30', '+5.5'],
  'Asia/Kolkata': ['+05:30', '+5.5'],
  'Asia/Kathmandu': ['+05:45', '+5.75'],
  'Asia/Almaty': ['+06:00', '+6'],
  'Asia/Dhaka': ['+06:00', '+6'],
  'Asia/Yekaterinburg': ['+06:00', '+6'],
  'Asia/Yangon': ['+06:30', '+6.5'],
  'Asia/Bangkok': ['+07:00', '+7'],
  'Asia/Novosibirsk': ['+07:00', '+7'],
  'Asia/Krasnoyarsk': ['+08:00', '+8'],
  'Asia/Ulaanbaatar': ['+08:00', '+8'],
  'Asia/Shanghai': ['+08:00', '+8'],
  'Australia/Perth': ['+08:00', '+8'],
  'Asia/Singapore': ['+08:00', '+8'],
  'Asia/Taipei': ['+08:00', '+8'],
  'Asia/Irkutsk': ['+09:00', '+9'],
  'Asia/Seoul': ['+09:00', '+9'],
  'Asia/Tokyo': ['+09:00', '+9'],
  'Australia/Darwin': ['+09:30', '+9.5'],
  'Australia/Adelaide': ['+09:30', '+9.5'],
  'Australia/Hobart': ['+10:00', '+10'],
  'Asia/Yakutsk': ['+10:00', '+10'],
  'Australia/Brisbane': ['+10:00', '+10'],
  'Pacific/Port_Moresby': ['+10:00', '+10'],
  'Australia/Sydney': ['+10:00', '+10'],
  'Asia/Vladivostok': ['+11:00', '+11'],
  'Pacific/Guadalcanal': ['+11:00', '+11'],
  'Etc/GMT-12': ['+12:00', '+12'],
  'Pacific/Fiji': ['+12:00', '+12'],
  'Asia/Magadan': ['+12:00', '+12'],
  'Pacific/Auckland': ['+12:00', '+12'],
  'Pacific/Tongatapu': ['+13:00', '+13'],
  'Pacific/Apia': ['+13:00', '+13']
};

export const COUNTRIES = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, Democratic Republic of the Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D\'Ivoire',
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Democratic People\'s Republic of',
  'Korea, Republic of',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Lao People\'s Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, the Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.s.',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

export default {
  MODELS,
  SENSORS,
  LABELS,
  UNITS,
  MIN_VALUES,
  MAX_VALUES,
  SPACE_TYPES,
  PLANT_ATTRIBUTES,
  TIME_ZONES
};

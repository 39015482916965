import React from 'react';
import 'styled-components/macro';
import {
  Accordion,
  AccordionItem,
  AccordionHead,
  AccordionBody,
  styles
} from '@grownode/ui';

export function AccordionTabs({ config, selectedId, onTabClick }) {
  return (
    <Accordion>
      {config.map((tab, key) => {
        const tabClick =
          typeof onTabClick === "function" ? onTabClick : () => {};
        const isOpen = selectedId === tab.id;
        return (
          <AccordionItem
            key={key}
            open={isOpen}
            css={`
              max-height: ${isOpen ? "4000px" : "56px"};
              padding-left: 0;
            `}
          >
            <AccordionHead
              onClick={() => tabClick(tab.id)}
              css={`
                background: ${styles.colors.primaryBlue};
                color: ${styles.colors.plain};
                padding-left: 12px;
                border-bottom: 1px solid ${styles.colors.plain};
                height: 55px;
                margin-bottom: 0;
              `}
            >
              {tab.title}
            </AccordionHead>
            <AccordionBody
              css={`
                padding: 16px;
                padding-top: 16px;
                background-color: ${styles.colors.plain};
                border-left: 1px solid
                  ${styles.colors.grayRegular};
                border-right: 1px solid
                  ${styles.colors.grayRegular};
                border-bottom: 1px solid
                  ${styles.colors.grayRegular};
              `}
            >
              {tab.content}
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

export default AccordionTabs;

import axios from 'axios';
import { auth } from '../firebase';

export const saveGateway = async ({ gatewayId, name, companyId }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/saveGateway`;
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      {
        gatewayId,
        name,
        companyId
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('GATEWAY_SAVE_ERROR');
    }

    return response;
  } catch (e) {
    throw e;
  }
};

export default saveGateway;

import React, { useState, useEffect, Suspense } from 'react';
import { styles, Icon } from '@grownode/ui';
import 'styled-components/macro';
import { useSlideOut } from 'lib/hooks';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { FallBackLoader } from 'shared-components';
import {
  EditPlants,
  EditSpaces,
  EditGateway,
  EditSensor
} from 'shared-components/lazy';
import { useResponsiveQuery } from 'atomic-layout';
import { Drawer, Target } from '@accessible/drawer';
import drawerStyles from './drawer.module.scss';

export function SlideOut({ onClose, onSave }) {
  const isLarge = useResponsiveQuery({ from: 'md' });
  const [content, setContent] = useState(null);
  const [currentScroll, setCurrentScroll] = useState(0);
  const { slideOutOpen, setSlideOutOpen, slideOutTool, entityId, spaceId } = useSlideOut();

  useEffect(() => {
    switch (slideOutTool) {
      case 'Plants':
        setContent([<EditPlants key="EditPlants" plantParam={entityId} defaultSpace={spaceId} onSave={onSave} />]);
        break;
      case 'Spaces':
      case 'spaces-edit':
        setContent([<EditSpaces key="EditSpaces" spaceId={entityId} defaultSpace={spaceId} onSave={onSave} />]);
        break;
      case 'hardware-gateway':
        setContent([<EditGateway key="EditGateway" gatewayId={entityId} defaultSpace={spaceId} onSave={onSave} />]);
        break;
      case 'hardware-sensor':
        setContent([<EditSensor key="EditSensor" sensorId={entityId} defaultSpace={spaceId} onSave={onSave} />]);
        break;
      default:
        setContent([<h2 key={slideOutTool}>{slideOutTool}</h2>]);
        break;
    }
  }, [slideOutTool, entityId, spaceId, onSave]);

  const hideOnClickOutside = element => {
    const outsideClickListener = event => {
      const zoneDialog = document.getElementById('zone-parent-selector');
      if (
        element &&
        (!zoneDialog || (zoneDialog && !zoneDialog.parentNode.contains(event.target))) &&
        (event.target.className !== '' && typeof event.target.className.split === 'function' && (
          !event.target.className.split(' ').includes('SensorSelector') &&
          !event.target.className.split(' ').includes('SensorSelector-Item')
        )) &&
        !element.contains(event.target) &&
        isVisible(element) &&
        slideOutOpen
      ) {
        setSlideOutOpen(false);
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

  useEffect(() => {
    hideOnClickOutside(document.querySelector('#SlideOut'));
  });

  useEffect(() => {
    if (slideOutOpen) {
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
      setCurrentScroll(window.scrollY);
      setSlideOutOpen(true);
    } else {
      document.body.style.height = '';
      document.body.style.overflow = '';
      window.scrollTo(0, currentScroll);
      if (typeof onClose === 'function') {
        onClose();
      }
      setSlideOutOpen(false);
    }
  }, [slideOutOpen, setSlideOutOpen, currentScroll, onClose]);

  return (
    <Drawer
      open={slideOutOpen}
      preventScroll={false}
      id="SlideOut"
    >
      <Target
        placement="right"
        closedClass={drawerStyles.closed}
        openClass={drawerStyles.closed + " " + drawerStyles.open}
        closeOnEscape={true}
      >
        <div
          css={`
            width: ${isLarge ? '50%' : '100%'};
            background: #FFF;
          `}
        >
          <div
            css={`
              height: 100%;
              width: 28px;
              background: ${styles.colors.primaryGray};
              display: inline-block;
              vertical-align: top;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            `}
            title="Close"
            onClick={() => setSlideOutOpen(!slideOutOpen)}
          >
            <Icon
              icon={CloseOutline}
              size={28}
              css={`
                margin-top: 16px;
                color: ${styles.colors.primaryBlue};
              `}
            />
          </div>
          <div
            css={`
              display: inline-block;
              vertical-align: top;
              padding: 16px;
              width: calc(100% - 60px);
              overflow-y: auto;
              height: calc(100vh - 60px);
            `}
          >
            <Suspense fallback={<FallBackLoader isPage />}>
              {content}
            </Suspense>
          </div>
        </div>
      </Target>
    </Drawer>
  );
};

export default SlideOut;

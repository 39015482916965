import React from 'react';
import { Tile, PlantsTable } from 'shared-components';
import { Icon } from '@grownode/ui';
import 'styled-components/macro';
import { Composition } from 'atomic-layout';
import { traceZoneDown } from 'lib/utils';
import { useSelector } from 'react-redux';
import { getPlantsByPhase } from 'shared-selectors';
import { Plant } from '@styled-icons/remix-line/Plant';

export const PlantsTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {
  const plants = useSelector(state => state?.models?.inventory || {});
  let allPlantsBelow = {};
  let spaceTrace = Object.keys(spaces);
  if (!thisSpace || thisSpace === 'root') {
    allPlantsBelow = plants;
  } else {
    spaceTrace = traceZoneDown(spaces, thisSpace);
    for (const [key, val] of Object.entries(plants)) {
      if (spaceTrace.includes(val?.spaceId)) {
        if (!allPlantsBelow[key]) {
          allPlantsBelow[key] = val;
        }
      }
    }
  }

  const plantsByPhase = getPlantsByPhase(allPlantsBelow);
  const growthPhases = Object.keys(plantsByPhase).sort();

  const createTotals = () => {
    return (
      <>
        <div
          css={`
            font-size: 28px;
            font-weight: 400;
            text-align: center;
            height: 1.2em;
          `}
        >
          {Object.keys(allPlantsBelow)?.length || 0}
        </div>
        <div
          css={`
            text-align: center;
            font-size: 12px;
          `}
        >
          {growthPhases.length > 0 ? (
            <>
              {growthPhases[0]}
              {growthPhases?.length > 1 && (
                <div css={`font-size: 10px; line-height: 12px;`}>+ {growthPhases.length - 1} more...</div>
              )}
            </>
          ) : (
            <>
              <em>No Growth Phase</em>
            </>
          )}
        </div>
      </>
    )
  };

  return (
    <Tile
      title={<><Icon icon={Plant} size={18} /> Plants</>}
      id="spaces_plants"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={true}
      content={createTotals()}
      expandedContent={
        <Composition
          areas={`
            Totals Listing
          `}
          templateCols="15% 1fr"
          templateRows="auto"
        >
          {({ Totals, Listing }) => (
            <>
              <Totals>
                {createTotals()}
              </Totals>
              <Listing
                paddingRight={16}
                paddingBottom={16}
              >
                <PlantsTable
                  searchFilters={
                    {
                      spaceId: spaceTrace
                    }
                  }
                />
              </Listing>
            </>
          )}
        </Composition>
      }
    />
  )
};

export default PlantsTile;

import _taggedTemplateLiteral from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from "styled-components";
import Modal from 'styled-react-modal';
import { colors } from "../shared/styles";
export var DialogModal = Modal.styled(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: ", ";\n  height: ", ";\n  background-color: hsla(0,0%,100%,1);\n  margin-top: 0;\n  opacity: 1;\n"])), function (props) {
  return props.width || '40rem';
}, function (props) {
  return props.height || '20rem';
});
export var DialogModalHeader = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  font-size: 18px;\n  padding: 8px 16px;\n  color: ", ";\n"])), colors.primaryBlue);
export var DialogModalCloser = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  float: right;\n  font-size: 18px;\n  cursor: pointer;\n  color: ", ";\n"])), colors.primaryBlue);
export var DialogModalBody = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: block;\n  padding: 8px;\n  min-height: 16.3rem;\n  max-height: 36rem;\n  ", "\n  & > div {\n    padding: 0;\n  }\n"])), function (props) {
  return props.allowScroll ? 'overflow-y: scroll;' : '';
});
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  SortableTable,
  FormCheckbox,
  Icon,
  styles
} from '@grownode/ui';
import 'styled-components/macro';
import { Box, Composition } from 'atomic-layout';
import { FallBackLoader, AreYouSure } from 'shared-components';
import { BulkEdit } from './components';
import { useSelector } from 'react-redux';
//import { useNavigate } from 'react-router-dom';
import { useSlideOut } from 'lib/hooks';
import * as R from 'ramda';
import { format } from 'date-fns/fp';
import { GearFill } from '@styled-icons/bootstrap/GearFill';

export const PlantsTable = ({
  searchFilters = {}
}) => {
  const {
    setSlideOutOpen,
    setSlideOutTool,
    setEntityId,
    //setSpaceId
  } = useSlideOut();
  const plantsState = useSelector((state) => state?.models?.inventory);
  const spaces = useSelector((state) => state?.models?.zones);
  const userState = useSelector((state) => state?.userRecord);
  //const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [originialTableData, setOriginialTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [bulkEditOpen, setBulkEditOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (plantsState) {
      const newTableData = [];
      for (const [key, value] of Object.entries(plantsState)) {
        const {
          batchId,
          plantType,
          strainName,
          growthPhase,
          spaceId,
          created,
          attributes,
          ts
        } = value;
        newTableData.push({
          plantId: key,
          batchId,
          plantType,
          strainName,
          growthPhase,
          spaceId,
          created,
          attributes,
          ts
        });
      }
      setOriginialTableData(newTableData);
      setTableData(newTableData);
    }
  }, [plantsState]);

  const clickListener = ev => {
    const menu = document.querySelector('.bulkOptionsMenu');
    const box = document.querySelector('.bulkOptionsBox');
    if (box) {
      if (!(box.contains(ev.target) || menu.contains(ev.target))) {
        setShowOptions(false);
      }
    }
  };

  useEffect(() => {
    if (showOptions) {
      window.addEventListener('click', ev => {
        clickListener(ev);
      });
    } else {
      window.removeEventListener('click', ev => {
        clickListener(ev);
      });
    }
  });

  useEffect(() => {
    const newTableData = originialTableData.filter(row => {
      for (const [filter, values] of Object.entries(searchFilters)) {
        let matchTotal = 0;
        if (
          (row.attributes[filter] && values.includes(row.attributes[filter].value)) ||
          (row[filter] && values.includes(row[filter]))
        ) {
          matchTotal = matchTotal + 1;
        }
        if (matchTotal === 0) {
          return false;
        }
      }
      return true;
    });

    if (JSON.stringify(newTableData) !== JSON.stringify(tableData)) {
      setTableData(newTableData);
    }
  }, [searchFilters, originialTableData, tableData]);

  const handleRowClick = (row, ev) => {
    ev.preventDefault();
    if (ev.target.nodeName !== 'INPUT') {
      setSlideOutTool('Plants');
      setEntityId(row.plantId);
      setSlideOutOpen(true);
    }
  };

  const selectRow = useCallback(id => {
    if (selectedRows.includes(id)) {
      const newRows = selectedRows.filter(item => item !== id);
      setSelectedRows(newRows);
    } else {
      setSelectedRows([
        ...selectedRows,
        id
      ]);
    }
  }, [selectedRows]);

  const handleSelectAll = () => {
    if (selectedRows.length < tableData.length) {
      const allRows = tableData.map(row => row.plantId);
      setSelectedRows(allRows);
      setShowOptions(false);
    }
  }

  const handleSelectNone = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
      setShowOptions(false);
    }
  }

  const handleBulkDelete = () => {
    if (selectedRows.length > 0) {
      setIsDeleteOpen(true);
      setShowOptions(false);
    }
  };

  const handleBulkEdit = () => {
    if (selectedRows.length > 0) {
      setBulkEditOpen(true);
      setShowOptions(false);
    }
  };

  const finalizePlantDelete = () => {
    console.log('Deleting ' + selectedRows.length + ' plant(s)...');
  };

  const columns = useMemo(
    () => [
      {
        Header: "Plant Type",
        accessor: "plantType",
        sortOptions: ["A-Z", "Z-A"],
        width: '13%'
      },
      {
        Header: "Strain Name",
        accessor: "strainName",
        sortOptions: ["A-Z", "Z-A"],
        width: '1fr'
      },
      {
        Header: "Growth Phase",
        accessor: "growthPhase",
        sortOptions: ["A-Z", "Z-A"],
        width: '140px'
      },
      {
        Header: "Space",
        accessor: "spaceId",
        sortOptions: ["A-Z", "Z-A"],
        width: '10%',
        Cell: item => {
          return spaces[item.value] && item?.value ? spaces[item.value].name : <em>No Space Defined</em>;
        }
      },
      {
        Header: "Date Added",
        accessor: "created",
        sortOptions: ["A-Z", "Z-A"],
        width: '15%',
        Cell: item => {
          return item?.value ? format('MM/dd/yyyy', item.value.toDate()) : '';
        }
      },
      {
        Header: "Select",
        accessor: "plantId",
        disableSortBy: true,
        width: '5%',
        Cell: item => {
          return (
            <FormCheckbox
              css={`margin-right: auto; margin-left: auto;`}
              checked={selectedRows.includes(item?.value)}
              onChange={() => {
                selectRow(item?.value);
              }}
            />
          );
        }
      }
    ],
    [spaces, selectRow, selectedRows]
  );

  const renderDetail = useCallback(
    ({ row }) =>
      R.difference(row.allCells, row.cells).map(cell => (
        <Box
          flex
          justifyContent="space-between"
          padding={5}
          marginHorizontal={5}
        >
          <div>{cell.column.render("Header")}</div>
          <div>{cell.render("Cell")}</div>
        </Box>
      )),
    []
  );

  const initialSortBy = useMemo(
    () => [{ id: "strainName", desc: false }, { id: "plantId", desc: false }],
    []
  );

  if (!spaces || !plantsState) {
    return (
      <FallBackLoader isPage />
    );
  }

  return (
    <>
      <Composition
        areas={`Message Controls`}
        gap={8}
        templateCols="auto 30px"
        templateRows="auto"
        alignItems="end"
        marginVertical={16}
      >
        {({ Message, Controls }) => (
          <>
            <Message>
              <div css={`text-align: right;`}>
                {selectedRows.length} plant{selectedRows.length === 1 ? '' : 's'} selected
              </div>
            </Message>
            <Controls>
              <Icon
                className="bulkOptionsMenu"
                icon={GearFill}
                size={20}
                title={`Manage ${selectedRows.length} plant${selectedRows.length === 1 ? '' : 's'}`}
                css={`
                  cursor: pointer;
                  color: ${styles.colors.secondaryBlueLight};

                  &:hover {
                    opacity: 0.7;
                  }
                `}
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              />
              {showOptions && (
                <Box
                  padding={0}
                  className="bulkOptionsBox"
                  css={`
                    background: #FCFCFC;
                    border: 1px solid ${styles.colors.primaryGray};
                    position: absolute;
                    margin-left: -170px;
                    width: 200px;
                    margin-top: 10px;
                    z-index: 100;
                  `}
                >
                  <div
                    onClick={handleSelectAll}
                    css={`
                      padding: 4px 8px 4px 8px;
                      color: ${selectedRows.length === tableData.length ? styles.colors.grayLight : 'inherit'};
                      ${selectedRows.length < tableData.length && (
                        `cursor: pointer;
                        &:hover {
                          background: ${styles.colors.grayLightest};
                        }`
                      )}
                    `}
                  >
                    Select All
                  </div>
                  <div
                    onClick={handleSelectNone}
                    css={`
                      padding: 4px 8px 4px 8px;
                      color: ${selectedRows.length === 0 ? styles.colors.grayLight : 'inherit'};
                      ${selectedRows.length > 0 && (
                        `cursor: pointer;
                        &:hover {
                          background: ${styles.colors.grayLightest};
                        }`
                      )}
                    `}
                  >
                    Select None
                  </div>
                  <hr
                    css={`
                      margin-right: 4px;
                      margin-left: 4px;
                      height: 0px;
                      border: none;
                      border-top: 1px solid #CCC;
                    `}
                  />
                  <div
                    onClick={handleBulkEdit}
                    css={`
                      padding: 4px 8px 4px 8px;
                      color: ${selectedRows.length === 0 ? styles.colors.grayLight : 'inherit'};
                      ${selectedRows.length > 0 && (
                        `cursor: pointer;
                        &:hover {
                          background: ${styles.colors.grayLightest};
                        }`
                      )}
                    `}
                  >
                    Edit {selectedRows.length} plant{selectedRows.length === 1 ? '' : 's'}
                  </div>
                  <div
                    onClick={handleBulkDelete}
                    css={`
                      padding: 4px 8px 4px 8px;
                      color: ${selectedRows.length === 0 ? styles.colors.grayLight : 'inherit'};
                      ${selectedRows.length > 0 && (
                        `cursor: pointer;
                        &:hover {
                          background: ${styles.colors.grayLightest};
                        }`
                      )}
                    `}
                  >
                    Remove {selectedRows.length} plant{selectedRows.length === 1 ? '' : 's'}
                  </div>
                </Box>
              )}
            </Controls>
          </>
        )}
      </Composition>
      {(tableData.length > 0) && (
        <span
          className="ActionToolbar"
        >
          <SortableTable
            columns={columns}
            data={tableData}
            renderDetails={renderDetail}
            initialSortBy={initialSortBy}
            onRowClick={(rowData, ev) => { handleRowClick(rowData, ev); }}
          />
        </span>
      )}
      {(tableData.length < 1) && (
        <div><em>No Plants Available</em></div>
      )}
      <AreYouSure
        modalOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onComplete={finalizePlantDelete}
      //isLoading={isLoading}
      >
        Are you sure you want to delete {selectedRows.length} plant{selectedRows.length === 1 ? '' : 's'}?
      </AreYouSure>
      <BulkEdit
        open={bulkEditOpen}
        setOpen={setBulkEditOpen}
        plants={selectedRows}
        companyId={userState?.defaultCompany}
        onSuccess={() => {
          setSelectedRows([]);
          setShowOptions(false);
        }}
      />
    </>
  );
};

export default PlantsTable;

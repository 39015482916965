import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Composition } from 'atomic-layout';
import { useLocation } from 'react-router-dom';
import { FormInput, Button, Icon } from '@grownode/ui';
import { traceZone } from 'lib/utils';
import { FallBackLoader } from 'shared-components';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Close } from '@styled-icons/evaicons-solid/Close';
import 'styled-components/macro';

const defaultPlantFields = [
  {
    term: 'Strain Name',
    slug: 'strainName',
    values: []
  },
  {
    term: 'Plant Type',
    slug: 'plantType',
    values: []
  },
  {
    term: 'Batch ID',
    slug: 'batchId',
    values: []
  },
  {
    term: 'Growth Phase',
    slug: 'growthPhase',
    values: []
  },
  {
    term: 'Space',
    slug: 'spaceId',
    values: []
  },
];

export const TextFilter = ({ onFilterChange, onFilterSave }) => {
  const plantData = useSelector(state => state.models.inventory);
  const userState = useSelector((state) => state?.userRecord);
  const companyContext = useSelector((state) => state?.models?.companyContext);
  const spaces = useSelector((state) => state?.models?.zones);
  const location = useLocation();
  const [attrList, setAttrList] = useState([]);
  const [spaceMap, setSpaceMap] = useState({});
  const [filteredAttrList, setFilteredAttrList] = useState([]);
  const [filteredValList, setFilteredValList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [valueSelection, setValueSelection] = useState(false);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [selectedAttr, setSelectedAttr] = useState(null);
  const [filters, setFilters] = useState({});
  const [dropOpen, setDropOpen] = useState(false);
  let isMounted = useRef(false);

  useEffect(() => {
    if (location && companyContext && userState) {
      if (location.search.indexOf('filter') > -1) {
        const parts = location.search.split('=');
        const filterItem = parts[1].split('-');
        const filterKey = filterItem[1];
        const filterData = companyContext[userState.defaultCompany].savedFilters[filterKey];
        if (filterData.filters) {
          setFilters(filterData.filters);
          onFilterChange(filterData.filters);
        }
      }
    }
    // eslint-disable-next-line
  }, [location, companyContext, userState]);

  const getSpaceTree = useCallback(spaceId => {
    const traced = traceZone(spaces, spaceId);
    traced.reverse();
    const spaceStr = traced.map(a => a?.name).join(' > ');
    if (!spaceMap[spaceStr] && isMounted.current) {
      setSpaceMap({
        ...spaceMap,
        [spaceStr]: spaceId
      });
    }
    return spaceStr;
  }, [spaces, spaceMap]);

  const getSpaceTreeSimple = spaceId => {
    const traced = traceZone(spaces, spaceId);
    traced.reverse();
    return traced.map(a => a.name).join(' > ');
  }

  const getAttributesFromPlants = useCallback(() => {
    if (!plantData) {
      return [];
    }

    const attributeArray = [];
    const attributes = [];
    const attributeOptions = {};

    for (const [, plant] of Object.entries(plantData)) {
      defaultPlantFields.forEach(defaultField => {
        const defaultVarValue = defaultField.slug === 'spaceId' ? getSpaceTree(plant[defaultField.slug]) : plant[defaultField.slug];
        if (!attributeArray.includes(defaultField.slug)) {
          attributeArray.push(defaultField.slug);
          attributes.push({
            term: defaultField.term,
            slug: defaultField.slug
          });
          attributeOptions[defaultField.slug] = [defaultVarValue];
        } else {
          if (!attributeOptions[defaultField.slug].includes(defaultVarValue)) {
            attributeOptions[defaultField.slug].push(defaultVarValue);
          }
        }
      });

      for (const [key, attr] of Object.entries(plant.attributes)) {
        if (!attributeArray.includes(key)) {
          attributeArray.push(key);
          attributes.push({
            term: attr.title,
            slug: key
          });
          attributeOptions[key] = [attr.value];
        } else {
          if (!attributeOptions[key].includes(attr.value)) {
            attributeOptions[key].push(attr.value);
          }
        }
      }
    };

    const attrsWithValues = [];
    attributes.forEach(item => {
      item.values = attributeOptions[item.slug];
      attrsWithValues.push(item);
    });

    return attrsWithValues.sort((a, b) => {
      if (a.term === b.term) {
        return 0;
      }
      return (a.term > b.term) ? 1 : -1;
    });
  }, [plantData, getSpaceTree]);

  const omitUsedAttrs = useCallback(attrs => {
    return attrs.filter(attr => {
      return !filters[attr.slug] || filters[attr.slug].length < attr.values.length;
    });
  }, [filters]);

  const omitUsedVals = (slug, vals) => {
    return vals.filter(val => {
      const actualVal = slug === 'spaceId' ? spaceMap[val] : val;
      return !filters[slug] || !filters[slug].includes(actualVal);
    });
  };

  const setAttrsFiltered = attrs => {
    setFilteredAttrList(omitUsedAttrs(attrs));
  };

  const setValsFiltered = (slug, vals) => {
    const values = omitUsedVals(slug, vals);
    setFilteredValList(values);
  };

  useEffect(() => {
    isMounted.current = true;
    const attrList = getAttributesFromPlants();
    setAttrList(attrList);
    setFilteredAttrList(attrList);
    document.addEventListener('click', ev => {
      if (isMounted.current) {
        if (
          !typeof ev.target.className.indexOf === 'function' ||
          (typeof ev.target.className.indexOf === 'function' && ev.target.className.indexOf('Clickable') < 0)
        ) {
          setDropOpen(false);
          setSelectedIndex(null);
          setValueSelection(false);
          setSelectedAttr(null);
        }
      }
    });
    return () => {
      isMounted.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const filteredAttrs = text => {
    return attrList.filter(attr => {
      return attr.term.indexOf(text) === 0;
    });
  };

  const filteredVals = text => {
    return filteredValList.filter(val => {
      return val.indexOf(text) === 0;
    });
  };

  if (!plantData) {
    return <FallBackLoader />;
  }

  const handleFocus = () => {
    setDropOpen(true);
  };

  const handleChange = ev => {
    const text = ev.currentTarget.value;
    if (text !== currentSearchText) {
      setCurrentSearchText(text);
    }
  };

  const focusSearch = () => {
    const searchInput = document.getElementById('searchText');
    if (searchInput) {
      searchInput.focus();
    }
  }

  const handleKeyUp = ev => {
    const text = ev.currentTarget.value;
    if (text !== currentSearchText) {
      setCurrentSearchText(text);
    }
    switch (ev.keyCode) {
      case 40: // Down arrow
        setDropOpen(true);
        if (selectedIndex !== null) {
          const listing = valueSelection ? filteredValList : filteredAttrList;
          const goTo = selectedIndex + 1;
          if (goTo < listing.length) {
            handleArrow(goTo);
          }
        } else {
          handleArrow(0);
        }
        break;
      case 38: // Up Arrow
        setDropOpen(true);
        if (selectedIndex !== null) {
          const goTo = selectedIndex - 1;
          if (selectedIndex !== 0) {
            handleArrow(goTo);
          }
        } else {
          handleArrow(0);
        }
        break;
      case 13: // Enter Key
        if (selectedIndex !== null) {
          if (valueSelection) {
            handleValSelect(filteredValList[selectedIndex]);
          } else {
            handleAttrSelect(filteredAttrList[selectedIndex]);
          }
        }
        break;
      case 27: // Escape Key
        setDropOpen(false);
        setSelectedIndex(null);
        setValueSelection(false);
        setSelectedAttr(null);
        break;
      default:
        setDropOpen(true);
        if (valueSelection) {
          setFilteredValList(omitUsedVals(selectedAttr, filteredVals(text)));
        } else {
          setFilteredAttrList(omitUsedAttrs(filteredAttrs(text)));
        }
        break;
    }
  };

  const addFilter = (slug, value) => {
    let newFilters = { ...filters };
    if (!newFilters[slug]) {
      newFilters[slug] = [value];
    } else if (!newFilters[slug].includes(value)) {
      newFilters[slug] = [...newFilters[slug], value];
    }
    setFilters(newFilters);
    if (typeof onFilterChange === 'function') {
      onFilterChange(newFilters);
    }
    setAttrsFiltered(attrList);
    const values = attrList.filter(item => {
      return item.slug === slug;
    });
    setValsFiltered(slug, values[0].values);
  };

  const isElementVisible = (el, holder) => {
    const { top, bottom } = el.getBoundingClientRect();
    const holderRect = holder.getBoundingClientRect();
    if (top <= holderRect.top) {
      return holderRect.top - top <= 0;
    } else {
      return bottom - holderRect.bottom <= 0;
    }
  }

  const getVisibleCount = () => {
    const dropDown = document.querySelector('.FilterOptions');
    const items = document.querySelectorAll('.FilterOptions > .Opt');
    let count = 0;
    if (dropDown && items) {
      items.forEach(item => {
        if (isElementVisible(item, dropDown)) {
          count = count + 1;
        }
      });
    }
    return count;
  };

  const handleArrow = key => {
    const visibleItems = getVisibleCount();
    const goingUp = key > selectedIndex;
    setSelectedIndex(key);
    const dropDown = document.querySelector('.FilterOptions');
    const selectedNode = document.querySelector('.FilterOption-' + key);
    if (dropDown && selectedNode) {
      if (!isElementVisible(selectedNode, dropDown)) {
        if (goingUp) {
          const nudge = dropDown.offsetHeight - (visibleItems * selectedNode.offsetHeight) - visibleItems;
          dropDown.scrollTop = (selectedNode.offsetHeight * key) - (visibleItems * selectedNode.offsetHeight) + nudge;
        } else {
          dropDown.scrollTop = (selectedNode.offsetHeight * key);
        }
      }
    }
  };

  const handleMouseOver = key => {
    setSelectedIndex(key);
  };

  const handleAttrSelect = attr => {
    setSelectedAttr(attr.slug);
    focusSearch();
    setCurrentSearchText('');
    setValueSelection(true);
    const vals = attr.values.sort();
    setValsFiltered(attr.slug, vals);
    setSelectedIndex(null);
  };

  const handleValSelect = val => {
    setAttrsFiltered(attrList);
    if (selectedAttr !== null) {
      addFilter(selectedAttr, selectedAttr === 'spaceId' ? spaceMap[val] : val);
      setSelectedAttr(null);
    }
    focusSearch();
    setCurrentSearchText('');
    setValueSelection(false);
    setSelectedIndex(null);
  };

  const handleRemoveFilter = (item, val) => {
    let newFilters = {};
    const attrValues = filters[item];
    const newAttrValues = [];
    attrValues.forEach(item => {
      if (item !== val) {
        newAttrValues.push(item);
      }
    });

    if (newAttrValues.length < 1) {
      for (const [key, data] of Object.entries(filters)) {
        if (key !== item) {
          newFilters[key] = data;
        }
      }
    } else {
      newFilters = {
        ...filters,
        [item]: newAttrValues
      }
    }

    setCurrentSearchText('');
    setFilters(newFilters);
    if (typeof onFilterChange === 'function') {
      onFilterChange(newFilters);
    }
    setAttrsFiltered(attrList);
    setSelectedAttr(null);
    setSelectedIndex(null);
    setValueSelection(false);
    focusSearch();
    const values = attrList.filter(val => {
      return val.slug === item;
    });
    setValsFiltered(item, values[0].values);
  };

  const searchFilter = document.getElementById('searchText');
  let dropWidth = 300;
  if (searchFilter) {
    const rect = searchFilter.getBoundingClientRect();
    dropWidth = rect.width;
  }

  const filterList = Object.keys(filters).sort();

  if (!plantData) {
    return null;
  }

  return (
    <Composition
      areas={`FilterList FilterList
        SearchText SearchBtn`}
      templateCols="1fr 200px"
      gap={16}
      marginBottom={16}
    >
      {({ FilterList, SearchText, SearchBtn }) => (
        <>
          <FilterList>
            {filterList.length > 0 && (
              <>
                {filterList.map((filter, i) => {
                  const item = attrList.find(f => { return f.slug === filter; });
                  return (
                    <span key={i}>
                      {filters[filter].map((value, x) => {
                        return (
                          <span
                            key={i + '-' + x}
                            css={`
                              margin: 0;
                              margin-right: 4px;
                              margin-bottom: 4px;
                              padding: 4px;
                              padding-left: 8px;
                              padding-right: 8px;
                              display: inline-block;
                              list-style-type: none;
                              background: #CECECE;
                              border-radius: 4px;
                              font-size: 11px;
                            `}
                          >
                            <strong>{item.term}:</strong> {item.slug === 'spaceId' ? getSpaceTreeSimple(value) : value}
                            <Icon
                              icon={Close}
                              size={14}
                              title="Remove Filter"
                              onClick={() => handleRemoveFilter(item.slug, value)}
                              css={`
                                cursor: pointer;
                                margin-left: 4px;

                                &:hover {
                                  opacity: 0.7;
                                }
                              `}
                            />
                          </span>
                        );
                      })}
                    </span>
                  );
                })}
              </>
            )}
          </FilterList>
          <SearchText>
            <FormInput
              id="searchText"
              name="searchText"
              label=""
              placeholder="Start Typing..."
              onKeyUp={handleKeyUp}
              onFocus={handleFocus}
              onClick={handleFocus}
              onChange={handleChange}
              value={currentSearchText}
              className="Clickable"
              autoComplete="off"
            />
            {dropOpen && (
              <div
                className="FilterOptions Clickable"
                css={`
                  position: absolute;
                  background: #FFF;
                  border: 1px solid #CCC;
                  border-top: none;
                  width: ${dropWidth - 2}px;
                  margin-top: -5px;
                  overflow-y: scroll;
                  max-height: 250px;
                `}
              >
                {!valueSelection && (
                  <>
                    {filteredAttrList.map((attr, i) => {
                      return (
                        <div
                          key={i}
                          className={'Clickable Opt FilterOption-' + i}
                          css={`
                            background: ${selectedIndex === i ? '#CECECE' : 'transparent'};
                            padding: 8px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            cursor: pointer;
                          `}
                          onMouseOver={() => handleMouseOver(i)}
                          onClick={() => handleAttrSelect(attr)}
                        >
                          {attr.term}
                        </div>
                      );
                    })}
                  </>
                )}
                {valueSelection && (
                  <>
                    {filteredValList.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className={'Clickable Opt FilterOption-' + i}
                          css={`
                            background: ${selectedIndex === i ? '#CECECE' : 'transparent'};
                            padding: 8px;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            cursor: pointer;
                          `}
                          onMouseOver={() => handleMouseOver(i)}
                          onClick={() => handleValSelect(val)}
                        >
                          {val}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </SearchText>
          <SearchBtn>
            <Button
              type="button"
              variant="tertiary"
              width="100%"
              height={40}
              disabled={filterList.length < 1}
              rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              onClick={() => {
                if (typeof onFilterSave === 'function') {
                  onFilterSave(filters);
                }
              }}
            >
              Save Filter
            </Button>
          </SearchBtn>
        </>
      )}
    </Composition>
  );
};

export default TextFilter;
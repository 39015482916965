import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { GrowNode } from 'Apps';
import { global } from '@grownode/ui';

import './index.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

const GlobalStyle = global.GlobalStyle;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false
    });
  }
});

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Router>
      <GrowNode />
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

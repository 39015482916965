import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'lib/hooks';
import 'styled-components/macro';
import { Only, useResponsiveQuery } from 'atomic-layout';
import {
  Footer as GrowNodeFooter,
  FooterItem,
  FooterSeparator,
  Link,
  Column,
  Row,
  Container,
  styles
} from '@grownode/ui';

export function Footer() {
  const inlineRange = useResponsiveQuery({ from: "sm" });
  const { user } = useAuth();
  const isLoggedIn = user !== null;
  const now = new Date();
  const currentYear = now.getFullYear();

  return (
    <GrowNodeFooter>
      <Container>
        {isLoggedIn && (
          <>
            <Only to="sm">
              <Row>
                <Column
                  cols={12}
                  css={`
                    text-align: center;
                  `}
                >
                  <Link as={NavLink} to="/" css={`color: ${styles.colors.secondaryRed}; text-decoration: none;`}>
                    GrowNode
                  </Link>
                  <FooterSeparator />
                </Column>
              </Row>
            </Only>
            <Row>
              <Column cols={8} colsMd={8}>
                <Row>
                  <Column cols={12} colsMd={12}>
                    <FooterItem inline={inlineRange} separator={inlineRange}>
                      <Link as={NavLink} to={isLoggedIn ? '/dashboard' : '/'}>
                        Home
                      </Link>
                    </FooterItem>
                    {isLoggedIn && (
                      <>
                        <FooterItem inline={inlineRange} separator={inlineRange}>
                          <Link as={NavLink} to="/spaces">
                            Spaces
                          </Link>
                        </FooterItem>
                        <FooterItem inline={inlineRange} separator={inlineRange}>
                          <Link as={NavLink} to="/plants">
                            Plants
                          </Link>
                        </FooterItem>
                        <FooterItem inline={inlineRange} separator={inlineRange}>
                          <Link as={NavLink} to="/hardware">
                            Hardware
                          </Link>
                        </FooterItem>
                      </>
                    )}
                  </Column>
                </Row>
              </Column>

              <Column
                cols={4}
                colsMd={4}
              >
                <Only
                  from="sm"
                  css={`
                    text-align: ${inlineRange ? 'right' : 'center'};
                  `}
                >
                  <Link as={NavLink} to={isLoggedIn ? '/dashboard' : '/'} css={`color: ${styles.colors.secondaryRed}; text-decoration: none;`}>
                    GrowNode
                  </Link>
                </Only>
              </Column>
            </Row>
            <FooterSeparator />
          </>
        )}
        <Row>
          <Column cols={12} colsLg={8}>
            <FooterItem inline={inlineRange}>
              <Link as={NavLink} to="/about">
                About Us
              </Link>
            </FooterItem>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link as={NavLink} to="/terms">
                Terms of Service
              </Link>
            </FooterItem>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link as={NavLink} to="/privacy-policy">
                Privacy Policy
              </Link>
            </FooterItem>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link as={NavLink} to="/disclosures">
                Disclosures
              </Link>
            </FooterItem>
            <FooterItem inline={inlineRange} separator={inlineRange}>
              <Link as={NavLink} to={isLoggedIn ? '/dashboard' : '/'}>
                www.grownode.com
              </Link>
            </FooterItem>
          </Column>
          <Column
            cols={12}
            colsLg={4}
            marginTop={10}
            marginTopLg={0}
            css={`
              text-align: ${inlineRange ? 'right' : 'center'};
            `}
          >
            &copy; {currentYear}. All rights reserved. GrowNode.com.
          </Column>
        </Row>
      </Container>
    </GrowNodeFooter>
  );
}

export default Footer;

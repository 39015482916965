import { useAuth } from 'lib/hooks';
import { isAuthed } from 'lib/utils';
import React from 'react';
import firebase, { db } from 'lib/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { fetchUserRecord } from 'shared-reducers/userRecord';

export const setOrTimeout = async user => {
  const doc = firebase
    .firestore()
    .collection('user-accounts')
    .doc(user.uid);
  const snapshot = await doc.get();
  const data = snapshot.data();
  const expTime = data.sessionExpiry.toDate().valueOf();
  const nowTime = new Date().valueOf();
  const sessionAge = (nowTime - expTime) / 1000 / 60 / 60;
  if (sessionAge > 4) {
    return await firebase.auth().signOut();
  } else {
    const now = new Date();
    now.setSeconds(now.getSeconds() + 4 * 3600);
    await doc.set(
      {
        sessionExpiry: firebase.firestore.Timestamp.fromDate(now)
      },
      { merge: true }
    );
  }
};

export function Private({ authPath = '/login', children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, signout, isInitializing } = useAuth();
  const { app } = useSelector(state => state.userRecord);

  React.useEffect(() => {
    if (isAuthed(user)) {
      db.collection('user-accounts')
        .doc(user.uid)
        .get()
        .then(async snapshot => {
          const data = snapshot.data();
          const expTime = data.sessionExpiry.toDate().valueOf();
          const nowTime = new Date().valueOf();
          const sessionAge = (nowTime - expTime) / 1000 / 60 / 60;
          if (sessionAge > 4) {
            await signout();
          } else {
            dispatch(fetchUserRecord(user.uid));
          }
        });
    }
  }, [user, app, dispatch, signout]);

  if (!isAuthed(user) && !isInitializing) {
    return (
      <Navigate to={authPath} replace state={{ from: location.pathname }} />
    );
  }

  if (isAuthed(user)) {
    setOrTimeout(user);
  }

  return children;
};

export function PrivateRoute({ element, authPath, ...props }) {
  return (
    <Route
      {...props}
      element={<Private authPath={authPath}>{element}</Private>}
    />
  );
};

import axios from 'axios';
import { auth } from '../../firebase';

export const saveZone = async ({
  zoneId,
  parentZoneId,
  companyId,
  name,
  sensorList,
  type,
  typeOther,
  typeDimensionsLength,
  typeDimensionsWidth,
  typeDimensionsHeight
}) => {
	const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/saveZone`;
  const token = await auth.currentUser.getIdToken();
	try {
		const response = await axios.post(
			apiUrl,
			{
        zoneId,
        companyId,
        parentZoneId,
				name,
        sensorList,
        type,
        typeOther,
        typeDimensionsLength,
        typeDimensionsWidth,
        typeDimensionsHeight
			},
			{
				headers: {
					'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
				},
				validateStatus: status => {
					return status >= 200 && status < 500;
				}
			}
		);
		return response;
	} catch (e) {
		throw e;
	}
};

export default saveZone;

import { lazy } from 'react';

const About = lazy(() => import('./About'));
const Activate = lazy(() => import('./Activate'));
const Dash = lazy(() => import('./Dash'));
const Disclosures = lazy(() => import('./Disclosures'));
const EmailPreferences = lazy(() => import('./EmailPreferences'));
const Plants = lazy(() => import('./Plants'));
const Spaces = lazy(() => import('./Spaces'));
const SpacesDashboard = lazy(() => import('./Spaces/Dashboard'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const Hardware = lazy(() => import('./Hardware'));
const HardwareGatewayActivate = lazy(() => import('./Hardware/GatewayActivate'));
const Home = lazy(() => import('./Home'));
const People = lazy(() => import('./People'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const Settings = lazy(() => import('./Settings'));
const Terms = lazy(() => import('./Terms'));

export {
  About,
  Activate,
  Dash,
  Disclosures,
  EmailPreferences,
  Plants,
  Spaces,
  SpacesDashboard,
  ForgotPassword,
  Hardware,
  HardwareGatewayActivate,
  Home,
  People,
  PrivacyPolicy,
  ResetPassword,
  Settings,
  Terms
};

import { lazy } from 'react';

const Grows = lazy(() => import('../Grows'));
const Login = lazy(() => import('../Login'));
const Contact = lazy(() => import('../Contact'));
const Communication = lazy(() => import('../Communication'));

export {
  Grows,
  Login,
  Contact,
  Communication
};

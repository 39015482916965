import { createSelector } from 'reselect';
import { traceZoneDown } from 'lib/utils';

export const getSpaces = state => state?.models?.zones ?? {};

export const getSpaceSensors = createSelector(getSpaces, space => {
  const sensorsBySpace = {};
  for (const [key, value] of Object.entries(space)) {
    const sensors = value?.sensorList;
    const activeSensors = {};
    if (sensors && Object.keys(sensors).length > 0) {
      for (const [key, value] of Object.entries(sensors)) {
        if (value.active === true) {
          activeSensors[key] = value;
        }
      }
    }
    if (activeSensors && Object.keys(activeSensors).length > 0) {
      if (!sensorsBySpace[key]) {
        sensorsBySpace[key] = [];
      }
      sensorsBySpace[key] = {
        ...sensorsBySpace[key],
        ...activeSensors
      };
    }
  }
  return sensorsBySpace;
});

export const getSubSpaces = (spaces, spaceId) => {
  const subSpaces = [];
  for (const [key, value] of Object.entries(spaces)) {
    const finalSpaceId = spaceId === 'root' ? null : spaceId;
    if (value?.parentId === finalSpaceId && value?.active !== false) {
      subSpaces.push({
        id: key,
        ...value
      });
    }
  }
  return subSpaces.sort((a, b) => {
    if (a.name === b.name) {
      return 0;
    }
    return (a.name > b.name) ? 1 : -1;
  });
};

export const getCanopy = (spaces, spaceId) => {
  const zoneTrace = traceZoneDown(spaces, spaceId);
  const totalCanopy = zoneTrace.reduce((sum, zone) => {
    const curSpace = spaces[zone];
    if (curSpace) {
      const { typeDimensionsWidth, typeDimensionsLength } = curSpace;
      if (typeDimensionsWidth && typeDimensionsLength) {
        return sum + Number(typeDimensionsWidth) * Number(typeDimensionsLength)
      }
    }
    return sum;
  }, 0);

  return parseFloat(totalCanopy.toFixed(2));
};

import { lazy } from 'react';

const EditPlants = lazy(() => import('./EditPlants'));
const EditSpaces = lazy(() => import('./EditSpaces'));
const EditGateway = lazy(() => import('./EditGateway'));
const EditSensor = lazy(() => import('./EditSensor'));

export {
  EditPlants,
  EditSpaces,
  EditGateway,
  EditSensor
};

import 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from 'atomic-layout';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { register } from 'lib/api';
import { DashPassword, DashInput, DashForm, DashCheckbox, DashSelect } from 'shared-components';
import { FormError } from './components';
import { PasswordWizard, FallBackLoader } from 'shared-components';
import { verifyEmail } from 'lib/api';
import { useAuth } from 'lib/hooks';
import {
  Text,
  Panel,
  PanelTitle,
  PanelTitleHeading,
  FormMessage,
  LoadingButton,
  Link,
  Icon
} from '@grownode/ui';
import * as CONSTANTS from '../../../constants';

export function Activate() {
  const { signout, user } = useAuth();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [oobCodeInfo, setOobCodeInfo] = useState();
  const requestedOobCode = new URLSearchParams(
    window.location.href.split("?")[1]
  ).get("oobCode");

  useEffect(() => {
    async function getInfo() {
      if (requestedOobCode !== null) {
        try {
          const info = await verifyEmail({
            oobCode: requestedOobCode
          });
          if (info.error !== undefined) {
            setIsExpired(true);
          } else {
            setOobCodeInfo(info);
          }
        } catch (e) {
          setError(e.message);
        }
      }
    }
    getInfo();
  }, [requestedOobCode]);

  if (user !== null && oobCodeInfo) {
    signout();
  }

  if (requestedOobCode && !oobCodeInfo) {
    return <FallBackLoader isPage />;
  }

  const tzs = [];
  for (const [key, value] of Object.entries(CONSTANTS.TIME_ZONES)) {
    tzs.push({
      key,
      value
    });
  }

  return (
    <Box>
      <Box maxWidth={600} paddingVertical={48} marginHorizontal="auto">
        <Panel paddingVertical={48} paddingHorizontal={60}>
          <PanelTitle
            large
            css={`
							text-align: center;
						`}
          >
            <PanelTitleHeading large>
              GrowNode Account Registration
            </PanelTitleHeading>
          </PanelTitle>

          {requestedOobCode && isExpired && (
            <FormMessage variant="error">Sorry, the activation code in this link is expired.</FormMessage>
          )}

          {oobCodeInfo && (
            <FormMessage variant="success">Congrats! Your email address has been verified and you're ready to go.<br /> <Link as={NavLink} to="/login">Click here to Log In!</Link></FormMessage>
          )}

          {success && (
            <FormMessage variant="success">
              <strong>Registration Successful!</strong>
              <p>&nbsp;</p>
              We've sent an email to the address provided.{' '}
              Please click the link in that message to verify{' '}
              your email address and access the site!
            </FormMessage>
          )}
          {(!success && !oobCodeInfo) && (
            <Formik
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: '',
                companyName: '',
                defaultUnits: 'metric',
                defaultTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                agreeToTerms: false
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email address')
                  .required('Required'),
                firstName: Yup.string()
                  .required('Required'),
                lastName: Yup.string()
                  .required('Required'),
                companyName: Yup.string()
                  .required('Required'),
                password: Yup.string()
                  .matches(/[A-Z]/, 'Password does not meet criteria')
                  .matches(/[0-9]/, 'Password does not meet criteria')
                  .min(7, 'Password does not meet criteria')
                  .required('Required'),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref('password'), null], 'Passwords do not match')
                  .required('Required'),
                agreeToTerms: Yup.bool().oneOf([true], 'Required')
              })}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                try {
                  await signout();
                  const response = await register({
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    password: values.password,
                    companyName: values.companyName,
                    defaultUnits: values.defaultUnits,
                    defaultTimeZone: values.defaultTimeZone,
                    defaultTimeZoneOffset: CONSTANTS.TIME_ZONES[values.defaultTimeZone][1]
                  });
                  if (response.data.error) {
                    throw new Error(response.data.error);
                  } else {
                    setSuccess(true);
                  }
                } catch (e) {
                  setError(e.message);
                  actions.setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => {
                return (
                  <DashForm>
                    {error && (
                      <FormMessage variant="error">
                        <FormError errorCode={error} />
                      </FormMessage>
                    )}
                    <DashInput
                      id="email"
                      name="email"
                      label="Email"
                      disabled={isSubmitting}
                    />
                    <DashInput
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      disabled={isSubmitting}
                    />
                    <DashInput
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      disabled={isSubmitting}
                    />
                    <DashInput
                      id="companyName"
                      name="companyName"
                      label="Grow Name"
                      disabled={isSubmitting}
                    />
                    <DashSelect
                      id="defaultUnits"
                      name="defaultUnits"
                      label="Default Units"
                      onChange={ev => {
                        setFieldValue('defaultUnits', ev.target.options[ev.target.selectedIndex].value);
                      }}
                      value={values.defaultUnits}
                      disabled={isSubmitting}
                    >
                      <option value="metric">Metric (Celsius, cm)</option>
                      <option value="imperial">Imperial (Fahrenheit, inches)</option>
                    </DashSelect>
                    <DashSelect
                      id="defaultTimeZone"
                      name="defaultTimeZone"
                      label="Default Time Zone"
                      onChange={ev => {
                        setFieldValue('defaultTimeZone', ev.target.options[ev.target.selectedIndex].value);
                      }}
                      value={values.defaultTimeZone}
                      disabled={isSubmitting}
                    >
                      {tzs.map((tz, i) => (
                        <option key={i} value={tz.key}>{tz.key} (UTC{tz.value[0]})</option>
                      ))}
                    </DashSelect>
                    <PasswordWizard password={values.password} />
                    <DashPassword
                      id="password"
                      name="password"
                      label="Password"
                      successMsg="Password is good"
                      disabled={isSubmitting}
                    />
                    <DashPassword
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Re-enter Password"
                      successMsg="Passwords match"
                      disabled={isSubmitting}
                    />
                    <Box
                      flex
                      justifyContent="space-between"
                      marginVertical={16}
                    >
                      <DashCheckbox
                        id="agreeToTerms"
                        name="agreeToTerms"
                        value="agreed"
                        onChange={(ev) => {
                          setFieldValue('agreeToTerms', ev.currentTarget.checked);
                        }}
                        disabled={isSubmitting}
                      >
                        <span>I have read and agree to the&nbsp;</span>
                        <Link as={NavLink} to="/terms">Terms of Use</Link>
                        <span>&nbsp;and&nbsp;</span>
                        <Link as={NavLink} to="/privacy-policy">Privacy Policy</Link>
                      </DashCheckbox>
                    </Box>
                    <LoadingButton
                      height={40}
                      type="submit"
                      variant="tertiary"
                      rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                      isLoading={isSubmitting}
                    >
                      Register
                    </LoadingButton>
                  </DashForm>
                )
              }}
            </Formik>
          )}
          <Box
            marginTop={40}
            css={`
							text-align: center;
						`}
          >
            <Text as="span">Need help? Contact Customer Service</Text>
            <br />
            <Link href="tel:1-800-Grow-Node">
              1-800-Grow-Node
            </Link>
          </Box>
          <Box
            marginTop={20}
            css={`
							text-align: center;
						`}
          >
            <Link as={NavLink} to="/login">
              <Icon icon={ChevronThinLeft} size={12} />{" "}
              <Text
                as="span"
                css={`
									vertical-align: middle;
								`}
              >
                Back
              </Text>
            </Link>
          </Box>
        </Panel>
      </Box>
    </Box>
  );
}

export default Activate;

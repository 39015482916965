import React, { useState } from 'react';
import { Composition } from 'atomic-layout';
import { styles, Icon, InputMessage } from '@grownode/ui';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { AreYouSure } from 'shared-components';
import 'styled-components/macro';

export const Individual = ({ required = false, children, error, deleteHandler = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Composition  
      areas={`field options`}
      templateCols="auto 60px"
      templateRows="auto"
      alignItems="flex-start"
    >
      {({ Field, Options }) => (
        <>
          <Field>
            {children}
            {error && (
              <InputMessage css={`margin-top: 8px;`} key={1} variant="error">
                {error}
              </InputMessage>
            )}
          </Field>
          <Options>
            {!required && (
              <>
                <AreYouSure
                  modalOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  onComplete={() => {
                    deleteHandler();
                    setIsOpen(false);
                  }}
                >
                  Are you sure you want to delete this attribute?
                </AreYouSure>
                <Icon
                  title="Remove Attribute"
                  icon={Trash}
                  size={20}
                  css={`
                    margin-bottom: 10px;
                    margin-left: 16px;
                    cursor: pointer;
                    color: ${styles.colors.secondaryBlueLight};
                    vertical-align: top;
                    margin-top: 30px;

                    &:hover {
                      opacity: 0.7;
                    }
                  `}
                  onClick={() => setIsOpen(true)}
                />
              </>
            )}
          </Options>
        </>
      )}
    </Composition>
  );
};

export default Individual;

import React, { useEffect, useState, useCallback } from 'react';
import { DragList, FallBackLoader } from 'shared-components';
import { DndProvider } from 'react-dnd';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getSubSpaces } from 'shared-selectors';
import { usePrevious } from 'lib/hooks';
//import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  SpacesTile,
  //CanopyTile,
  //TasksTile,
  //PeopleTile,
  //GroupsTile,
  PlantsTile,
  HardwareTile
} from './components';

export const SpacesBlock = ({ currentSpace = null }) => {
  const [thisSpace, setThisSpace] = useState(currentSpace);
  const prevSpace = usePrevious(thisSpace);
  const spaces = useSelector(state => state?.models?.zones || {});
  const subSpaces = currentSpace ? getSubSpaces(spaces, currentSpace) : [];

  useEffect(() => {
    setThisSpace(currentSpace);
  }, [currentSpace]);

  const generateListItems = useCallback(() => {
    const theItems = [
      /*
      {
        id: 2,
        Component: (
          <CanopyTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      {
        id: 3,
        Component: (
          <GroupsTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      */
      {
        id: 4,
        Component: (
          <PlantsTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      /*
      {
        id: 5,
        Component: (
          <PeopleTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      */
      {
        id: 6,
        Component: (
          <HardwareTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      /*
      {
        id: 7,
        Component: (
          <TasksTile
            spaces={spaces}
            thisSpace={thisSpace}
            subSpaces={subSpaces}
          />
        )
      },
      */
    ];
    if (!thisSpace) {
      return [
        {
          id: 1,
          Component: (
            <SpacesTile
              spaces={spaces}
              thisSpace={thisSpace}
              subSpaces={subSpaces}
            />
          )
        },
        ...theItems
      ];
    } else {
      return theItems;
    }
  }, [thisSpace, spaces, subSpaces]);

  const [listItems, setListItems] = useState(generateListItems());

  useEffect(() => {
    if (thisSpace !== prevSpace) {
      setListItems(generateListItems());
    }
  }, [thisSpace, generateListItems, prevSpace]);

  if (!spaces) {
    return <FallBackLoader isPage={true} />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <DragList
        height="100%"
        items={listItems}
        onDragChange={itemsFunc => {
          setListItems(itemsFunc(listItems));
        }}
      />
    </DndProvider>
  );
};

export default SpacesBlock;

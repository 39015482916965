import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tile } from 'shared-components';
import { styles, Icon } from '@grownode/ui';
import 'styled-components/macro';
import { getSpaceSensors } from 'shared-selectors';
import { useSlideOut } from 'lib/hooks';
import { SENSORS } from '../../../constants';
import { NetworkWired } from '@styled-icons/fa-solid/NetworkWired';

export const HardwareTile = ({
  thisSpace = null,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {
  const { setSlideOutOpen, setSlideOutTool, setEntityId } = useSlideOut();
  const sensors = useSelector(state => state?.models?.nodes || {});
  const spaceSensors = useSelector(getSpaceSensors);
  const [currentSensors, setCurrentSensors] = useState([]);

  useEffect(() => {
    if (Object.keys(spaceSensors).length > 0) {
      let tmpSensors = [];
      if (thisSpace && thisSpace !== 'root') {
        if(spaceSensors[thisSpace]) {
          for (const [id,] of Object.entries(spaceSensors[thisSpace])) {
            const sensor = sensors[id];
            if (sensor?.active === true) {
              tmpSensors.push({
                id,
                ...sensor
              });
            }
          }
        }
      } else {
        const uniqueSensors = [];
        for (const [spaceId,] of Object.entries(spaceSensors)) {
          if (spaceSensors[spaceId]) {
            for (const [id,] of Object.entries(spaceSensors[spaceId])) {
              const sensor = sensors[id];
              if (sensor?.active === true) {
                if (!uniqueSensors.includes(id)) {
                  uniqueSensors.push(id);
                  tmpSensors.push({
                    id,
                    ...sensor
                  });
                }
              }
            }
          }
        };
      }
      tmpSensors.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      });
      setCurrentSensors(tmpSensors);
    }
  }, [spaceSensors, thisSpace, sensors]);

  const handleSensorClick = sensorId => {
    setSlideOutTool('hardware-sensor');
    setEntityId(sensorId);
    setSlideOutOpen(true);
  };

  return (
    <Tile
      title={<><Icon icon={NetworkWired} size={18} /> Hardware</>}
      id="spaces_hardware"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={currentSensors?.length > 0}
      content={
        <>
          <div
            css={`
              font-size: 28px;
              font-weight: 400;
              text-align: center;
              height: 1.2em;
            `}
          >
            {currentSensors?.length}
          </div>
          <div css={`text-align: center;font-size: 12px;`}>Sensors</div>
        </>
      }
      expandedContent={
        <>
          <div css={`font-size: 13px;`}>
            {currentSensors?.length && (
              <table
                width="100%"
                border="0"
                cellPadding={0}
                cellSpacing={0}
                css={`
                  & > tbody {
                    & > tr {
                      cursor: pointer;

                      &:hover {
                        background: ${styles.colors.grayLightest};
                      }

                      & > td {
                        padding: 4px;
                      }
                    }
                  }

                  & > thead {
                    & > tr {
                      & > th {
                        padding: 4px;
                        border-bottom: 1px solid ${styles.colors.grayDark};
                      }
                    }
                  }
                `}
              >
                <thead>
                  <tr>
                    <th css={`text-align: left;`}>Sensor Name</th>
                    <th css={`text-align: left;`}>Sensor Type</th>
                    <th css={`text-align: left;`}>Authorized?</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSensors.map((sensor, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={() => {
                          handleSensorClick(sensor.id);
                        }}
                      >
                        <td>{sensor.name}</td>
                        <td>{SENSORS[sensor.type]}</td>
                        <td>{sensor?.isAuthed === true ? 'Yes' : 'No'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      }
    />
  )
};

export default HardwareTile;

import axios from 'axios';
import { auth, db } from '../firebase';

export const getSensorData = async ({
  spaceId,
  startDate,
  endDate,
  timeZoneOffset,
  companyId
}) => {

  // First get list of node ids that have ever been in this space...
  let currentSpace = null;
  const spaceDataRef = await db.collection('companies').doc(companyId).collection('models').doc('zones').get();
  if (spaceDataRef.exists) {
    const spaceData = spaceDataRef.data();
    if (spaceData[spaceId]) {
      currentSpace = spaceData[spaceId];
    }
  }

  if (!currentSpace) {
    throw new Error('SPACE_NOT_FOUND');
  }

  const nodeIdList = Object.keys(currentSpace?.sensorList) || null;

  if (!nodeIdList) {
    throw new Error('NO_SENSORS_ASSIGNED');
  }


  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/getData`;
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      {
        spaceId,
        startDate,
        endDate,
        nodeIdList,
        timeZoneOffset,
        companyId
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );

    return response.data;
  } catch (e) {
    throw e;
  }
};

export default getSensorData;

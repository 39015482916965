import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tree, AreYouSure } from 'shared-components';
import { Box } from 'atomic-layout';
import { deleteFilter, changeContext } from 'lib/api';
import { traceZone } from 'lib/utils';
import { fetchUserRecord } from 'shared-reducers/userRecord';
import 'styled-components/macro';
import { Icon, Link, styles } from '@grownode/ui';
import { Workspaces } from '@styled-icons/material-twotone/Workspaces';
//import { People } from '@styled-icons/bootstrap/People';
import { Plant } from '@styled-icons/remix-line/Plant';
import { NetworkWired } from '@styled-icons/fa-solid/NetworkWired';
import { Filter } from '@styled-icons/bootstrap/Filter';
import { Trash } from '@styled-icons/bootstrap/Trash';

export function Nav({ isShrunk, setMobileDrawerOpen }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [zonesLoading, setZonesLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.userRecord);
  const zoneState = useSelector((state) => state?.models?.zones);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const membershipState = useSelector((state) => state?.models?.membership);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};
  const zoneTree = currentCompany?.zoneTree || [];
  const savedFilters = currentCompany?.savedFilters || [];
  const [selectedNav, setSelectedNav] = useState([pathname.replace('/', ''), userState?.defaultCompany]);
  const membershipList = membershipState ? Object.keys(membershipState) : [];

  useEffect(() => {
    const pathParts = pathname.split('/');
    const path = pathParts.slice(-1).join('');
    const base = pathParts[1];
    const tmpExpanded = [];
    if (userState?.defaultCompany) {
      tmpExpanded.push(`${userState?.defaultCompany}-${userState?.defaultCompany}`);
    }
    if (base === 'spaces' && path !== 'spaces' && zoneState) {
      tmpExpanded.push(`${userState?.defaultCompany}-spaces`);
      const zoneTrace = traceZone(zoneState, path);
      zoneTrace.forEach(zone => {
        tmpExpanded.push(`${userState?.defaultCompany}-${zone?.zoneId}`);
      });
    }
    setExpandedNodes(tmpExpanded);
    setSelectedNav([path === 'dashboard' ? userState?.defaultCompany : path, userState?.defaultCompany])
  }, [pathname, userState.defaultCompany, zoneState]);

  const deleteFilterHandler = async () => {
    setIsLoading(true);
    await deleteFilter({
      companyId: userState?.defaultCompany,
      filterName: selectedFilter
    });
    navigate('/plants');
    setIsOpen(false);
    setIsLoading(false);
  };

  const switchCompany = async newCompanyId => {
    try {
      await changeContext(userState.id, newCompanyId);
      dispatch(fetchUserRecord(userState.id));
    } catch (e) {
      console.log(e.message);
    }
  };

  const savedFiltersNav = [];
  if (savedFilters.length > 0) {
    savedFilters.forEach((filter, i) => {
      savedFiltersNav.push({
        active: true,
        id: '/plants/?filter=filter-' + i,
        label: <>
          {filter.filterName}
          <span
            css={`
              float: right;
              margin-right: 16px;
              display: none;
            `}
            className="closer"
            onClick={ev => {
              ev.stopPropagation();
              setSelectedFilter(filter.filterName);
              setIsOpen(true);
            }}
          >
            <Icon
              icon={Trash}
              size={16}
              css={`
                color: ${styles.colors.secondaryBlueLight};
              `}
            />
          </span>
        </>,
        parentId: 'plants',
        items: [],
        showLeafIcon: false,
        icon: <Icon icon={Filter} size={16} />,
        onMouseOver: ev => {
          const closer = ev.currentTarget.querySelector('.closer');
          closer.style.display = 'block';
        },
        onMouseOut: ev => {
          const closer = ev.currentTarget.querySelector('.closer');
          closer.style.display = 'none';
        }
      });
    });

    savedFiltersNav.sort((a, b) => {
      if (a.label === b.label) {
        return 0;
      }
      return a.label > b.label ? 1 : -1;
    });

  }

  useEffect(() => {
    if (zoneTree.length && zonesLoading === true) {
      setZonesLoading(false);
    }
  }, [zoneTree, zonesLoading]);

  const mainNavItems = [
    'spaces',
    'plants',
    'hardware',
    //'people'
  ];

  const navTree = [];
  if (membershipState) {
    for (const [key, values] of Object.entries(membershipState)) {
      navTree.push({
        id: key,
        active: true,
        label: values.name,
        parentId: null,
        showLeafIcon: false,
        items: [
          {
            id: `spaces`,
            active: true,
            label: <>
              Spaces
              <span css={`float: right;margin-right: 16px;`}>
                <Icon icon={Workspaces} size={16} />
              </span>
            </>,
            parentId: key,
            showLeafIcon: false,
            items: values.zoneTree
          },
          {
            id: `plants`,
            active: true,
            label: <>
              Plants
              <span css={`float: right;margin-right: 16px;`}>
                <Icon icon={Plant} size={16} />
              </span>
            </>,
            parentId: key,
            showLeafIcon: false,
            items: []
          },
          {
            id: `hardware`,
            active: true,
            label: <>
              Hardware
              <span css={`float: right;margin-right: 16px;`}>
                <Icon icon={NetworkWired} size={16} />
              </span>
            </>,
            parentId: key,
            showLeafIcon: false,
            items: []
          },
          /*
          {
            id: `people`,
            active: true,
            label: <>
              People
              <span css={`float: right;margin-right: 16px;`}>
                <Icon icon={People} size={16} />
              </span>
            </>,
            parentId: key,
            showLeafIcon: false,
            items: []
          }
          */
        ]
      });
    };
  }

  const navClicked = id => {
    const idContext = id.split('-');
    const context = idContext[0];
    const navItem = idContext.slice(1).join('-');
    const contextSwitchRequired = userState?.defaultCompany !== context;

    if (contextSwitchRequired) {
      switchCompany(context).then(() => {
        navTo(navItem, context);
      });
    } else {
      navTo(navItem, context);
    }
  };

  const navTo = (navItem, context) => {
    setSelectedNav([navItem, context]);
    if (typeof setMobileDrawerOpen === 'function') {
      setMobileDrawerOpen(false);
    }
    if (mainNavItems.includes(navItem)) {
      navigate(`/${navItem}`);
    } else if (navItem.indexOf('/plants/?filter=filter-') === 0) {
      navigate(`${navItem}`);
    } else if (membershipList.includes(navItem)) {
      navigate('/dashboard');
    } else {
      navigate(`/spaces/${navItem}`);
    }
  };

  return (
    <nav>
      <Box
        css={`
          overflow: hidden;
        `}
      >
        {!isShrunk && (
          <Tree
            data={navTree}
            isLoading={zonesLoading}
            onChange={navClicked}
            masterParentName="Home"
            expandAll={false}
            selectedId={selectedNav}
            showMasterParent={false}
            defaultExpanded={expandedNodes}
            idPrefix={true}
            prefix={userState?.defaultCompany}
          />
        )}
        {isShrunk && (
          <>
            <Box
              paddingLeft={12}
              paddingVertical={8}
              css={`
                background-color: ${selectedNav === 'spaces' ? 'rgba(118, 134, 138, 0.24)' : 'transparent'};
              `}
            >
              <Link
                as={NavLink}
                to="/spaces"
                title="Spaces"
                onClick={() => setSelectedNav(['spaces', userState?.defaultCompany])}
              >
                <Icon icon={Workspaces} size={28} />
              </Link>
            </Box>
            <Box
              paddingLeft={12}
              paddingVertical={8}
              css={`
                background-color: ${selectedNav === 'plants' ? 'rgba(118, 134, 138, 0.24)' : 'transparent'};
              `}
            >
              <Link
                as={NavLink}
                to="/plants"
                title="Plants"
                onClick={() => setSelectedNav(['plants', userState?.defaultCompany])}
              >
                <Icon icon={Plant} size={28} />
              </Link>
            </Box>
            <Box
              paddingLeft={12}
              paddingVertical={8}
              css={`
                background-color: ${selectedNav === 'hardware' ? 'rgba(118, 134, 138, 0.24)' : 'transparent'};
              `}
            >
              <Link
                as={NavLink}
                to="/hardware"
                title="Hardware"
                onClick={() => setSelectedNav(['hardware', userState?.defaultCompany])}
              >
                <Icon icon={NetworkWired} size={28} />
              </Link>
            </Box>
            {/*}
            <Box
              paddingLeft={12}
              paddingVertical={8}
              css={`
                background-color: ${selectedNav === 'people' ? 'rgba(118, 134, 138, 0.24)' : 'transparent'};
              `}
            >
              <Link
                as={NavLink}
                to="/people"
                title="People"
                onClick={() => setSelectedNav(['people', userState?.defaultCompany])}
              >
                <Icon icon={People} size={28} />
              </Link>
            </Box>
            {*/}
          </>
        )}
      </Box>
      <AreYouSure
        modalOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onComplete={deleteFilterHandler}
        isLoading={isLoading}
      >
        Are you sure you want to delete this filter?
      </AreYouSure>
    </nav>
  );
}

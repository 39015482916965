import React from 'react';
import { Tile } from 'shared-components';
import { Icon } from '@grownode/ui';
import 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { getPlantsByGroups } from 'shared-selectors';
import { traceZoneDown } from 'lib/utils';
import { GroupWork } from '@styled-icons/material-twotone/GroupWork';

export const GroupsTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {
  const isLarge = useResponsiveQuery({ from: 'md' });
  const plants = useSelector(state => state?.models?.inventory || {});
  let allPlantsBelow = {};
  if (!thisSpace || thisSpace === 'root') {
    allPlantsBelow = plants;
  } else {
    const spaceTrace = traceZoneDown(spaces, thisSpace === 'root' ? null : thisSpace);
    for (const [key, val] of Object.entries(plants)) {
      if (spaceTrace.includes(val?.spaceId)) {
        if (!allPlantsBelow[key]) {
          allPlantsBelow[key] = val;
        }
      }
    }
  }

  const plantGroups = getPlantsByGroups(allPlantsBelow)
  const groupKeys = Object.keys(plantGroups).sort();

  const createTotals = isListing => {
    return (
      <>
        <div
          css={`
            font-size: 28px;
            font-weight: 400;
            text-align: center;
            height: 1.2em;
          `}
        >
          {groupKeys?.length || 0}
        </div>
        <div
          css={`
            text-align: center;
            font-size: 12px;
          `}
        >
          {(groupKeys?.length > 0 && !isListing) ? (
            <>
              {plantGroups[groupKeys[0]][0].strainName}
              {groupKeys?.length > 1 && (
                <div css={`font-size: 10px; line-height: 12px;`}>+ {groupKeys?.length - 1} more...</div>
              )}
            </>
          ) : (
            <>
              # of Groups
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Tile
      title={<><Icon icon={GroupWork} size={18} /> Groups</>}
      id="spaces_groups"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={groupKeys?.length > 0}
      content={createTotals(false)}
      expandedContent={
        <Composition
          areas={`
            Totals Listing
          `}
          templateCols="15% 1fr"
          templateRows="auto"
        >
          {({ Totals, Listing }) => (
            <>
              <Totals>
                {createTotals(true)}
              </Totals>
              <Listing>
                {groupKeys.map((group, i) => (
                  <div
                    key={i}
                    css={`
                      cursor: pointer;
                      display: inline-block;
                      max-width: ${isLarge ? '20%' : '38%'};
                      text-align: center;
                      margin-left: 8px;
                      margin-bottom: 8px;
                      margin-right: 8px;
                      vertical-align: top;
                      padding: 8px;
                      border-radius: 4px;
                      background: #DFDFDF;
                      transition: background 250ms ease-in-out;

                      &:hover {
                        background: #EFEFEF;
                      }
                    `}
                    onClick={ev => {
                      ev.stopPropagation();
                      console.log('Clicked Group!');
                      //navigate(`/spaces/${space.id}`);
                    }}
                  >
                    <div>
                      <Icon icon={GroupWork} size={18} />
                    </div>
                    <div
                      css={`font-size: 14px; font-weight: 400; line-height: 14px; margin-top: 8px;`}
                    >
                      {plantGroups[group][0].strainName}
                    </div>
                    <div css={`font-size: 12px;`}>
                      {plantGroups[group]?.length || 0} Plant{plantGroups[group]?.length !== 1 ? 's' : ''}
                    </div>
                    <div css={`font-size: 12px;`}>
                      {plantGroups[group][0]?.growthPhase ? (
                        <>
                          Growth Phase: <span css={`font-weight: 400;`}>{plantGroups[group][0].growthPhase}</span>
                        </>
                      ) : (
                        <>
                          <em>No Growth Phase</em>
                        </>
                      )}
                    </div>
                    <div css={`font-size: 12px;`}>
                      {plantGroups[group][0]?.attributes?.geneticStartDate?.value ? (
                        <>
                          Start Date: <span css={`font-weight: 400;`}>{plantGroups[group][0]?.attributes?.geneticStartDate?.value}</span>
                        </>
                      ) : (
                        <>
                          <em>No Start Date</em>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Listing>
            </>
          )}
        </Composition>
      }
    />
  )
};

export default GroupsTile;

import React, { useState, useEffect } from 'react';
import { Box, Composition } from 'atomic-layout';
import { Button } from '@grownode/ui';
import { DashSelect } from '../DashFields';
import { CustomModal } from './CustomModal';
import { AttributeForm } from './AttributeForm';
import { areObjectsSame } from 'lib/utils';
import { usePrevious } from 'lib/hooks';

export const Attributes = ({ plantData, attributeList, attributeErrors, isSubmitting, onChange = () => { } }) => {
  const [attribute, setAttribute] = useState(0);
  const [attrOut, setAttrOut] = useState(plantData && plantData?.attributes ? plantData?.attributes : {});
  const prevAttrs = usePrevious(plantData && plantData?.attributes ? plantData?.attributes : {});
  const [showCustom, setShowCustom] = useState(false);

  useEffect(() => {
    if (plantData && plantData?.attributes) {
      setAttrOut(plantData?.attributes || {});
    }
  }, [plantData]);

  const handleAttributeSelection = () => {
    if (attribute === 0) {
      setShowCustom(true);
    } else if (!Object.keys(attrOut).includes(attribute)) {
      const attrDetails = getAttributeDetails(attribute);
      setAttrOut({
        ...attrOut,
        [attribute]: {
          ...attrDetails,
          value: ''
        }
      });
      setAttribute(0);
    }
  };

  const rebuildWithout = (attrObj, keyToRemove) => {
    const newAttrs = {};
    for (const [key, value] of Object.entries(attrObj)) {
      if (key !== keyToRemove) {
        newAttrs[key] = value;
      }
    }
    return newAttrs;
  };

  const handleAttributeDelete = slug => {
    const newAttrOut = rebuildWithout(attrOut, slug);
    setAttrOut(newAttrOut);
    setAttribute(0);
    setShowCustom(false);
  };

  const handleCustomAttribute = data => {
    setAttrOut({
      ...attrOut,
      [data.slug]: {
        ...data,
        value: ''
      }
    });
    setAttribute(0);
    setShowCustom(false);
  };

  const getAttributeDetails = attribute => {
    return attributeList.filter(item => {
      return item.slug === attribute;
    })[0];
  };

  const mapAttrOut = () => {
    const chosenAttrs = [];
    if (attrOut) {
      for (const [, value] of Object.entries(attrOut)) {
        chosenAttrs.push(value);
      }
    }
    return chosenAttrs;
  };

  const fieldChangeHandler = (field, value, label) => {
    const newAttrOut = {
      ...attrOut,
      [field]: {
        ...attrOut[field],
        value,
        label: label || value
      }
    };
    setAttrOut(newAttrOut);
  };

  useEffect(() => {
    if (typeof onChange === 'function') {
      const isSameObj = areObjectsSame(prevAttrs, attrOut);
      if (!isSameObj) {
        onChange(attrOut);
      }
    }
  }, [attrOut, prevAttrs, onChange]);

  const selectedAttrs = attrOut ? Object.keys(attrOut) : [];
  const filteredAttrs = attributeList.filter(item => {
    return !selectedAttrs.includes(item.slug);
  });

  if (!filteredAttrs) {
    return null;
  }

  return (
    <Box>
      <Composition
        areas={`attrs btn`}
        templateCols="auto 100px"
        templateRows="auto"
        alignItems="end"
        gap={8}
        marginBottom={16}
      >
        {({ Attrs, Btn }) => (
          <>
            <Attrs>
              <DashSelect
                id="attribute"
                name="attribute"
                label="Attribute"
                onChange={ev => {
                  setAttribute(ev.target.options[ev.target.selectedIndex].value);
                }}
                value={attribute}
                disabled={isSubmitting}
              >
                <option value="0">[Custom Attribute]</option>
                {filteredAttrs.map((attr, i) => {
                  return <option key={i} value={attr.slug}>{attr.title}</option>;
                })}
              </DashSelect>
            </Attrs>
            <Btn>
              <Button
                variant="primary"
                type="button"
                onClick={() => handleAttributeSelection()}
                height={40}
                disabled={isSubmitting}
              >
                Add
              </Button>
            </Btn>
          </>
        )}
      </Composition>
      {mapAttrOut().map((item, i) => {
        return (
          <Box key={i} marginBottom={16}>
            <AttributeForm
              attr={item}
              fieldChangeHandler={fieldChangeHandler}
              onDelete={handleAttributeDelete}
              attributeErrors={attributeErrors}
              isSubmitting={isSubmitting}
            />
          </Box>
        );
      })}
      <CustomModal modalOpen={showCustom} onComplete={handleCustomAttribute} onClose={() => setShowCustom(false)} />
    </Box>
  );
};

export default Attributes;

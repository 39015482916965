import { db } from 'lib/firebase';

export const changeContext = async (uid, newContext) => {
  return await db
    .collection('user-accounts')
    .doc(uid)
    .set({
      defaultCompany: newContext
    }, { merge: true });
};

export default changeContext;

import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase, { db } from '../firebase';
import store from '../../store';
import { rootReset } from 'store/actions';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {auth.isInitializing ? <div>Loading...</div> : children}
    </authContext.Provider>
  );
};

export function useAuth() {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);

  async function signin(email, password) {
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      if (!response.user.emailVerified) {
        setIsInitializing(false);
        throw new Error('EMAIL_NOT_VERIFIED');
      }
      const now = new Date();
      now.setSeconds(now.getSeconds() + 4 * 3600);
      try {
        await db
          .collection('user-accounts')
          .doc(response.user.uid)
          .update({
            lastUserLogin: firebase.firestore.Timestamp.fromDate(new Date()),
            sessionExpiry: firebase.firestore.Timestamp.fromDate(now)
          });
        setUser(response.user);
        setIsInitializing(false);
        return response.user;
      } catch (e) {
        setIsInitializing(false);
        throw new Error('USER_RECORD_NOT_EXISTING');
      }
    } catch (e) {
      await firebase
        .auth()
        .signOut();
      setIsInitializing(false);
      setUser(null);
      throw e;
    }
  };

  async function signinWithCustomToken(token) {
    try {
      setIsInitializing(true);
      const response = await firebase.auth().signInWithCustomToken(token);
      const now = new Date();
      now.setSeconds(now.getSeconds() + 4 * 3600);
      try {
        await db
          .collection('user-accounts')
          .doc(response.user.uid)
          .update({
            lastAgentLogin: firebase.firestore.Timestamp.fromDate(new Date()),
            sessionExpiry: firebase.firestore.Timestamp.fromDate(now)
          });
        setUser(response.user);
        setIsInitializing(false);
        return response.user;
      } catch (e) {
        throw new Error('USER_RECORD_NOT_EXISTING');
      }
    } catch (e) {
      setUser(null);
      throw e;
    }
  };

  async function signout() {
    if (window.dataListeners !== undefined) {
      window.dataListeners.forEach(listener => {
        for (const [key, func] of Object.entries(listener)) {
          if (typeof key === 'string' && typeof func === 'function') {
            func();
          }
        }
      });
    }
    try {
      return firebase
        .auth()
        .signOut()
        .then(() => {
          setUser(null);
          store.dispatch(rootReset());
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    let unsubscribe = null;
    try {
      setIsInitializing(true);
      unsubscribe = firebase.auth().onAuthStateChanged(async authUser => {
        if (authUser && authUser.emailVerified) {
          const result = await db
            .collection('user-accounts')
            .doc(authUser.uid)
            .get();
          if (result.exists) {
            setUser(authUser);
          } else {
            setUser(null);
          }
        } else {
          setUser(null);
        }
        setIsInitializing(false);
      });
      return () => unsubscribe();
    } catch (e) {
      setUser(null);
      setIsInitializing(false);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }, []);

  return {
    user,
    isInitializing,
    signin,
    signout,
    signinWithCustomToken
  };
};

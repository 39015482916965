import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'styled-components/macro';
import Graph from 'react-graph-vis';
import { styles } from '@grownode/ui';
import { useSlideOut } from 'lib/hooks';
import { DetailPopup } from './DetailPopup';

const options = {
  layout: {
    hierarchical: {
      direction: 'UD',
      parentCentralization: false,
      sortMethod: 'directed',
      nodeSpacing: 150,
      shakeTowards: 'roots'
    },
  },
  physics: {
    enabled: false
  },
  interaction: {
    dragNodes: false,
    dragView: false,
    zoomView: false
  },
  nodes: {
    font: {
      size: 12
    },
    shape: 'box'
  },
  edges: {
    color: "#000000",
    smooth: {
      forceDirection: true
    }
  }
};

export const NetworkTree = () => {
  const userState = useSelector((state) => state?.userRecord);
  const gateways = useSelector(state => state?.models?.gateways || null);
  const sensors = useSelector(state => state?.models?.nodes || null);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};
  const { setSlideOutOpen, setSlideOutTool, setEntityId } = useSlideOut();
  const [openNode, setOpenNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const events = {
    select: ({ nodes, pointer: { DOM } }) => {
      setSelectedNode(nodes[0] || null);
      const container = document.getElementById('graph-container');
      const containerPos = container.getBoundingClientRect();
      setOpenNode({
        x: (containerPos.x + window.scrollX) + DOM.x,
        y: (containerPos.y + window.scrollY) + DOM.y
      });
    },
    doubleClick: ({ nodes }) => {
      if (nodes[0]) {
        const entityParts = nodes[0].split('-');
        const type = entityParts[0] === 'GW' ? 'gateway' : 'sensor';
        const entityId = entityParts.splice(1, entityParts.length).join('-');
        setSlideOutTool(`hardware-${type}`);
        setEntityId(entityId);
        setSlideOutOpen(true);
      }
    }
  }

  const buildNetwork = () => {
    const gwKeys = gateways ? Object.keys(gateways).sort() : [];
    const sensorKeys = sensors ? Object.keys(sensors).sort() : [];
    const nodes = [
      { id: 1, label: currentCompany?.name, color: '#FFF' }
    ];
    const edges = [];

    gwKeys.forEach(gw => {
      const gwData = gateways[gw];
      const gwNodeId = `GW-${gw}`;
      nodes.push({
        id: gwNodeId,
        label: gwData.name,
        color: '#FFF'
      });
      edges.push({ from: 1, to: gwNodeId });
    });

    sensorKeys.forEach(sensor => {
      const item = sensors[sensor];
      const sensorGwAssignment = item?.lastSensorValues?.gatewayId || item?.gatewayId || null;
      const sensorNodeId = `S-${sensor}`;
      nodes.push({
        id: sensorNodeId,
        label: item.name ? item.name : sensor,
        color: '#FFF'
      });
      if (gwKeys.includes(sensorGwAssignment)) {
        const gwNodeId = `GW-${sensorGwAssignment}`;
        edges.push({ from: gwNodeId, to: sensorNodeId, color: 'red', dashes: !item?.isAuthed });
      }
    });

    return {
      nodes,
      edges
    };
  };

  const graph = buildNetwork();

  return (
    <div
      css={`
        border: 1px solid ${styles.colors.grayLight};
        width: 100%;
      `}
      id="graph-container"
    >
      {(openNode && selectedNode && selectedNode !== 1) && (
        <DetailPopup
          position={openNode}
          item={selectedNode}
        />
      )}
      <Graph
        graph={graph}
        options={options}
        events={events}
        style={{ height: "640px" }}
      />
    </div>
  );
};

export default NetworkTree;

import { createSelector } from 'reselect';

export const getSensors = state => state?.models?.nodes ?? {};

export const getSensorsAssigned = createSelector(getSensors, sensor => {
  const assignedSensors = {};
  for (const [key, value] of Object.entries(sensor)) {
    if (value?.isAuthed) {
      if (!assignedSensors[key]) {
        assignedSensors[key] = [];
      }
      assignedSensors[key].push({
        id: key,
        ...value
      });
    }
  }
  return assignedSensors;
});

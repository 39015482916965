import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import userRecord from 'shared-reducers/userRecord';
import models from 'shared-reducers/models';

const store = configureStore({
  reducer: { userRecord, models },
  middleware: getDefaultMiddleware({
    immutableCheck: false
  })
});

export default store;

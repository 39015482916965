import React, { useState } from 'react';
import { Composition } from 'atomic-layout';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { newsletterSignup } from 'lib/api';
import { Panel, PanelTitle, PanelTitleHeading, LoadingButton, FormMessage, Link, Icon } from '@grownode/ui';
import { DashInput, DashForm } from 'shared-components';
import 'styled-components/macro';
import { ChevronLeftCircle } from '@styled-icons/boxicons-regular/ChevronLeftCircle';

export const NewsletterSignup = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const goBack = resetForm => {
    setError(null);
    setSuccess(false);
    resetForm();
  };

  return (
    <Panel>
      <PanelTitle
        css={`margin-bottom: 0 !important;`}
      >
        <PanelTitleHeading>Stay Updated</PanelTitleHeading>
      </PanelTitle>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Please Enter a valid Email Address').required("Email Address Required"),
        })}
        validateOnMount
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            setError(null);
            await newsletterSignup(values?.email);
            setSuccess(true);
            actions.setSubmitting(false);
          } catch (e) {
            setError(e.message);
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, resetForm, isValid }) => {
          return (
            <DashForm>
              <Composition
                areas={`
                  Main
                  Btn
                `}
                gap={16}
                padding={8}
              >
                {({ Main, Btn }) => (
                  <>
                    <Main>
                      <p>
                        Keep up to date by entering your email address below so you don't miss out on what's coming with GrowNode!
                      </p>

                      {success && (
                        <FormMessage variant="success">
                          Thanks for signing up! Watch your email for updates!
                        </FormMessage>
                      )}
                      {error && (
                        <FormMessage variant="error">
                          {error}
                        </FormMessage>
                      )}
                      {!success && (
                        <DashInput
                          id="email"
                          name="email"
                          label="Email Address"
                          disabled={isSubmitting}
                        />
                      )}
                    </Main>
                    <Btn>
                      <div
                        css={`
                          text-align: right;
                        `}
                      >
                        {!success ? (
                          <LoadingButton
                            variant="primary"
                            type="submit"
                            disabled={!isValid || isSubmitting}
                          >
                            Sign Up
                          </LoadingButton>
                        ) : (
                          <Link onClick={() => goBack(resetForm)}><Icon icon={ChevronLeftCircle} size={14} /> Back</Link>
                        )}
                      </div>
                    </Btn>
                  </>
                )}
              </Composition>
            </DashForm>
          );
        }}
      </Formik>
    </Panel>
  );
};

export default NewsletterSignup;

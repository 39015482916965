import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "lib/hooks";
import "styled-components/macro";
import {
	Icon
} from "@grownode/ui";
import { Composition, Box } from "atomic-layout";
import { Nav } from "shared-pages/common";
import { Menu } from '@styled-icons/entypo/Menu';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { Drawer, Target, Trigger, CloseButton } from "@accessible/drawer";
import drawerStyles from "./drawer.module.scss";

export function MobileNav({ open = false, setMobileDrawerOpen }) {
	const { user } = useAuth();
	const isLoggedIn = user !== null;

	return (
		<Box>
      <>
        {isLoggedIn && (
          <>
            <Drawer
              open={open}
            >
              <Trigger>
                <Icon
                  icon={Menu}
                  size={28}
                  css={`
                    margin-top: 16px;
                    margin-left: 16px;
                    color: #FFF;
                  `}
                  onClick={() => setMobileDrawerOpen(!open)}
                />
              </Trigger>

              <Target
                placement="left"
                closedClass={drawerStyles.closed}
                openClass={drawerStyles.closed + " " + drawerStyles.open}
              >
                <Composition templateCols="1fr auto" templateRows="1fr">
                  <CloseButton>
                    <Box
                      css={`
                        font-weight: 600;
                        padding-top: 16px;
                      `}
                    >
                      <NavLink
                        to="/"
                        css={`
                          display: block;
                          color: #F44335;
                          text-decoration: none;
                          font-size: 20px;
                          font-weight: 400;
                          text-align: center;
                        `}
                      >
                        GrowNode
                      </NavLink>
                      <Nav setMobileDrawerOpen={setMobileDrawerOpen} />
                    </Box>
                  </CloseButton>
                  <Box
                    paddingTop={40}
                    paddingHorizontal={24}
                    css={`
                      background: #222;
                      color: #FFF;
                    `}
                  >
                    <CloseButton>
                      <Icon
                        icon={CloseOutline}
                        size={24}
                        onClick={() => setMobileDrawerOpen(false)}
                      />
                    </CloseButton>
                  </Box>
                </Composition>
              </Target>
            </Drawer>
          </>
        )}
      </>
    </Box>
	);
}

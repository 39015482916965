import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  DialogModal,
  DialogModalHeader,
  DialogModalCloser,
  DialogModalBody,
  styles
} from '@grownode/ui';
import { FallBackLoader } from 'shared-components';
import { Box } from 'atomic-layout';
import { useSelector } from 'react-redux';
import { deleteZone } from 'lib/api';
import 'styled-components/macro';
import { DownArrow } from '@styled-icons/boxicons-regular/DownArrow';
import { Close } from '@styled-icons/evaicons-solid/Close';

export const ActionMenu = ({
  onSelect,
  spaces,
  thisSpace = null,
  //subSpaces,
  shown = ['spaces', 'plants', 'hardware', 'people', 'tasks', 'editSpace', 'archive']
}) => {
  const userState = useSelector((state) => state?.userRecord);
  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isZoneDeleting, setIsZoneDeleting] = useState(false);

  const currentSpace = spaces ? {
    id: thisSpace,
    ...spaces[thisSpace]
  } : {};

  const toggleDeleteConfirm = () => {
    setModalOpen(!modalOpen);
  };

  const handleClick = tool => {
    if (tool === 'Archive') {
      setModalOpen(true);
    } else if (typeof onSelect === 'function') {
      onSelect(tool);
    }
    setMenuOpen(false);
  };

  const hideOnClickOutside = element => {
    const outsideClickListener = event => {
      if (element && !element.contains(event.target) && isVisible(element)) {
        setMenuOpen(false);
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

  useEffect(() => {
    hideOnClickOutside(document.querySelector('.ActionMenu > ul'));
  });

  return (
    <div
      className="ActionMenu"
      css={`
        text-align: right;
        display: inline-block;
      `}
    >
      <Button
        variant="primary"
        rightEnhancer={<Icon icon={DownArrow} size={14} />}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        css={`
          width: 120px;
        `}
      >
        <span
          css={`
            margin-right: 4px;
          `}
        >
          Actions
        </span>
        <span
          css={`
            margin-right: 4px;
          `}
        >
          |
        </span>
      </Button>
      {menuOpen && (
        <ul
          css={`
            margin-left: 8px !important;
            width: 104px;
            position: absolute;
            z-index: 2;
            background: #FFF;
            list-style: none;
            margin: 0;
            padding: 0;
            border: 1px solid rgba(0,8,16,.15);
            border-top: none;
            box-shadow: 1px 2px 4px -2px rgb(0 0 0 / 50%);

            & > li {
              text-align: left;
              cursor: pointer;
              padding: 4px;
              font-size: 12px;

              &:hover {
                background: #EEE;
              }
            }
          `}
        >
          {shown.includes('spaces') && (
            <li onClick={() => handleClick('Spaces')}>
              Add Spaces
            </li>
          )}
          {shown.includes('plants') && (
            <li onClick={() => handleClick('Plants')}>
              Add Plants
            </li>
          )}
          {shown.includes('hardware') && (
            <li onClick={() => handleClick('Hardware')}>
              Add Hardware
            </li>
          )}
          {/*shown.includes('people') && (
            <li onClick={() => handleClick('People')}>
              Add People
            </li>
          )*/}
          {shown.includes('tasks') && (
            <li onClick={() => handleClick('Tasks')}>
              Add Tasks
            </li>
          )}
          {(currentSpace && shown.includes('editSpace')) && (
            <li onClick={() => handleClick('spaces-edit')}>
              Edit Space
            </li>
          )}
          {(currentSpace && shown.includes('archive')) && (
            <li onClick={() => handleClick('Archive')}>
              Archive Space
            </li>
          )}
        </ul>
      )}
      <DialogModal
        isOpen={modalOpen}
        onBackgroundClick={toggleDeleteConfirm}
        onEscapeKeydown={toggleDeleteConfirm}
        css={`width: 30rem; height: 11rem;`}
      >
        <DialogModalHeader>
          Are you sure?
          <DialogModalCloser onClick={() => setModalOpen(false)}>
            <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
          </DialogModalCloser>
        </DialogModalHeader>
        <DialogModalBody
          css={`padding: 16px;`}
        >
          {isZoneDeleting && (
            <FallBackLoader />
          )}
          {!isZoneDeleting && (
            <>
              Are you sure you want to archive this space and all it's child spaces? (This cannot be undone)
              <Box css={`text-align: right; padding-right: 8px !important; padding-bottom: 8px !important; margin-top: 20px;`}>
                <span css={`margin-right: 8px;`}>
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => {
                      toggleDeleteConfirm();
                    }}
                  >
                    Cancel
                  </Button>
                </span>
                <Button
                  type="button"
                  variant="tertiary"
                  onClick={async () => {
                    setIsZoneDeleting(true);
                    await deleteZone({
                      zoneId: currentSpace.id,
                      companyId: userState?.defaultCompany
                    });
                    setIsZoneDeleting(false);
                    toggleDeleteConfirm();
                  }}
                >
                  Archive Now
                </Button>
              </Box>
            </>
          )}
        </DialogModalBody>
      </DialogModal>
    </div>
  );
};

export default ActionMenu;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Icon,
  DialogModal,
  DialogModalHeader,
  DialogModalBody,
  DialogModalCloser,
  LoadingButton,
  FormMessage,
  Text,
  styles
} from '@grownode/ui';
import 'styled-components/macro';
import { Composition, Box } from 'atomic-layout';
import { Formik } from 'formik';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { Attributes, FallBackLoader, DashForm } from 'shared-components';
import { PLANT_ATTRIBUTES } from '../../../../constants';
import { traceZone } from 'lib/utils';
import { updatePlants } from 'lib/api';

export const BulkEdit = ({
  open,
  setOpen,
  plants,
  companyId,
  onSuccess
}) => {
  const [attributeErrors, setAttributeErrors] = useState({});
  const [attributes, setAttributes] = useState({});
  const [attributeList, setAttributeList] = useState([]);
  const [openPlants, setOpenPlants] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [review, setReview] = useState(false);
  const [spaceMap, setSpaceMap] = useState({});
  const spaces = useSelector((state) => state?.models?.zones);
  const plantsState = useSelector((state) => state?.models?.inventory);
  const userState = useSelector((state) => state?.userRecord);
  const companyContext = useSelector((state) => state?.models?.companyContext);
  const companyData = userState && companyContext ? companyContext[userState?.defaultCompany] : null;

  useEffect(() => {
    const attrList = [];
    for (const [key, value] of Object.entries(attributes)) {
      attrList.push({
        slug: key,
        title: value.title,
        value: value.value,
        label: value.label
      });
    }
    setAttributeList(attrList);
  }, [attributes]);

  const areAttributesValid = () => {
    let hasErrors = false;
    const attributeErr = {};
    for (const [key, value] of Object.entries(attributes)) {
      if (value?.choices?.length > 0 && !value?.choices.includes(value.value)) {
        attributeErr[key] = 'Invalid Selection';
        hasErrors = true;
      } else if (value.value.trim() === '') {
        attributeErr[key] = 'Required';
        hasErrors = true;
      }
    }
    setAttributeErrors(attributeErr);
    return !hasErrors;
  };

  if (!companyContext || !userState || !plantsState) {
    return (
      <FallBackLoader isPage />
    );
  }

  const finalAttributeList = companyData ? [
    ...companyData.plantCustomAttributes,
    ...PLANT_ATTRIBUTES
  ] : PLANT_ATTRIBUTES;
  const spaceList = [];
  const spaceChoices = [];
  for (const [spaceId,] of Object.entries(spaces)) {
    const traced = traceZone(spaces, spaceId);
    traced.reverse();
    const spaceStr = traced.map(a => a.name).join(' > ');
    spaceList.push(spaceStr);
    spaceChoices.push(spaceId);
    if (!spaceMap[spaceStr]) {
      setSpaceMap({
        ...spaceMap,
        [spaceStr]: spaceId
      });
    }
  };
  const sortedAttributeList = [
    {
      slug: 'strainName',
      title: 'Strain Name',
      type: 'text'
    },
    {
      slug: 'plantType',
      title: 'Plant Type',
      type: 'text'
    },
    {
      slug: 'batchId',
      title: 'Batch ID',
      type: 'text'
    },
    {
      slug: 'growthPhase',
      title: 'Growth Phase',
      type: 'text'
    },
    {
      slug: 'spaceId',
      title: 'Space',
      type: 'text',
      choices: spaceChoices,
      labels: spaceList
    },
    ...finalAttributeList.sort((a, b) => {
      if (a.title === b.title) { return 0; }
      return a.title > b.title ? 1 : -1;
    })
  ];

  const getSpaceTreeSimple = spaceId => {
    const traced = traceZone(spaces, spaceId);
    traced.reverse();
    return traced.map(a => a.name).join(' > ');
  }

  const handleSubmit = async () => {
    try {
      const result = await updatePlants({
        plantIdList: plants,
        companyId,
        attributes
      });
      if (result?.data?.success) {
        setSuccess(true);
        setReview(false);
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } else {
        setError(result?.data?.error);
      }
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  }

  const togglePlantView = plantId => {
    if (openPlants.includes(plantId)) {
      const newOpenPlants = openPlants.filter(plant => plant !== plantId);
      setOpenPlants(newOpenPlants);
    } else {
      setOpenPlants([
        ...openPlants,
        plantId
      ]);
    }
  };

  const closeActions = () => {
    setOpen(false);
    setReview(false);
  };

  return (
    <DialogModal
      isOpen={open}
      onBackgroundClick={closeActions}
      onEscapeKeydown={closeActions}
      width="30rem"
      height="40rem"
    >
      <DialogModalHeader>
        Bulk Edit Plants
        <DialogModalCloser onClick={closeActions}>
          <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
        </DialogModalCloser>
      </DialogModalHeader>
      <DialogModalBody
        allowScroll={true}
        css={`max-height: 36rem; overflow-y: scroll;`}
      >
        {success && (
          <Box
            padding={8}
            marginBottom={16}
            css={`
              border: 1px solid ${styles.colors.secondaryGreen};
              color: ${styles.colors.secondaryGreen};
              font-size: 15px;
            `}
          >
            You have successfully updated {plants.length} plant{plants.length !== 1 ? 's' : ''}
            <div
              css={`text-align: center; margin-top: 16px;`}
            >
              <Button
                onClick={closeActions}
                type="button"
                variant="primary"
                rightEnhancer={<Icon icon={Close} size={12} />}
              >
                Close Window
              </Button>
            </div>
          </Box>
        )}
        {!success && review && (
          <Composition
            areas={`ControlLeft ControlRight
            Listing Listing`}
            gap={16}
            templateRows="40px 1fr"
            templateCols="1fr 1fr"
          >
            {({ ControlLeft, ControlRight, Listing }) => (
              <>
                <ControlLeft>
                  <Button
                    type="button"
                    variant="secondary"
                    leftEnhancer={<Icon icon={ChevronThinLeft} size={12} />}
                    onClick={() => {
                      setReview(false);
                    }}
                  >
                    Back
                  </Button>
                </ControlLeft>
                <ControlRight>
                  <div
                    css={`text-align: right;`}
                  >
                    <Button
                      type="button"
                      variant="primary"
                      rightEnhancer={<Icon icon={ChevronThinRight} size={12} />}
                      onClick={handleSubmit}
                    >
                      Complete
                    </Button>
                  </div>
                </ControlRight>
                <Listing>
                  <div
                    css={`height: 520px; overflow-y: scroll;`}
                  >
                    {error && (
                      <Box
                        padding={8}
                        marginBottom={16}
                        css={`
                          border: 1px solid ${styles.colors.secondaryRed};
                          color: ${styles.colors.secondaryRed};
                          font-size: 14px;
                        `}
                      >
                        {error}
                      </Box>
                    )}
                    <Text>Please review your changes below:</Text>
                    <ul
                      css={`margin: 0; padding: 0; list-style-type: none;`}
                    >
                      {plants.map((plant, i) => {
                        const plantData = plantsState[plant];
                        return (
                          <li key={i}>
                            <div
                              css={`
                                cursor: pointer;
                                background: #DEDEDE;

                                &:hover {
                                  background: #AAA;
                                  color: #FFF;
                                }
                              `}
                              onClick={() => togglePlantView(plantData['companyPlantId'])}
                            >
                              <Composition
                                areas={`PlantId StrainName PlantType`}
                                templateCols="100px 1fr 1fr"
                                gap={16}
                                padding={8}
                              >
                                {({ PlantId, StrainName, PlantType }) => (
                                  <>
                                    <PlantId>
                                      {plantData['companyPlantId']}
                                    </PlantId>
                                    <StrainName>
                                      {plantData['strainName']}
                                    </StrainName>
                                    <PlantType>
                                      {plantData['plantType']}
                                    </PlantType>
                                  </>
                                )}
                              </Composition>
                            </div>
                            <div
                              css={`font-size: 11px;`}
                            >
                              {openPlants.includes(plantData['companyPlantId']) && (
                                <>
                                  {attributeList.map((attr, x) => {
                                    let oldValue = <em>Not Previously Set</em>;
                                    if (['strainName', 'plantType', 'batchId', 'growthPhase', 'spaceId'].includes(attr.slug)) {
                                      oldValue = attr.slug === 'spaceId' ? getSpaceTreeSimple(plantData[attr.slug]) : plantData[attr.slug];
                                    } else if (plantData.attributes[attr.slug]) {
                                      oldValue = plantData.attributes[attr.slug].value;
                                    }
                                    return (
                                      <Composition
                                        areas={`Attribute From To`}
                                        templateCols="100px 1fr 1fr"
                                        gap={16}
                                        padding={4}
                                        key={x}
                                      >
                                        {({ Attribute, From, To }) => (
                                          <>
                                            <Attribute>
                                              <strong>{attr.title}</strong>
                                            </Attribute>
                                            <From>
                                              <strong>From:</strong> "{oldValue}"
                                            </From>
                                            <To>
                                              <strong>To:</strong> "{attr.label}"
                                            </To>
                                          </>
                                        )}
                                      </Composition>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Listing>
              </>
            )}
          </Composition>
        )}
        {!success && !review && (
          <Formik
            initialValues={{}}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              try {
                if (areAttributesValid()) {
                  setReview(true);
                }
              } catch (e) {
                setError(e.message);
                actions.setSubmitting(false);
              }
            }}
          >
            {({ values, isValid, isSubmitting }) => {
              return (
                <DashForm>
                  {error && (
                    <FormMessage variant="error">
                      {error}
                    </FormMessage>
                  )}
                  <Attributes
                    attributes={attributes}
                    attributeList={sortedAttributeList}
                    attributeErrors={attributeErrors}
                    isSubmitting={isSubmitting}
                    onChange={attrs => { setAttributes(attrs); }}
                  />
                  <LoadingButton
                    type="submit"
                    variant="tertiary"
                    height={40}
                    width="100%"
                    isLoading={isSubmitting}
                    rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                    disabled={!isValid || Object.keys(attributes).length < 1}
                  >
                    Apply
                  </LoadingButton>
                </DashForm>
              );
            }}
          </Formik>
        )}
      </DialogModalBody>
    </DialogModal>
  );
};

export default BulkEdit;

import _styled from "styled-components";
import { colors } from "../shared/styles";
export var Heading = _styled.h1.withConfig({
  displayName: "Typography__Heading",
  componentId: "sc-ak9bcx-0"
})(["font-weight:400;", ""], function (props) {
  switch (props.as) {
    case "h2":
      return "\n          font-size: 16px;\n          line-height: 20px;\n          color: ".concat(colors.primaryBlue, ";\n        ");
    case "h3":
      return "\n          font-size: 14px;\n          line-height: 1.5em;\n        ";
    case "h4":
      return "\n          font-size: 12px;\n          line-height: 14px;\n        ";
    case "h5":
      return "\n          font-size: 11px;\n          line-height: 13px;\n        ";
    case "h6":
      return "\n          font-size: 10px;\n        line-height: 12px;\n        ";
    default:
      return "\n          font-size: 24px;\n          line-height: 26px;\n          color: ".concat(colors.primaryBlue, ";\n          font-weight: 400;\n        ");
  }
});
export var Text = _styled.p.withConfig({
  displayName: "Typography__Text",
  componentId: "sc-ak9bcx-1"
})(["", ""], function (props) {
  switch (props.as) {
    case "blockquote":
      return "\n          margin-left: 40px;\n          margin-top: 20px;\n          margin-bottom: 20px;\n          font-size: 16px;\n          line-height: 20px;\n          color: ".concat(colors.primaryGray, ";\n        ");
    default:
      return "\n          line-height: 1.5em;\n          font-weight: 400;\n        ";
  }
});
export var List = _styled.ul.withConfig({
  displayName: "Typography__List",
  componentId: "sc-ak9bcx-2"
})(["font-size:14px;line-height:20px;margin-bottom:20px;padding-left:", ";color:", ";list-style-type:", ";& > li{display:", ";}", ""], function (props) {
  return props.inline || props.noBullets ? "0" : "2em";
}, colors.primaryGray, function (props) {
  return props.noBullets ? "none" : "disc";
}, function (props) {
  return props.inline ? "inline-block" : "list-item";
}, function (props) {
  switch (props.as) {
    case "ol":
      return "\n          list-style-type: ".concat(props.noBullets ? "none" : "decimal", ";\n        ");
    default:
      return "";
  }
});
export var ListItem = _styled.li.withConfig({
  displayName: "Typography__ListItem",
  componentId: "sc-ak9bcx-3"
})(["font-size:14px;line-height:1.5em;font-weight:300;"]);
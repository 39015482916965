import React from 'react';

const messages = {
  PASSWORD_RESET_EMAIL_ERROR: (
    <span>
      An unknown error occurred when trying to send you a password reset email,
      please try again later.
    </span>
  ),
  'USER_NOT_FOUND': (
    <span>
      An unknown error occurred when trying to send you a password reset email,
      please try again later.
    </span>
  ),
  UNHANDLED_ERROR: 'Oops! Something went wrong!'
};

export const FormError = ({ errorCode }) => {
  console.log(errorCode);
  return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

import React, { useState, useContext, createContext } from 'react';
const slideOutContext = createContext();

export function ProvideSlideOut({ children }) {
  const slideout = useProvideSlideOut();
  return (
    <slideOutContext.Provider value={slideout}>
      {children}
    </slideOutContext.Provider>
  );
}

export function useSlideOut() {
  return useContext(slideOutContext);
}

function useProvideSlideOut() {
  const [slideOutOpen, setSlideOutOpen] = useState(false);
  const [slideOutTool, setSlideOutTool] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [spaceId, setSpaceId] = useState(null);

  return {
    slideOutOpen,
    setSlideOutOpen,
    slideOutTool,
    setSlideOutTool,
    entityId,
    setEntityId,
    spaceId,
    setSpaceId
  };
}

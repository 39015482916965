import axios from 'axios';
import { auth } from '../../firebase';

export const savePlant = async ({
  plantId,
  companyId,
  strainName,
  growthPhase,
  plantType,
  batchId,
  spaceId,
  numPlants = 1,
  attributes,
  isNewPlant
}) => {
	const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/savePlant`;
  const token = await auth.currentUser.getIdToken();
	try {
		const response = await axios.post(
			apiUrl,
			{
        plantId,
        companyId,
        strainName,
        growthPhase,
        plantType,
        batchId,
        spaceId,
        numPlants,
        attributes,
        isNewPlant
			},
			{
				headers: {
					'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
				},
				validateStatus: status => {
					return status >= 200 && status < 500;
				}
			}
		);
		return response;
	} catch (e) {
		throw e;
	}
};

export default savePlant;

import _taggedTemplateLiteral from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from "styled-components";
import { colors } from "../shared/styles";
export var Tabs = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tborder: 1px solid rgb(220, 220, 220);\n"])));
export var TabContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tbackground: ", ";\n\tdisplay: block;\n"])), function (props) {
  return props.theme.colors.plain;
});
export var TabLink = styled.a(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: inline-block;\n\tposition: relative;\n\tpadding: 10px;\n\ttext-align: center;\n\tcolor: ", ";\n\ttext-decoration: none;\n\tcursor: pointer;\n\tbackground: ", ";\n\tvertical-align: top;\n\theight: 30px;\n\tline-height: 30px;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tborder-right: 1px solid ", ";\n\n\t&:last-child {\n\t\tborder-right: none;\n\t}\n\n\t&:hover {\n\t\topacity: 0.7;\n\t}\n\n\t&::before {\n\t\tdisplay: ", ";\n\t\tcontent: \"\";\n\t\tposition: absolute;\n\t\tz-index: 1;\n\t\twidth: 10px;\n\t\theight: 10px;\n\t\tbackground: ", ";\n\t\ttransform: rotate(45deg);\n\t\tleft: 50%;\n\t\tmargin-left: -5px;\n\t\tbottom: -5px;\n\t}\n"])), function (props) {
  return props.active ? props.theme.colors.plain : props.theme.colors.secondaryBlue;
}, function (props) {
  return props.active ? props.theme.colors.secondaryBlue : props.theme.colors.plain;
}, function (props) {
  return props.theme.colors.grayRegular;
}, function (props) {
  return props.active ? "inline-block" : "none";
}, function (props) {
  return props.theme.colors.secondaryBlue;
});
export var TabContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\tdisplay: ", ";\n\tpadding: 10px 30px 30px;\n\toverflow: hidden;\n\tbackground-color: ", ";\n\tborder-top: 1px solid ", ";\n\tfont-weight: 300;\n\tfont-size: 14px;\n"])), function (props) {
  return props.active ? "block" : "none";
}, function (props) {
  return props.theme.colors.grayLight;
}, function (props) {
  return props.theme.colors.grayRegular;
});
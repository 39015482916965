import axios from 'axios';
import { auth } from '../../firebase';

export const activateGateway = async ({
  verificationCode,
  gatewayId,
  gatewayName,
  companyId
}) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/activateGateway`;
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      {
        verificationCode,
        gatewayId,
        gatewayName,
        companyId
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default activateGateway;

import React from 'react';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import { Loader } from '@grownode/ui';

export function FallBackLoader({ isPage }) {
  return (
    <Box
      css={`
        text-align: center;
        margin: ${isPage ? '50px' : 0};
      `}
    >
      <Loader color="primaryBlue" size={60} />
    </Box>
  );
}

export default FallBackLoader;

import React from 'react';
import { Layout } from 'shared-components';
import { Panel } from '@grownode/ui';

export function Disclosures() {
  return (
    <Layout.BasicPage>
      <Panel>Disclosures Page</Panel>
    </Layout.BasicPage>
  );
}

export default Disclosures;

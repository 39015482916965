import 'styled-components/macro';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import { Box } from 'atomic-layout';
import {
  Text,
  Panel,
  PanelTitle,
  FormCheckbox,
  Button,
  Link,
  FormMessage,
  PanelTitleHeading,
  Icon
} from '@grownode/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { useAuth } from 'lib/hooks';
import { fetchUserRecord } from 'shared-reducers/userRecord';
import {
  DashInput,
  DashPassword,
  DashForm,
  FallBackLoader
} from 'shared-components';
import { FormError } from './components';

export function Login({ onSignin }) {
  const [error, setError] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { signin, signout } = useAuth();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  return (
    <Box>
      <Box maxWidth={550} paddingVertical={48} marginHorizontal="auto">
        <Panel paddingVertical={48} paddingHorizontal={60}>
          <PanelTitle
            large
            css={`
              text-align: center;
            `}
          >
            <PanelTitleHeading large>GrowNode Login</PanelTitleHeading>
          </PanelTitle>
          {isLoggingIn && (
            <>
              <FallBackLoader isPage />
              <Text
                css={`
                  text-align: center;
                  margin-top: 0;
                `}
              >
                Logging In...
              </Text>
            </>
          )}
          {!isLoggingIn && (
            <>
              <Formik
                initialValues={{
                  username: "",
                  password: ""
                }}
                onSubmit={values => {
                  setIsLoggingIn(true);
                  signin(values.username, values.password)
                    .then(({ uid }) => dispatch(fetchUserRecord(uid)))
                    .then(({ payload }) => {
                      if (typeof onSignin === "function") {
                        return onSignin();
                      }
                    })
                    .then(() => navigate(location?.state?.from ?? '/dashboard'))
                    .catch(async e => {
                      await signout();
                      setIsLoggingIn(false);
                      setError(e.message);
                    });
                }}
              >
                {props => (
                  <DashForm>
                    {error && (
                      <FormMessage variant="error">
                        <FormError errorCode={error} />
                      </FormMessage>
                    )}
                    <DashInput
                      id="username"
                      name="username"
                      label="Username"
                      disabled={isLoggingIn}
                    />
                    <DashPassword
                      id="password"
                      name="password"
                      label="Password"
                      disabled={isLoggingIn}
                    />
                    <Box
                      flex
                      justifyContent="space-between"
                      marginVertical={16}
                    >
                      <FormCheckbox disabled={isLoggingIn}>
                        Remember me
                      </FormCheckbox>
                      <Link
                        as={NavLink}
                        to="/forgot-password"
                        underline
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Forgot Password?
                      </Link>
                    </Box>
                    <Button
                      type="submit"
                      variant="tertiary"
                      height={40}
                      width="100%"
                      disabled={isLoggingIn}
                      rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                    >
                      Client Login
                    </Button>
                  </DashForm>
                )}
              </Formik>
              <Box
                marginTop={40}
                css={`
                  text-align: center;
                `}
              >
                <Text as="span">New to GrowNode?</Text>
                <br />
                <Link as={NavLink} to="/activate" underline>
                  Register for an account now
                </Link>
              </Box>
            </>
          )}
        </Panel>
      </Box>
    </Box>
  );
}

import axios from 'axios';

export const forgotPassword = async ({ email }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/forgotPassword`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        email
      },
      {
        headers: {
          'Content-type': 'application/json'
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    return response;
  } catch (e) {
    console.log(e.message);
    throw new Error('PASSWORD_RESET_EMAIL_ERROR');
  }
};

export default forgotPassword;

import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, NetworkTree } from 'shared-components';
import {
  Link,
  Breadcrumbs,
  BreadcrumbsItem,
  Panel,
  PanelTitle,
  PanelTitleHeading
} from '@grownode/ui';
import 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export function Hardware() {
  const userState = useSelector((state) => state?.userRecord);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};

  return (
    <Layout.BasicPage>
      <Breadcrumbs>
        <BreadcrumbsItem>
          <Link as={NavLink} to="/dashboard">{currentCompany?.name}</Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem>Hardware</BreadcrumbsItem>
      </Breadcrumbs>
      <Panel>
        <PanelTitle>
          <PanelTitleHeading>Hardware</PanelTitleHeading>
        </PanelTitle>
        <NetworkTree />
      </Panel>
    </Layout.BasicPage>
  );
}

export default Hardware;

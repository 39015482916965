import React from 'react';
import {
  FormInput,
  FormPassword,
  FormDatePicker,
  FormTextArea,
  FormSelect,
  FormCheckbox,
  FormSwitch
} from '@grownode/ui';
import { useField, Form } from 'formik';
import { Composition } from 'atomic-layout';
import NumberFormat from 'react-number-format';

export function DashInput({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormInput
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashCheckbox({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormCheckbox
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashNumberFormat({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <NumberFormat
      customInput={FormInput}
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashSwitch({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormSwitch
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashPassword({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormPassword
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashTextArea({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormTextArea
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashDatePicker({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormDatePicker
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashSelect({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField({ as: 'select', ...props });
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormSelect
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
};

export function DashForm(props) {
  return <Composition as={Form} gap={16} {...props} />;
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'lib/firebase';

export const fetchUserRecord = createAsyncThunk(
  'userRecord/fetchUserRecord',
  async (userUid, thunkAPI) => {
    try {
      const doc = firebase
        .firestore()
        .collection('user-accounts')
        .doc(userUid);
      const snapshot = await doc.get();
      const data = snapshot.data();
      return {
        id: snapshot.id,
        ...data,
        lastUserLogin: data.lastUserLogin?.toDate().toISOString(),
        lastAgentLogin: data.lastUserLogin?.toDate().toISOString(),
        sessionExpiry: data.sessionExpiry?.toDate().toISOString()
      };
    } catch (e) {
      throw e;
    }
  }
);

const userRecordSlice = createSlice({
  name: 'userRecord',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserRecord.fulfilled, (state, action) => {
      return action.payload;
    })
  }
});

export default userRecordSlice.reducer;

import React from 'react';
import { Composition } from 'atomic-layout';
import { useSelector } from 'react-redux';
import { useSlideOut } from 'lib/hooks';
import { format, fromUnixTime } from 'date-fns/fp';
import 'styled-components/macro';
import { styles, Icon } from '@grownode/ui';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import * as CONSTANTS from '../../constants';

export const DetailPopup = ({
  position,
  item
}) => {
  const gateways = useSelector(state => state?.models?.gateways || null);
  const sensors = useSelector(state => state?.models?.nodes || null);
  const { setSlideOutOpen, setSlideOutTool, setEntityId } = useSlideOut();
  const type = item?.split('-')[0] || null;
  const entityId = item.replace(`${type}-`, '');

  if (!type) return null;

  let data = {};
  if (type === 'GW') {
    const gateway = gateways[entityId];
    data = {
      title: gateway?.name || '',
      created: new Date(gateway?.ts) || '?'
    };
  } else {
    const sensor = sensors[entityId];
    data = {
      title: sensor?.name || '',
      created: new Date(fromUnixTime(sensor?.ts?.seconds)) || '?',
      isAuthed: sensor?.isAuthed || false,
      signal: sensor?.lastSensorValues?.signal || null,
      batt: sensor?.lastSensorValues?.batt || null,
      lastData: sensor?.lastSensorValues?.time || null,
      flags: sensor?.lastSensorValues?.flags || null,
      type: sensor?.type || null,
    };
  }

  const editEntity = () => {
    setEntityId(entityId);
    setSlideOutTool(type === 'GW' ? 'hardware-gateway' : 'hardware-sensor');
    setSlideOutOpen(true);
  };

  return (
    <div
      css={`
        background: #FFF;
        border: 1px solid ${styles.colors.grayLight};
        position: absolute;
        top: ${position.y}px;
        left: ${position.x}px;
        z-index: 2;
        margin-left: 8px;
        margin-top: -12px;
        padding: 8px;
        border-radius: 4px;
        font-size: 12px;

        &::before {
          position: absolute;
          display: inline-block;
          content: "";
          height: 8px;
          width: 8px;
          border-left: 1px solid ${styles.colors.grayLight};
          border-top: 1px solid ${styles.colors.grayLight};
          transform: rotate(-45deg);
          margin-left: -13px;
          background: #FFF;
          margin-top: 4px;
        }
      `}
    >
      <Composition
        areas={`
          Title
          Content
          Controls
        `}
        gap={4}
        templateRows="32px auto 24px"
      >
        {({ Title, Content, Controls }) => (
          <>
            <Title>
              <strong>
                {data.title}
              </strong>
              <div
                css={`
                  font-size: 10px;
                  line-height: 10px;
                `}
              >
                {entityId}
              </div>
            </Title>
            <Content>
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  {(type === 'S' && data?.type) && (
                    <tr>
                      <td css={`padding-right: 4px; padding-bottom: 4px;`} colSpan={2}>
                        Sensor Type:
                        <div css={`font-size: 10px; line-height: 10px;`}>{CONSTANTS.SENSORS[data?.type] || 'unknown'}</div>
                      </td>
                    </tr>
                  )}
                  {type === 'S' && (
                    <tr>
                      <td css={`padding-right: 4px;`}>
                        Authorized?
                      </td>
                      <td>
                        {data?.isAuthed ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td css={`padding-right: 4px;`}>
                      Created:
                    </td>
                    <td>
                      {format('MM/dd/yyyy h:mm aaa', data.created)}
                    </td>
                  </tr>
                  {(type === 'S' && data?.lastData) && (
                    <tr>
                      <td css={`padding-right: 4px;`}>
                        Last Data:
                      </td>
                      <td>
                        {format('MM/dd/yyyy h:mm aaa', fromUnixTime(data?.lastData))}
                      </td>
                    </tr>
                  )}
                  {(type === 'S' && data?.signal) && (
                    <tr>
                      <td css={`padding-right: 4px;`}>
                        Signal Strength:
                      </td>
                      <td>
                        {data?.signal}
                      </td>
                    </tr>
                  )}
                  {(type === 'S' && data?.batt) && (
                    <tr>
                      <td css={`padding-right: 4px;`}>
                        Battery:
                      </td>
                      <td>
                        {data?.batt}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Content>
            <Controls>
              <div
                css={`
                  text-align: right;
                `}
              >
                <Icon
                  icon={Edit}
                  size={16}
                  css={`
                    cursor: pointer;
                    color: ${styles.colors.secondaryBlueLight};
                  `}
                  title="Edit"
                  onClick={editEntity}
                />
              </div>
            </Controls>
          </>
        )}
      </Composition>
    </div>
  );
};

export default DetailPopup;

import { createSelector } from 'reselect';

export const getPlants = state => state?.models?.inventory ?? {};

export const getSpacePlants = createSelector(getPlants, plants => {
  const plantsBySpace = {};
  for (const [key, value] of Object.entries(plants)) {
    const spaceId = value?.spaceId;
    if (!plantsBySpace[spaceId]) {
      plantsBySpace[spaceId] = [];
    }
    plantsBySpace[spaceId].push({
      id: key,
      ...value
    });
  }
  return plantsBySpace;
});

export const getPlantsByPhase = (plants) => {
  const plantsBySpace = {};
  for (const [key, value] of Object.entries(plants)) {
    const growthPhase = value?.growthPhase;
    if (!plantsBySpace[growthPhase]) {
      plantsBySpace[growthPhase] = [];
    }
    plantsBySpace[growthPhase].push({
      id: key,
      ...value
    });
  }
  return plantsBySpace;
};

export const getPlantsByGroups = (plants) => {
  const plantsByGroups = {};
  for (const [key, value] of Object.entries(plants)) {
    const strainName = value?.strainName;
    const growthPhase = value?.growthPhase;
    const startDate = value?.attributes?.geneticStartDate?.value;
    const groupKey = `${strainName}_${growthPhase}_${startDate}`.replace(/\s/ig, '-').replace(/\//ig, '-').toLowerCase();
    if (!plantsByGroups[groupKey]) {
      plantsByGroups[groupKey] = [];
    }
    plantsByGroups[groupKey].push({
      id: key,
      ...value
    });
  }
  return plantsByGroups;
};

import axios from 'axios';
import { auth } from '../../firebase';

export const saveLoginSettings = async ({
  existingUsername,
  existingPassword,
  newPassword,
  newUsername,
  editUsername,
  editPassword
}) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/updateLoginSettings`;
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios.post(
      apiUrl,
      {
        existingUsername,
        existingPassword,
        newPassword,
        newUsername,
        editUsername,
        editPassword
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    if (response?.status !== 200) {
      throw new Error(response?.data?.error);
    }
    return response?.data;
  } catch (e) {
    console.log(e.message);
    throw new Error(e.message);
  }
};

export default saveLoginSettings;

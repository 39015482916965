import React, { useState } from 'react';
import { Composition, Box } from 'atomic-layout';
import 'styled-components/macro';
import { Button } from '@grownode/ui';
import { slugify } from 'lib/utils';
import { DashSelect, DashInput, DashCheckbox } from '../DashFields';

export const Custom = ({ onAdded }) => {
  const [limitChoices, setLimitChoices] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [fieldChoices, setFieldChoices] = useState('');

  const handleAdd = () => {
    let customAttrChoices = fieldChoices.split(',').filter(choice => choice !== '');
    customAttrChoices.forEach((item, index) => { customAttrChoices[index] = item.trim(); });
    const customAttrDetails = {
      type: fieldType,
      slug: 'customAttr-' + slugify(fieldName),
      title: fieldName,
      value: '',
      required: isRequired,
      choices: customAttrChoices,
      isCustom: true
    };
    onAdded(customAttrDetails);
  };

  return (
    <Composition  
      areas={`type
      name
      required
      choices
      btn`}
      templateCols="auto"
      templateRows="auto"
      alignItems="center"
      gap={8}
    >
      {({ Type, Name, Required, Choices, Btn }) => (
        <>
          <Type>
            <DashSelect
              id="customFieldType"
              name="customFieldType"
              label="Type"
              value={fieldType}
              onChange={ev => {
                setFieldType(ev.target.value);
              }}
            >
              <option value="">-- Choose One --</option>
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
            </DashSelect>
          </Type>
          <Name>
            <DashInput
              id="customFieldName"
              name="customFieldName"
              value={fieldName}
              label="Name"
              type="text"
              onChange={ev => {
                setFieldName(ev.target.value);
              }}
            />
          </Name>
          <Required>
            <DashCheckbox
              id="customFieldRequired"
              name="customFieldRequired"
              value="isRequired"
              checked={isRequired}
              onChange={() => {
                setIsRequired(!isRequired);
              }}
            >
              Required?
            </DashCheckbox>
          </Required>
          <Choices>
            {fieldType !== 'date' && (
              <DashCheckbox
                id="customFieldHasChoices"
                name="customFieldHasChoices"
                value="hasChoices"
                checked={limitChoices}
                onChange={() => {
                  setLimitChoices(!limitChoices);
                }}
              >
                Limit Choices?
              </DashCheckbox>
            )}
            <Box marginTop={16} css={`min-height: 61px;`}>
              {fieldType !== 'date' && limitChoices && (
                <DashInput
                  id="customFieldChoices"
                  name="customFieldChoices"
                  label="Enter possible choices for this field separated by commas"
                  value={fieldChoices}
                  onChange={ev => {
                    setFieldChoices(ev.target.value);
                  }}
                />
              )}
            </Box>
          </Choices>
          <Btn>
            <Box css={`text-align: right;`}>
              <Button
                variant="primary"
                type="button"
                onClick={handleAdd}
                height={40}
              >
                Add
              </Button>
            </Box>
          </Btn>
        </>
      )}
    </Composition>
  );
};

export default Custom;

import _taggedTemplateLiteral from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from "styled-components";
import { colors } from "../shared/styles";
export var Footer = styled.footer(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tclear: both;\n\tcolor: ", ";\n\tfont-size: 12px;\n\tfont-family: Open Sans, sans-serif;\n\tpadding-top: 40px;\n\tpadding-bottom: 30px;\n\tpadding-left: 15px;\n\tpadding-right: 15px;\n\tbackground: ", ";\n"])), colors.plain, colors.black);
export var FooterItem = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: ", ";\n\tline-height: ", ";\n\tfont-weight: 300;\n\n\tpadding-left: ", ";\n\tpadding-right: ", ";\n\tborder-left: ", ";\n\n\t&:first-child {\n\t\tpadding-left: 0;\n\t\tborder-left: none;\n\t}\n\n\ta {\n\t\tcolor: ", ";\n\t\tcursor: pointer;\n\t\tfont-size: 12px;\n\t}\n"])), function (props) {
  return props.inline ? "inline-block" : "block";
}, function (props) {
  return props.inline ? "15px" : "30px";
}, function (props) {
  return props.inline ? "15px" : "0px";
}, function (props) {
  return props.inline ? "15px" : "0px";
}, function (props) {
  return props.separator ? "1px solid #fff" : "none";
}, colors.plain);
export var FooterSeparator = styled.hr(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\topacity: 0.25;\n\tmargin-top: 10px;\n\tmargin-bottom: 10px;\n\theight: 0;\n\tborder: none;\n\tborder-bottom: 1px solid ", ";\n"])), colors.grayRegular);
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styled-components/macro';
import { FallBackLoader, DashTabs } from 'shared-components';
import { Panel, Row, Column, Icon } from '@grownode/ui';
import { Plant } from '@styled-icons/remix-line/Plant';
import { LogIn } from '@styled-icons/boxicons-regular/LogIn';
import { IdCard } from '@styled-icons/boxicons-regular/IdCard';
import { Settings } from '@styled-icons/feather/Settings';
import { Grows, Login, Contact, Communication } from '../lazy';

export function TabNav({ sub }) {
  const navigate = useNavigate();

  const handleTabSelect = tab => {
    navigate(`/settings/${tab}`);
  };

  const tabConfig = [];
  tabConfig.push({
    id: "grows",
    title: (
      <>
        <Row marginBottom={4} paddingTop={12}>
          <Column cols={2} colsLg={12}>
            <Icon icon={Plant} size={24} />
          </Column>
          <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
            Grows
          </Column>
        </Row>
      </>
    ),
    content: (
      <Suspense fallback={<FallBackLoader />}>
        <Grows />
      </Suspense>
    )
  });

  tabConfig.push({
    id: "login",
    title: (
      <>
        <Row marginBottom={4} paddingTop={12}>
          <Column cols={2} colsLg={12}>
            <Icon icon={LogIn} size={24} />
          </Column>
          <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
            Login Settings
          </Column>
        </Row>
      </>
    ),
    content: (
      <Suspense fallback={<FallBackLoader />}>
        <Login />
      </Suspense>
    )
  });

  tabConfig.push({
    id: "contact",
    title: (
      <>
        <Row marginBottom={4} paddingTop={12}>
          <Column cols={2} colsLg={12}>
            <Icon icon={IdCard} size={24} />
          </Column>
          <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
            Contact Info
          </Column>
        </Row>
      </>
    ),
    content: (
      <Suspense fallback={<FallBackLoader />}>
        <Contact />
      </Suspense>
    )
  });

  tabConfig.push({
    id: "preferences",
    title: (
      <>
        <Row marginBottom={4} paddingTop={12}>
          <Column cols={2} colsLg={12}>
            <Icon icon={Settings} size={24} />
          </Column>
          <Column cols={10} colsLg={12} paddingTop={4} paddingTopLg={0}>
            Communication Preferences
          </Column>
        </Row>
      </>
    ),
    content: (
      <Suspense fallback={<FallBackLoader />}>
        <Communication />
      </Suspense>
    )
  });

  return (
    <Panel>
      <DashTabs
        config={tabConfig}
        selectedId={sub}
        onTabClick={handleTabSelect}
      />
    </Panel>
  );
}

export default TabNav;

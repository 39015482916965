import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import firebase from 'lib/firebase';

export const setModel = createAsyncThunk(
  'growNodeModels/setModel',
  async (model, thunkAPI) => {
    try {
      return model;
    } catch (e) {
      console.log(e);
    }
  }
);

export const setCompanyList = createAsyncThunk(
  'growNodeModels/setCompanyList',
  async (companyList, thunkAPI) => {
    try {
      return companyList;
    } catch (e) {
      console.log(e);
    }
  }
);

export const setCompanyContext = createAsyncThunk(
  'growNodeModels/setCompanyContext',
  async (companyContext, thunkAPI) => {
    try {
      return companyContext;
    } catch (e) {
      console.log(e);
    }
  }
);

export const setUserGateways = createAsyncThunk(
  'growNodeModels/setUserGateways',
  async (userGateways, thunkAPI) => {
    try {
      return userGateways;
    } catch (e) {
      console.log(e);
    }
  }
);

export const setNewGateways = createAsyncThunk(
  'growNodeModels/setNewGateways',
  async (newGateways, thunkAPI) => {
    try {
      return newGateways;
    } catch (e) {
      console.log(e);
    }
  }
);

export const callModel = createAsyncThunk(
  'growNodeModels/callModel',
  async (model, thunkAPI) => {
    try {
      const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/${model}Data`;
      const token = await firebase.auth().currentUser.getIdToken();
      await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        }
      });
      return true;
    } catch (e) {
      console.log(e);
    }
  }
);

const modelsSlice = createSlice({
  name: 'growNodeModels',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(setModel.fulfilled, (state, action) => {
        if (action.payload.gateways) {
          for (const [id, value] of Object.entries(action.payload.gateways)) {
            if (value.ts) {
              action.payload.gateways[id].ts = value.ts.toDate().valueOf();
            }
          }
        }
        return {
          ...state,
          ...action.payload
        };
      })
      .addCase(setCompanyList.fulfilled, (state, action) => {
        return {
          ...state,
          membership: {
            ...state.membership,
            ...action.payload
          }
        };
      })
      .addCase(setCompanyContext.fulfilled, (state, action) => {
        return {
          ...state,
          companyContext: action.payload
        };
      })
      .addCase(setUserGateways.fulfilled, (state, action) => {
        return {
          ...state,
          userGateways: {
            ...state.userGateways,
            ...action.payload
          }
        };
      })
      .addCase(setNewGateways.fulfilled, (state, action) => {
        return {
          ...state,
          newGateways: {
            ...state.newGateways,
            ...action.payload
          }
        };
      })
  }
});

export default modelsSlice.reducer;

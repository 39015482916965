import axios from 'axios';
import { auth } from '../../firebase';
import { TIME_ZONES } from '../../../constants';

export const setCompanyData = async ({
  companyId,
  batchIdPrefix,
  defaultTimeZone,
  plantIdPrefix,
  defaultUnits,
  name
}) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/saveCompany`;
    const response = await axios.post(
      apiUrl,
      {
        companyId,
        batchIdPrefix,
        defaultTimeZone,
        defaultTimeZoneOffset: Number(TIME_ZONES[defaultTimeZone][1]),
        plantIdPrefix,
        defaultUnits,
        name
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    if (response.status !== 200) {
      throw new Error(response?.data?.error);
    }
  } catch (e) {
    console.log(e.message);
    throw e;
  }
};

export default setCompanyData;

import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { Composition, Only } from 'atomic-layout';
import { Helmet } from 'react-helmet';
import 'styled-components/macro';
import {
  useAuth,
  useDataModelsListener
} from 'lib/hooks';
import { MODELS } from '../../../../constants';
import { Private, FallBackLoader, SlideOut } from 'shared-components';
import {
  Login,
  Nav,
  Header,
  Footer as GrowNodeFooter
} from 'shared-pages/common';
import {
  About,
  Disclosures,
  EmailPreferences,
  Plants,
  Spaces,
  SpacesDashboard,
  Hardware,
  HardwareGatewayActivate,
  Home,
  Dash,
  //People,
  PrivacyPolicy,
  Settings,
  Terms
} from 'shared-pages/dashboard/lazy';
import {
  Activate,
  ForgotPassword,
  ResetPassword
} from 'shared-pages/dashboard';
import { Icon } from '@grownode/ui';
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft';

export function Dashboard() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const userRecordData = useSelector(state => state.userRecord);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [leftRailOpen, setLeftRailOpen] = useState(true);
  const [leftMenuWidth, setLeftMenuWidth] = useState(0);
  const [leftRailWidth, setLeftRailWidth] = useState(0);
  const uid = user !== null ? user.uid : null;
  const companyId = userRecordData !== null ? userRecordData?.defaultCompany : null;

  useEffect(() => {
    if (companyId !== null && companyId !== undefined) {
      setCurrentCompany(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    let timeout = null;
    if (user === null) {
      setLeftRailWidth(0);
      setLeftMenuWidth(0);
    } else if (leftRailWidth === 0 && leftRailOpen) {
      setLeftRailWidth(256);
      setLeftMenuWidth(256);
    } else if (!leftRailOpen) {
      setLeftMenuWidth(50);
      timeout = window.setTimeout(() => {
        setLeftRailWidth(0);
      }, 250);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    }
  }, [user, leftRailWidth, leftRailOpen]);

  useDataModelsListener(uid, MODELS, dispatch, currentCompany, userRecordData);

  const dashboardRoutes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/dashboard', element: <Private><Dash /></Private> },
    { path: '/about', element: <About /> },
    { path: '/activate', element: <Activate /> },
    { path: '/disclosures', element: <Disclosures /> },
    { path: '/email-preferences/:uid', element: <EmailPreferences /> },
    { path: '/plants', element: <Private><Plants /></Private> },
    { path: '/spaces', element: <Private><Spaces /></Private> },
    { path: '/spaces/:spaceId', element: <Private><SpacesDashboard /></Private> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/hardware', element: <Private><Hardware /></Private> },
    { path: '/hardware/gateways/new/:gatewayId/:activationCode', element: <Private><HardwareGatewayActivate /></Private> },
    { path: '/hardware/gateways/new/:gatewayId', element: <Private><HardwareGatewayActivate /></Private> },
    { path: '/login', element: <Login /> },
    //{ path: '/people', element: <Private><People /></Private> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/settings', element: <Private><Settings /></Private> },
    { path: '/settings/:sub', element: <Private><Settings /></Private> },
    { path: '/terms', element: <Terms /> }
  ]);

  return (
    <Composition
      areas={`
        topbar topbar
        main main
        footer footer
      `}
      areasLg={`
        topbar topbar
        leftrail main
        footer footer
      `}
      templateColsLg={leftRailWidth + 'px 1fr'}
      templateCols="1fr"
      templateRows="64px 1fr auto"
      flexDirection="column"
      height="100vh"
    >
      {({ Topbar, Leftrail, Main, Footer }) => (
        <>
          <Helmet>
            <title>GrowNode Dashboard</title>
          </Helmet>
          <Topbar>
            <Header mobileDrawerOpen={mobileDrawerOpen} setMobileDrawerOpen={setMobileDrawerOpen} />
          </Topbar>
          <Only from="lg">
            <div
              css={`
                background: #FFF;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
                width: ${leftMenuWidth}px;
                transition: width 250ms ease-in-out;
                height: 100%;
              `}
            >
              {user !== null && (
                <>
                  <span
                    onClick={() => {
                      setLeftRailOpen(!leftRailOpen);
                    }}
                    css={`
                      cursor: pointer;
                      display: inline-block;
                      width: 16px;
                      line-height: 32px;
                      margin-left: ${leftMenuWidth - 16}px;
                      opacity: 1;
                      transition: margin-left 250ms ease-in-out;

                      &: hover {
                        opacity: 0.7;
                      }
                    `}
                    title={leftMenuWidth < 256 ? 'Maximize Menu' : 'Minimize Menu'}
                  >
                    <Icon
                      css={`
                        transform: ${leftMenuWidth < 256 ? 'rotate(180deg)' : 'rotate(0deg)'};
                        transition: transform 250ms ease-in-out;
                      `}
                      icon={ChevronLeft}
                      size={14}
                    />
                  </span>
                  <Leftrail>
                    <Nav isShrunk={(leftMenuWidth < 256)} />
                  </Leftrail>
                </>
              )}
            </div>
          </Only>
          <div
            css={`
              margin-left: ${(leftRailWidth < 256) ? leftMenuWidth : 0}px;
            `}
          >
            <Main>
              <Suspense fallback={<FallBackLoader isPage />}>
                {dashboardRoutes}
              </Suspense>
            </Main>
            <SlideOut />
          </div>
          <Footer marginTop="auto">
            <GrowNodeFooter />
          </Footer>
        </>
      )}
    </Composition>
  );
};

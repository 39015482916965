import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserRecord } from 'shared-reducers/userRecord';
import { Composition } from 'atomic-layout';
import { Icon } from '@grownode/ui';
import { PinAngle } from '@styled-icons/bootstrap/PinAngle';
import { PinAngleFill } from '@styled-icons/bootstrap/PinAngleFill';
import { CloseSquareOutline } from '@styled-icons/evaicons-outline/CloseSquareOutline';
import { CloseSquare } from '@styled-icons/evaicons-solid/CloseSquare';
import { setSpaceCustomization } from 'lib/api/user';

export const PinControl = ({
  sensorIds = [],
  spaceId,
  sensorDataKey,
  index,
  individual = false
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state?.userRecord);
  const [companyId, setCompanyId] = useState(userData?.defaultCompany || null);
  const [customizations, setCustomizations] = useState(userData?.customizations || {});

  useEffect(() => {
    setCompanyId(userData?.defaultCompany);
    setCustomizations(userData?.customizations || {});
  }, [userData]);

  const customCompany = customizations && customizations[companyId] ? customizations[companyId] : {};
  const customSpace = customCompany[spaceId] || {};
  const pinnedItems = customCompany?.pinned || {};
  const customSensorKey = customSpace[sensorDataKey] || {};

  if (!userData || !sensorIds?.length || !spaceId || !companyId || !sensorDataKey) {
    return null;
  }

  const isPinned = () => {
    let pinned = false;
    if (pinnedItems[spaceId] && pinnedItems[spaceId][sensorDataKey]) {
      pinned = true;
    }
    return pinned;
  };

  const isShown = () => {
    let shown = false;
    if (!customSpace || !customSpace[sensorDataKey] || customSpace[sensorDataKey]?.show === true) {
      shown = true;
    }
    return shown;
  };

  const pin = async () => {
    await setSpaceCustomization({
      uid: userData.id,
      companyId,
      spaceId,
      sensorDataKey,
      sortOrder: customSensorKey?.sortOrder || index,
      show: customSensorKey?.show || true,
      pinToCompany: !isPinned()
    });
    dispatch(fetchUserRecord(userData.id));
  };

  const toggle = async () => {
    await setSpaceCustomization({
      uid: userData.id,
      companyId,
      spaceId,
      sensorDataKey,
      sortOrder: customSensorKey?.sortOrder || index,
      show: !isShown(),
      pinToCompany: isPinned()
    });
    dispatch(fetchUserRecord(userData.id));
  };

  const shownItem = isShown();
  const pinnedItem = isPinned();

  return (
    <div
      css={`
        display: inline-block;
        margin-left: 16px;
      `}
    >
      <Composition
        areas={`PinBtn ShowHide`}
        gap={4}
        templateCols={20}
        templateRows={20}
      >
        {({ PinBtn, ShowHide }) => (
          <>
            <PinBtn>
              <Icon
                size={16}
                icon={pinnedItem ? PinAngleFill : PinAngle}
                css={`cursor: pointer; &:hover { opacity: 0.7; }`}
                title={pinnedItem ? 'Un-Pin Chart from Grow Page' : 'Pin Chart to Grow Page' }
                onClick={pin}
              />
            </PinBtn>
            <ShowHide>
              {!individual && (
                <Icon
                  size={16}
                  icon={shownItem ? CloseSquareOutline : CloseSquare }
                  css={`cursor: pointer; &:hover { opacity: 0.7; }`}
                  title={shownItem ? 'Hide Chart' : 'Show Chart'}
                  onClick={toggle}
                />
              )}
            </ShowHide>
          </>
        )}
      </Composition>
    </div>
  );
};

export default PinControl;

import { db } from '../../firebase';

export const getCommunicationPreferences = async (uid) => {
  try {
    const preferences = await db.collection('email-preferences').doc(uid).get();
    if (preferences.exists) {
      return preferences.data();
    }
    return null;
  } catch (e) {
    console.log(e.message);
    throw e;
  }
};

export default getCommunicationPreferences;

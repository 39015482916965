import React from 'react';
import { Layout } from 'shared-components';
import { Panel, PanelTitle, PanelTitleHeading } from '@grownode/ui';

export function About() {
  return (
    <Layout.BasicPage>
      <Panel>
        <PanelTitle>
          <PanelTitleHeading>About Us</PanelTitleHeading>
        </PanelTitle>
        <div>About Us Page</div>
      </Panel>
    </Layout.BasicPage>
  );
}

export default About;

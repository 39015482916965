import { db } from '../../firebase';

export const setSpaceCustomization = async ({
  uid,
  companyId,
  spaceId,
  sensorDataKey,
  sortOrder,
  show,
  pinToCompany
}) => {
  try {
    const userRef = await db.collection('user-accounts').doc(uid).get();
    let currentCustomizations = {};
    if (userRef.exists) {
      const userData = userRef.data();
      if (userData?.customizations) {
        currentCustomizations = userData?.customizations[companyId];
      }

      const currentSensorKey = currentCustomizations[spaceId] ? currentCustomizations[spaceId][sensorDataKey] : {};
      const currentSortOrder = currentSensorKey && currentSensorKey?.sortOrder ? currentSensorKey.sortOrder : -1;
      const currentShown = currentSensorKey && currentSensorKey?.show ? currentSensorKey.show : true;
      const data = {
        ...userData?.customizations,
        [companyId]: {
          ...currentCustomizations || {},
          [spaceId]: {
            ...currentCustomizations[spaceId] || {},
            [sensorDataKey]: {
              sortOrder: typeof sortOrder === 'number' ? sortOrder : currentSortOrder,
              show: typeof show === 'boolean' ? show : currentShown
            }
          }
        }
      };
  
      // Adding pinned item
      if (pinToCompany === true) {
        if (!data[companyId]) {
          data[companyId] = {};
        }
        if (!data[companyId]?.pinned[spaceId]) {
          data[companyId].pinned[spaceId] = {};
        }
        data[companyId].pinned = {
          ...data[companyId]?.pinned || {},
          [spaceId]: {
            ...data[companyId].pinned[spaceId] || {},
            [sensorDataKey]: -1
          }
        };
      }
  
      // Removing pinned item
      if (pinToCompany === false) {
        const newPinned = {};
        if (currentCustomizations?.pinned) {
          for (const [key, value] of Object.entries(currentCustomizations?.pinned)) {
            for (const [fieldName, fieldValue] of Object.entries(value)) {
              if (!newPinned[key]) {
                newPinned[key] = {};
              }
              if (fieldName !== sensorDataKey) {
                newPinned[key][fieldName] = fieldValue;
              }
            }
          }
        }
        data[companyId].pinned = newPinned;
      }

      await db.collection('user-accounts').doc(uid).set({
        ...userData,
        customizations: {
          [`${companyId}`]: data[companyId]
        }
      });
    } else {
      throw new Error('USER_NOT_FOUND');
    }
  } catch (e) {
    console.log(e.message);
    throw new Error('PIN_UPDATE_ERROR');
  }
};

export default setSpaceCustomization;

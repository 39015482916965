import axios from 'axios';
import { auth } from '../../firebase';

export const deleteFilter = async ({
  companyId,
  filterName
}) => {
	const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/deleteFilter`;
  const token = await auth.currentUser.getIdToken();
	try {
		const response = await axios.post(
			apiUrl,
			{
        companyId,
        filterName
			},
			{
				headers: {
					'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
				},
				validateStatus: status => {
					return status >= 200 && status < 500;
				}
			}
		);
		return response;
	} catch (e) {
		throw e;
	}
};

export default deleteFilter;

import React from 'react';
import { Box } from 'atomic-layout';
import "styled-components/macro";
import {
  DialogModal,
  DialogModalHeader,
  DialogModalCloser,
  DialogModalBody,
  Icon,
  styles
} from '@grownode/ui';
import { Close } from "@styled-icons/evaicons-solid/Close";
import { Custom } from './Custom';

export const CustomModal = ({ modalOpen, onClose, onComplete }) => {
  return (
    <DialogModal
        isOpen={modalOpen}
        onBackgroundClick={onClose}
        onEscapeKeydown={onClose}
        css={`width: 25rem; height: 23rem;`}
      >
        <DialogModalHeader>
          Add Custom Attribute
          <DialogModalCloser onClick={() => onClose()}>
            <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
          </DialogModalCloser>
        </DialogModalHeader>
        <DialogModalBody>
          <Box css={`
            overflow-y: scroll;
            margin-bottom: 8px;

            & > div {
              padding: 0px 8px;
            }
          `}>
            <Custom onAdded={
              val => {
                onComplete(val);
                onClose();
              }}
            />
          </Box>
        </DialogModalBody>
      </DialogModal>
  );
};

export default CustomModal;

import React, { useState } from 'react';
import { Tile, ActionMenu } from 'shared-components';
import { Icon } from '@grownode/ui';
import { useNavigate } from 'react-router';
import 'styled-components/macro';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { sqFtCalc } from 'lib/utils';
import { useSlideOut } from 'lib/hooks';
import { getCanopy } from 'shared-selectors';
import { Workspaces } from '@styled-icons/material-twotone/Workspaces';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';

export const SpacesTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth,
  subSpaces,
  isHeader = false
}) => {
  const { setSlideOutOpen, setSlideOutTool, setEntityId, setSpaceId } = useSlideOut();
  const [spaceListShown, setSpaceListShown] = useState(false);
  const navigate = useNavigate();
  const isSmall = useResponsiveQuery({ to: 'md' });

  let activeSpaceCount = 0;
  for (const [, val] of Object.entries(spaces)) {
    if (val?.active !== false) {
      activeSpaceCount++;
    }
  }

  const actionSelected = tool => {
    if (tool === 'spaces-edit') {
      setEntityId(thisSpace);
      setSpaceId(thisSpace);
    } else {
      setEntityId('new');
      setSpaceId(thisSpace);
    }
    setSlideOutTool(tool);
    setSlideOutOpen(true);
  };

  const createDetails = () => {
    const space = spaces[thisSpace];
    const canopyAtLevel = spaces ? getCanopy(spaces, thisSpace) : 0;
    return (
      <div
        css={`          
          margin-top: 8px;
          font-size: ${isSmall ? '12' : 'inherit'}px;
        `}
      >
        {(space && space?.type) && (
          <div>
            <span css={`font-weight: 400;`}>Type of Space:</span>{' '}
            {(space.type && space.type === 'Other' && space.typeOther) ? (
              <>
                {space.typeOther}
              </>
            ) : (
              <>
                {space.type}
              </>
            )}
          </div>
        )}
        {space && (
          <>
            <div>
              <span css={`font-weight: 400;`}>Plantable Area:</span>{' '}
              {(canopyAtLevel > 0) && (
                <>
                  {' '}{canopyAtLevel}
                  <span css={`font-size: 11px;`}>Ft<sup>2</sup></span>
                </>
              )}
            </div>
            {(space.typeDimensionsWidth && space.typeDimensionsLength) && (
              <div>
                <span css={`font-weight: 400;`}>Dimensions:</span>{' '}
                <span css={`font-size: 12px;`}>
                  {space.typeDimensionsLength}'L x {space.typeDimensionsWidth}'W
                  {space.typeDimensionsHeight && (
                    <>
                      {' '}x {space.typeDimensionsHeight}'H
                    </>
                  )}
                </span>
              </div>
            )}
          </>
        )}
        {(thisSpace && subSpaces?.length > 0) && (
          <>
            <div
              css={`
                cursor: pointer;
                display: inline;

                &:hover {
                  text-decoration: underline;
                }
              `}
              onClick={() => setSpaceListShown(!spaceListShown)}
            >
              <Icon
                icon={spaceListShown ? ChevronDown : ChevronRight}
                size={16}
                css={`margin-right: 4px;`}
              />
              {subSpaces?.length} Sub-Space{subSpaces?.length === 1 ? '' : 's'}
            </div>
            {spaceListShown && (
              <ul
                css={`
                  margin-top: 0;
                  font-size: 13px;
                `}
              >
                {subSpaces.map((ss, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => navigate(`/spaces/${ss?.id}`)}
                      css={`
                        cursor: pointer;
                  
                        &:hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      {ss?.name}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
        {!thisSpace && (
          <div>
            {activeSpaceCount} Space{activeSpaceCount === 1 ? '' : 's'}
          </div>
        )}
      </div>
    );
  };

  const allowedActions = ['spaces', 'plants', 'archive'];
  if (thisSpace !== 'root') {
    allowedActions.push('editSpace');
  }

  return (
    <Tile
      title={<><Icon icon={Workspaces} size={18} /> {thisSpace && Object.keys(spaces).includes(thisSpace) ? spaces[thisSpace]?.name : 'Spaces'}</>}
      id="spaces_total"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      defaultExpanded={thisSpace}
      onClick={() => {
        if (!thisSpace) {
          navigate('/spaces');
        }
      }}
      isTitleBlock={thisSpace}
      isExpandable={(thisSpace && subSpaces.length > 0) && !thisSpace}
      hasSub={subSpaces.length > 0 || isHeader || (!isHeader && activeSpaceCount > 0)}
      content={!isHeader && (
        <>
          <div
            css={`
              font-size: 28px;
              font-weight: 400;
              text-align: center;
              height: 1.2em;
            `}
          >
            {activeSpaceCount}
          </div>
          <div
            css={`
              text-align: center;
              font-size: 12px;
            `}
          >
            Total Spaces
          </div>
        </>
      )}
      expandedContent={
        <Composition
          areas={`Details Controls`}
          templateCols="1fr 120px"
          marginHorizontal={8}
          gap={isSmall ? 0 : 8}
        >
          {({ Details, Listing, Controls }) => (
            <>
              <Details>
                {createDetails()}
              </Details>
              <Listing>
                {thisSpace && (
                  <>
                    {subSpaces.map((space, i) => (
                      <div
                        key={i}
                        css={`
                          cursor: pointer;
                          display: inline-block;
                          width: calc(${isSmall ? 50 : 30}% - 24px);
                          text-align: center;
                          margin-left: 8px;
                          margin-right: 8px;
                          vertical-align: top;
                          padding: 4px;
                          padding-left: 8px;
                          padding-right: 8px;
                          border-radius: 4px;
                          background: #DFDFDF;
                          transition: background 250ms ease-in-out;

                          &:first-child {
                            margin-left: 0;
                          }

                          &:last-child {
                            margin-right: 0;
                          }

                          &:hover {
                            background: #EFEFEF;
                          }
                        `}
                        onClick={ev => {
                          ev.stopPropagation();
                          navigate(`/spaces/${space.id}`);
                        }}
                      >
                        <div
                          css={`font-size: 14px; font-weight: 400;`}
                        >
                          <Icon icon={Workspaces} size={16} /> {space.name}
                        </div>
                        <div css={`font-size: 12px; font-weight: 400;`}>
                          {(space.type && space.type === 'Other' && space.typeOther) ? (
                            <>
                              {space.typeOther}
                            </>
                          ) : (
                            <>
                              {space.type}
                            </>
                          )}
                          {(space.typeDimensionsWidth && space.typeDimensionsLength) && (
                            <>
                              {' '}{sqFtCalc(space.typeDimensionsLength, space.typeDimensionsWidth)}
                              <span css={`font-size: 11px;`}>Ft<sup>2</sup></span>
                            </>
                          )}
                        </div>
                        <div>
                          <span css={`font-size: 12px;`}>
                            {(space.typeDimensionsWidth && space.typeDimensionsLength) && (
                              <>
                                {space.typeDimensionsLength}'L x {space.typeDimensionsWidth}'W
                              </>
                            )}
                            {space.typeDimensionsHeight && (
                              <>
                                {' '}x {space.typeDimensionsHeight}'H
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Listing>
              <Controls>
                <div css={`text-align: right; margin-bottom: 8px;`}>
                  <ActionMenu
                    onSelect={actionSelected}
                    spaces={spaces}
                    thisSpace={thisSpace}
                    shown={allowedActions}
                  />
                </div>
              </Controls>
            </>
          )}
        </Composition>
      }
    />
  )
};

export default SpacesTile;

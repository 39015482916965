import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ModalProvider } from 'styled-react-modal'
import { ProvideAuth } from 'lib/hooks/useAuth';
import { ProvideSlideOut } from 'lib/hooks/useSlideOut';
import { Dashboard } from './SubApps/Dashboard';
import growNodeStore from './store';

export function GrowNode() {
	return (
    <ProvideAuth>
      <ReduxProvider store={growNodeStore}>
        <ModalProvider>
          <ProvideSlideOut>
            <Dashboard />
          </ProvideSlideOut>
        </ModalProvider>
      </ReduxProvider>
    </ProvideAuth>
  );
};

import 'styled-components/macro';
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import { Box } from 'atomic-layout';
import {
  Text,
  Panel,
  PanelTitle,
  PanelTitleHeading,
  Link,
  LoadingButton,
  FormMessage,
  Icon
} from '@grownode/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { ChevronThinLeft } from '@styled-icons/entypo/ChevronThinLeft';
import { DashForm, DashInput } from 'shared-components';
import { FormError } from './components';
import { forgotPassword } from 'lib/api/forgotPassword';

export function ForgotPassword() {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  return (
    <Box>
      <Box maxWidth={500} paddingVertical={48} marginHorizontal="auto">
        <Panel paddingVertical={48} paddingHorizontal={60}>
          <PanelTitle
            large
            css={`
              text-align: center;
            `}
          >
            <PanelTitleHeading large>Forgot Your Password?</PanelTitleHeading>
          </PanelTitle>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email address')
                .required('Required'),
            })}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              try {
                const response = await forgotPassword({
                  email: values.email,
                });
                if (response.data.error) {
                  throw new Error(response.data.error);
                } else {
                  setSuccess(true);
                }
              } catch (e) {
                setError(e.message);
              }
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <DashForm>
                {error && (
                  <FormMessage variant="error">
                    <FormError errorCode={error} />
                  </FormMessage>
                )}
                {success && (
                  <FormMessage variant="success">
                    <Text
                      css={`
                        margin-top: 0;
                      `}
                    >
                      If this is an active user in our records, an email will be
                      arriving to your inbox shortly.
                    </Text>
                    <Text
                      css={`
                        margin-bottom: 0;
                      `}
                    >
                      Please check your email for instructions to reset your
                      password.
                    </Text>
                  </FormMessage>
                )}
                <DashInput
                  id="email"
                  name="email"
                  label="Email"
                  disabled={props.isSubmitting}
                />
                <LoadingButton
                  height={40}
                  type="submit"
                  variant="tertiary"
                  rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                  isLoading={props.isSubmitting}
                >
                  Reset Password
                </LoadingButton>
              </DashForm>
            )}
          </Formik>
          <Box
            marginTop={40}
            css={`
              text-align: center;
            `}
          >
            <Text as="span">Need help? Contact Customer Service</Text>
            <br />
            <Link href="tel:1-800-Grow-Node">
              1-800-Grow-Node
            </Link>
          </Box>
          <Box
            marginTop={20}
            css={`
              text-align: center;
            `}
          >
            <Link as={NavLink} to="/login">
              <Icon icon={ChevronThinLeft} size={12} />{' '}
              <Text
                as="span"
                css={`
                  vertical-align: middle;
                `}
              >
                Back
              </Text>
            </Link>
          </Box>
        </Panel>
      </Box>
    </Box>
  );
}

export default ForgotPassword;

import axios from 'axios';

export const resetPassword = async ({ oobCode, password }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/resetPassword`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        oobCode,
        password
      },
      {
        headers: {
          'Content-type': 'application/json'
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );
    return response;
  } catch (e) {
    throw e;
  }
};

export default resetPassword;

import _taggedTemplateLiteral from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from "styled-components";
import { colors } from "../shared/styles";
export var Accordion = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n"])));
export var AccordionBody = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: block;\n\theight: auto;\n\tmax-height: 4000px;\n\tfont-size: 14px;\n\ttransition: all 300ms ease-in-out;\n"])));
export var AccordionItem = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: block;\n\ttransition: max-height 300ms ease-in-out;\n\tpadding-left: 20px;\n\toverflow: ", ";\n\tmax-height: ", ";\n\n\t", " {\n\t\tline-height: ", ";\n\t\tcolor: ", ";\n\t\toverflow: ", ";\n\t\tvisibility: ", ";\n\t}\n"])), function (props) {
  return props.open ? "inherit" : "hidden";
}, function (props) {
  return props.open ? "4000px" : "25px";
}, AccordionBody, function (props) {
  return props.open ? 1.5 : 0;
}, function (props) {
  return props.open ? "#4a4a4a" : "transparent";
}, function (props) {
  return props.open ? "inherit" : "hidden";
}, function (props) {
  return props.open ? "visible" : "hidden";
});
export var AccordionHead = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\tcursor: pointer;\n\tcolor: ", ";\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tmargin-bottom: 10px;\n"])), colors.secondaryBlue);
import React from 'react';
import { useNavigate } from 'react-router';
import { Tile } from 'shared-components';
import { Icon } from '@grownode/ui';
import 'styled-components/macro';
import { People } from '@styled-icons/bootstrap/People';

export const PeopleTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {
  const navigate = useNavigate();

  return (
    <Tile
      title={<><Icon icon={People} size={18} /> People</>}
      id="spaces_people"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={false}
      onClick={() => {
        navigate('/people');
      }}
      content={
        <>
          <div
            css={`
              font-size: 28px;
              font-weight: 400;
              text-align: center;
              height: 1.2em;
            `}
          >
            1
          </div>
          <div
            css={`
              text-align: center;
              font-size: 12px;
            `}
          >
            Erik
            <div
              css={`font-size: 10px; line-height: 12px;`}
            >
              Head Cultivator
            </div>
          </div>
        </>
      }
    />
  )
};

export default PeopleTile;

const messages = {
	"The password is invalid or the user does not have a password.":
		"Incorrect Email/Password, please try again.",
	"Missing or insufficient permissions.":
		"Incorrect Email/Password, please try again.",
	"The email address is badly formatted.":
    "Incorrect Email/Password, please try again.",
  "There is no user record corresponding to this identifier. The user may have been deleted.":
    "Incorrect Email/Password, please try again.",
  "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.":
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  USER_RECORD_NOT_EXISTING: "Incorrect Email/Password, please try again.",
  EMAIL_NOT_VERIFIED: "Email address not verified, please check your email to find the link. You can also use the Forgot Password link below to both reset your password and verify your email address.",
	UNHANDLED_ERROR: "Oops! Something went wrong!"
};

export const FormError = ({ errorCode }) => {
	return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

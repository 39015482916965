import { configureStore } from '@reduxjs/toolkit';
import userRecord from 'shared-reducers/userRecord';
import models from 'shared-reducers/models';

const store = configureStore({
  reducer: { userRecord, models },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    });
  }
});

export default store;

import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import 'styled-components/macro';

export const ItemTypes = {
  TILE: 'tile'
};

export const DragList = ({ items, height = '71vh', onDragChange }) => {
  const [cards, setCards] = useState(items)
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
    if (typeof onDragChange === 'function') {
      onDragChange((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }));
    }
  }, [onDragChange]);

  useEffect(() => {
    setCards(items);
  }, [items]);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.Component}
        moveCard={moveCard}
      />
    )
  }, [moveCard]);

  return (
    <>
      <div
        css={`width: 100%; height: ${height};`}
      >
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    </>
  )
}

export const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.TILE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TILE,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <span
      ref={ref}
      data-handler-id={handlerId}
      css={`
        overflow: hidden;
        opacity: ${opacity};
        margin: 4px;
      `}
    >
      {text}
    </span>
  )
};

export default DragList;

import axios from 'axios';

export const verifyEmail = async ({ oobCode }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/verifyEmail/${oobCode}`;
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-type': 'application/json'
      },
      validateStatus: status => {
        return status >= 200 && status < 500;
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default verifyEmail;

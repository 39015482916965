export var colors = {
  primaryBlue: "#2C2C2E",
  primaryGray: "#c0c1c2",
  secondaryBlueLight: "#21b0e8",
  secondaryNavy: "#1e1e2f",
  secondaryRed: "#F44335",
  secondaryGreen: "#45ae57",
  secondaryBlue: "#76868A",
  secondaryYellow: "#ffc107",
  secondaryGray: "rgb(244, 246, 248)",
  tertiaryBlue: "#0e69a4",
  tertiaryBlueLight: "#199ed8",
  grayDark: "#E4DEE9",
  grayRegular: "#474A53",
  grayLight: "#76868A",
  grayLightest: "#e4dfe9",
  plain: "hsla(0,0%,100%,.8)",
  black: "#191919"
};
export var typography = {
  fontUrl: "https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap",
  type: {
    primary: 'Poppins, sans-serif'
  },
  letterSpacing: ".02em",
  weight: {
    regular: "400",
    bold: "700",
    extrabold: "800",
    black: "900"
  },
  size: {
    small: "14",
    medium: "24",
    large: "40"
  }
};
import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import { Box, useResponsiveQuery } from 'atomic-layout';
import { Container } from '@grownode/ui';

export function BasicPage({ children }) {
  const isXLarge = useResponsiveQuery({ from: 'lg' });
  const isLarge = useResponsiveQuery({ from: 'md', to: 'lg' });
  const isMedium = useResponsiveQuery({ from: 'sm', to: 'md' });
  const isSmall = useResponsiveQuery({ to: 'sm' });
  const [maxWidth, setMaxWidth] = useState('100%');

  useEffect(() => {
    if (isSmall) {
      setMaxWidth('574px');
    }
    if (isMedium) {
      setMaxWidth('766px');
    }
    if (isLarge) {
      setMaxWidth('991px');
    }
    if (isXLarge) {
      setMaxWidth('100%');
    }
  }, [isSmall, isMedium, isLarge, isXLarge]);

  return (
    <Box
      width={'100%'}
      maxWidth={maxWidth}
      css={`
        padding-bottom: 24px;
        padding-top: 24px;
      `}
    >
      <Container>{children}</Container>
    </Box>
  );
};

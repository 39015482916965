import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

export function Speedometer({ value, min, max, unit, safeStart, safeStop }) {
  let segmentStops = [min, max];
  let segmentColors = ['#FD5A1B', '#4ED12F'];
  if (safeStart && safeStop) {
    const dangerZone = (max / 5) / 2;
    const dangerLow = (safeStart - dangerZone) < min ? min : safeStart - dangerZone;
    const dangerHigh = (safeStop + dangerZone) > max ? max : safeStop + dangerZone;
    segmentStops = [min, dangerLow, safeStart, safeStop, dangerHigh, max];
    segmentColors = ['#FD5A1B', '#F6901E', '#4ED12F', '#F6901E', '#FD5A1B']
  }

  return (
    <ReactSpeedometer
      value={value}
      minValue={min}
      maxValue={max}
      fluidWidth={false}
      currentValueText={`Current Value: ${value} ${unit}`}
      maxSegmentLabels={5}
      segments={5}
      segmentColors={segmentColors}
      customSegmentStops={segmentStops}
      valueTextFontSize="12px"
      labelFontSize="10px"
    />
  );
}

export default Speedometer;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'styled-components/macro';
import {
  Button,
  Text,
  Icon,
  styles
} from '@grownode/ui';
import { Composition, Box } from 'atomic-layout';
import { DialogModal, DialogModalHeader, DialogModalBody, DialogModalCloser } from '@grownode/ui';
import { ZoneTree } from '../ZoneTree';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { traceZone } from 'lib/utils';

export function ZoneParentSelector({ selectedParent, setter, isSubmitting, titleText = 'Parent Space' }) {
  const [zonesLoading, setZonesLoading] = useState(true);
  const [zone, setZone] = useState(selectedParent);
  const [modalOpen, setModalOpen] = useState(false);
  const userState = useSelector((state) => state?.userRecord);
  const zones = useSelector((state) => state?.models?.zones);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};
  const zoneTree = currentCompany?.zoneTree || [];

  useEffect(() => {
    if (zoneTree?.length) {
      setZonesLoading(false);
    }
  }, [zoneTree]);

  const toggleZoneTree = () => {
    setModalOpen(!modalOpen);
  };

  const zoneTrace = selectedParent ? traceZone(zones, selectedParent) : [];
  if (zoneTrace?.length) {
    zoneTrace.reverse();
  }

  return (
    <>
      <Composition
        templateCols="1fr 134px"
        gap={8}
        areas={`Left Right`}
        alignItems="end"
      >
        {({
          Left,
          Right
        }) => (
          <>
            <Left>
              <label
                css={`
                  font-size: 12px;
                  line-height: 13px;
                  font-weight: 400;
                  display: block;
                  margin-bottom: 8px;
                `}
              >
                {titleText}
              </label>
              <Text
                as="span"
                css={`
                  font-size: 12px;
                `}
              >
                {!selectedParent && (
                  <em>{currentCompany?.name}</em>
                )}
                {selectedParent && (
                  <>
                    <span>{currentCompany?.name}</span>
                    {zoneTrace.map((zone, i) => {
                      return (
                        <span key={i}>
                          <Icon icon={ChevronRight} size={16} />
                          {zone.name}
                        </span>
                      );
                    })}
                  </>
                )}
              </Text>
            </Left>
            <Right>
              <Button
                type="button"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={() => {
                  toggleZoneTree();
                }}
                rightEnhancer={<Edit size={14} />}
                css={`
                  font-size: 12px;
                  padding-left: 0 !important;
                  padding-right: 8px !important;
                `}
                //style={{ width: 200 }}
              >
                Set {titleText}
              </Button>
            </Right>
          </>
        )}
      </Composition>
      <DialogModal
        isOpen={modalOpen}
        onBackgroundClick={toggleZoneTree}
        onEscapeKeydown={toggleZoneTree}
        css={`width: 23rem; height: 23rem;`}
        id="zone-parent-selector"
      >
        <DialogModalHeader>
          Set {titleText}
          <DialogModalCloser onClick={() => setModalOpen(false)}>
            <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
          </DialogModalCloser>
        </DialogModalHeader>
        <DialogModalBody>
          <Box css={`
            overflow-y: scroll;
            border-bottom: 1px solid ${styles.colors.grayLight};
            margin-bottom: 8px;

            & > div {
              padding: 0px;
              min-height: 13.5rem;
            }
          `}>
            <ZoneTree
              data={zoneTree}
              zonesLoading={zonesLoading}
              onChange={setZone}
              masterParentName={currentCompany?.name}
              expandAll={true}
            />
          </Box>
          <Box css={`text-align: right; padding-right: 8px !important; padding-bottom: 8px !important;`}>
            <Box css={`text-align: left;`}>
              <strong>Selected {titleText}: </strong>
            </Box>
            <Box css={`text-align: left; padding-bottom: 8px;`}>
              {(zone === 0 || zone === null) && (
                <em>{currentCompany?.name}</em>
              )}
              {(zones && zone !== 0) ? zones[zone]?.name : ''}
            </Box>
            <Button
              type="button"
              variant="tertiary"
              onClick={() => {
                setter(zone);
                toggleZoneTree();
              }}
            >
              Apply
            </Button>
          </Box>
        </DialogModalBody>
      </DialogModal>
    </>
  );
}

export default ZoneParentSelector;

import { db, auth } from '../../firebase';

export const getCompanyData = async companyId => {
  const uid = auth.currentUser.uid;
  const companyRef = await db.collection('companies').doc(companyId).get();
  if (companyRef.exists) {
    const companyData = companyRef.data();
    if (companyData.members[uid] > -1) {
      return {
        userPerms: companyData.members[uid],
        id: companyId,
        ...companyData
      }
    }
  }
  return null;
};

export default getCompanyData;

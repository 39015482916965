import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _styled from "styled-components";
import { Box } from "atomic-layout";
import { colors } from "../shared/styles";
export var Panel = _styled(Box).attrs({
  padding: 16,
  paddingSm: 32,
  marginBottom: 24
}).withConfig({
  displayName: "Panel",
  componentId: "sc-fi8yaj-0"
})(["background:#FFF;line-height:1.5em;border-radius:4px;box-shadow:rgb(63 63 68 / 5%) 0px 0px 0px 1px,rgb(63 63 68 / 15%) 0px 1px 2px 0px;"]);
export var PanelTitle = _styled(Box).attrs(function (props) {
  return _objectSpread({
    marginBottom: props.large ? 80 : 32,
    paddingBottom: 16
  }, props);
}).withConfig({
  displayName: "Panel__PanelTitle",
  componentId: "sc-fi8yaj-1"
})(["border-bottom:", ";"], function (props) {
  return props.large ? "none" : "1px solid " + colors.secondaryGray;
});
export var PanelTitleHeading = _styled(Box).withConfig({
  displayName: "Panel__PanelTitleHeading",
  componentId: "sc-fi8yaj-2"
})(["color:", ";line-height:33px;font-weight:300;font-size:", ";display:inline-block;"], colors.primaryBlue, function (props) {
  return props.large ? "36px" : "24px";
});
export var PanelTitleControls = _styled(Box).withConfig({
  displayName: "Panel__PanelTitleControls",
  componentId: "sc-fi8yaj-3"
})(["color:", ";line-height:33px;font-weight:300;font-size:", ";text-align:right;display:inline-block;float:right;"], colors.primaryBlue, function (props) {
  return props.large ? "36px" : "24px";
});
import firebase, { db } from 'lib/firebase';
import * as md5 from 'md5';

export const newsletterSignup = async email => {
  const docId = md5(email);
  return await db
    .collection('newsletter')
    .doc(docId)
    .set({
      email: email,
      ts: firebase.firestore.Timestamp.fromDate(new Date())
    });
};

export default newsletterSignup;

import React from 'react';
import { Layout } from 'shared-components';
import { Panel, Heading } from '@grownode/ui';

export function Terms() {
  return (
    <Layout.BasicPage title={<Heading>Terms of Service</Heading>}>
      <Panel id="terms-page">Terms of Service Page</Panel>
    </Layout.BasicPage>
  );
}

export default Terms;

import React from 'react';
import 'styled-components/macro';
import { lineColorByIndex, numberFormat } from 'lib/utils';
import { LABELS } from '../../constants';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

export const SensorLineChart = ({
  data = [],
  lines = [],
  dataKey,
  unit = '',
  range,
  minMax
}) => {
  if (!data || !lines || !dataKey) {
    return null;
  }

  const getTimeField = () => {
    let timeField = 'time';
    switch (range) {
      case 'day':
        timeField = 'dateHour';
        break;
      case 'week':
        timeField = 'simpleDate';
        break;
      case 'month':
      case 'year':
        timeField = 'simpleDateYear';
        break;
      default:
        timeField = 'hourDisplay';
        break;
    }
    return timeField;
  };

  const getTooltipTime = payload => {
    let timeField = payload?.time;
    switch (range) {
      case 'day':
        timeField = `${payload?.simpleDate} ${payload?.dateHour}`;
        break;
      case 'week':
        timeField = payload?.simpleDate;
        break;
      case 'month':
      case 'year':
        timeField = payload?.simpleDateYear;
        break;
      default:
        timeField = `${payload?.simpleDate} ${payload?.hourDisplay}`;
        break;
    }
    return timeField;
  };

  const CustomTooltip = (data) => {
    const { active, payload } = data;
    if (active && payload && payload.length) {
      const timeField = getTooltipTime(payload[0].payload);
      return (
        <div
          css={`
            background: #FFF;
            opacity: 1;
            padding: 4px;
            font-size: 12px;
            border: 1px solid #CCC;
            border-radius: 4px;
          `}
        >
          <div css={`text-align: center;`}>
            {timeField} - <strong>{LABELS[dataKey]}</strong>
          </div>
          <table>
            <tbody>
              {payload.map((item, i) => {
                const { nodeName } = item?.payload;
                const finalValue = numberFormat(Number(item.value), 2);
                return (
                  <tr
                    key={i}
                    css={`
                      color: ${item.stroke};
                    `}
                  >
                    <td>
                      <strong>{nodeName}</strong>:{' '}
                    </td>
                    <td>
                      {finalValue}{unit}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  const CustomizedXAxisTick = (data) => {
    const { x, y, payload } = data;
    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={12} x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y - 12.5})`}>
        <text fontSize={12} x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {numberFormat(Number(payload.value), 1)}{unit}
        </text>
      </g>
    );
  };

  const CustomizedDot = () => {
    return null;
  };

  const getMinMaxPadding = maxValue => {
    if (!maxValue) {
      return 1;
    }
    return Number(maxValue) / 100;
  };

  const timeField = getTimeField();
  const minMaxPadding = getMinMaxPadding(minMax?.max);
  const xDomain = [Number(minMax?.min) - Number(minMaxPadding) || 0, Number(minMax?.max) + Number(minMaxPadding) || null];

  return (
    <div
      className="SensorLineChart"
      css={`
        border-bottom: 1px solid #CCC;
        margin-bottom: 16px;
      `}
    >
      <ResponsiveContainer
        width="99%"
        height={150}
      >
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          {lines.map((line, i) => {
            return (
              <Line
                key={i}
                data={data[line.sensorId]}
                type="monotone"
                dataKey="value"
                stroke={lineColorByIndex(i)}
                dot={<CustomizedDot />}
              />
            );
          })}

          <CartesianGrid
            stroke="#ccc"
            strokeDasharray="5 5"
          />

          <XAxis
            dataKey={timeField}
            tick={<CustomizedXAxisTick />}
          />

          <YAxis
            tick={<CustomizedYAxisTick />}
            type="number"
            domain={xDomain}
          />

          <Tooltip
            allowEscapeViewBox={{ x: false, y: false }}
            content={<CustomTooltip />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SensorLineChart;

import React from 'react';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import {
  DialogModal,
  DialogModalHeader,
  DialogModalCloser,
  DialogModalBody,
  Icon,
  Button,
  styles
} from '@grownode/ui';
import { Close } from '@styled-icons/evaicons-solid/Close';

export const AreYouSure = ({ modalOpen, onClose, onComplete, isLoading, children }) => {
  return (
    <DialogModal
        isOpen={modalOpen}
        onBackgroundClick={onClose}
        onEscapeKeydown={onClose}
        css={`width: 25rem; max-height: 9rem;`}
      >
        <DialogModalHeader>
          Are you sure?
          <DialogModalCloser onClick={() => onClose()}>
            <Icon icon={Close} size={20} css={`color: ${styles.colors.primaryBlue};`} />
          </DialogModalCloser>
        </DialogModalHeader>
        <DialogModalBody
          css={`min-height: auto; padding: 8px 16px;`}
        >
          <Box css={`
            overflow-y: scroll;

            & > div {
              padding: 0;
            }
          `}>
            {children}
            <Box css={`text-align: right; margin-top: 16px;`}>
              <Button
                variant="secondary"
                type="button"
                onClick={onClose}
                height={40}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="button"
                onClick={onComplete}
                height={40}
                disabled={isLoading}
                css={`margin-left: 8px !important;`}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </DialogModalBody>
      </DialogModal>
  );
};

export default AreYouSure;

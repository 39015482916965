import React from "react";
import { useParams } from "react-router-dom";
import { Layout } from "shared-components";
import { TabNav } from "./components/TabNav";
import { Heading } from "@grownode/ui";

export function Settings() {
	const { sub } = useParams();
  const item = (!sub) ? 'grows' : sub;
	return (
		<Layout.BasicPage title={<Heading>Account Settings</Heading>}>
			<TabNav sub={item} />
		</Layout.BasicPage>
	);
}

export default Settings;

import { css } from "styled-components/macro";
import React from "react";
import {
  Column,
  Row,
  Text,
  //Icon,
  styles
} from "@grownode/ui";
import { Box } from "atomic-layout";
import * as Yup from "yup";

const PassFailToggle = ({ pass, ...restProps }) =>
	pass ? (
		<span>*</span>
	) : (
		<span>X</span>
	);

const hasUppercase = string =>
	Yup.string()
		.matches(/[A-Z]/)
		.isValidSync(string);
const hasNumber = string =>
	Yup.string()
		.matches(/[0-9]/)
		.isValidSync(string);
const hasEnoughCharacters = string =>
	Yup.string()
		.min(7)
		.isValidSync(string);

export function PasswordWizard({ password }) {
	return (
		<Box
			css={`
				font-size: 12px;
				color: ${styles.colors.grayDark};
			`}
			marginBottom={20}
		>
			<Box>
				<Text>Create a password. It must contain:</Text>
			</Box>
			<Row gap={5}>
				<Column
					cols={12}
					flex
					alignItems="center"
					css={css`
						color: ${() =>
							hasEnoughCharacters(password)
								? styles.colors.secondaryGreen
								: styles.colors.grayDark};
					`}
				>
					<PassFailToggle
						size={18}
						pass={hasEnoughCharacters(password)}
						css="margin-right: 10px;"
					/>

					<Text as="span">At least 7 characters</Text>
				</Column>

				<Column
					cols={12}
					flex
					alignItems="center"
					css={css`
						color: ${() =>
							hasUppercase(password)
								? styles.colors.secondaryGreen
								: styles.colors.grayDark};
					`}
				>
					<PassFailToggle
						size={18}
						pass={hasUppercase(password)}
						css="margin-right: 10px;"
					/>
					<Text as="span">At least one capital letter</Text>
				</Column>
				<Column
					cols={12}
					flex
					alignItems="center"
					css={css`
						color: ${() =>
							hasNumber(password)
								? styles.colors.secondaryGreen
								: styles.colors.grayDark};
					`}
				>
					<PassFailToggle
						size={18}
						pass={hasNumber(password)}
						css="margin-right: 10px;"
					/>
					<Text as="span">At least one number</Text>
				</Column>
			</Row>
		</Box>
	);
}

export default PasswordWizard;

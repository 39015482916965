export const initialState = {};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "APP_FATAL_ERROR":
      const error = action.payload || null;
      return {
        ...state,
        error: error
      };
    default:
      return state;
  }
};

export default appReducer;

import { db } from '../../firebase';

export const saveContactInfo = async ({
  uid,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  phone
}) => {
  try {
    await db.collection('user-accounts').doc(uid).set({
      address1,
      address2,
      city,
      state,
      zip,
      country,
      phone
    }, { merge: true });
  } catch (e) {
    console.log(e.message);
    throw new Error('CONTACT_INFO_UPDATE_ERROR');
  }
};

export default saveContactInfo;

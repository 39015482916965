import React from 'react';
import { Tile } from 'shared-components';
import { Icon } from '@grownode/ui';
import 'styled-components/macro';
import { List } from '@styled-icons/fa-solid/List';

export const TasksTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {

  return (
    <Tile
      title={<><Icon icon={List} size={18} /> Tasks</>}
      id="spaces_tasks"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={false}
      content={
        <>
          <div
            css={`
              font-size: 28px;
              font-weight: 400;
              text-align: center;
              height: 1.2em;
            `}
          >
            ?
          </div>
          <div
            css={`
              text-align: center;
              font-size: 12px;
            `}
          >
            None
          </div>
        </>
      }
    />
  )
};

export default TasksTile;

import { db } from '../../firebase';

export const setCommunicationPreferences = async (uid, prefs) => {
  try {
    const data = {};
    prefs.forEach(pref => {
      data[pref[0]] = pref[1];
    });
    await db.collection('email-preferences').doc(uid).set(data, { merge: true });
  } catch (e) {
    console.log(e.message);
    throw e;
  }
};

export default setCommunicationPreferences;

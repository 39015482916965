import axios from 'axios';

export const register = async ({
  email,
  firstName,
  lastName,
  companyName,
  password,
  defaultUnits,
  defaultTimeZone,
  defaultTimeZoneOffset
}) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/registration`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        email,
        firstName,
        lastName,
        companyName,
        defaultUnits,
        defaultTimeZone,
        defaultTimeZoneOffset,
        password
      },
      {
        headers: {
          'Content-type': 'application/json'
        },
        validateStatus: status => {
          return status >= 200 && status < 500;
        }
      }
    );

    return response;
  } catch (e) {
    throw e;
  }
};

export default register;

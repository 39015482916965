import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import { Loader, Icon, styles } from '@grownode/ui';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { Room } from '@styled-icons/material-outlined/Room';
import zoneTreeStyles from './zoneTree.module.scss';

function createLevel({
  level,
  key,
  name,
  onChange,
  nodesExpanded,
  setNodesExpanded,
  sub,
  showArchived
}) {
  const hasChildren = level?.items?.length > 0;
  return (
    <TreeNode
      key={key}
      name={name}
      data={level}
      hasChildren={hasChildren}
      onChange={onChange}
      nodesExpanded={nodesExpanded}
      setNodesExpanded={setNodesExpanded}
      isOpen={nodesExpanded.includes(level.id)}
      sub={sub || false}
      showArchived={showArchived}
    >
      {level?.items?.length && (
        level.items.map((item, i) => {
          return createLevel({
            level: item,
            key: key + '-' + i,
            name: item.label,
            onChange,
            nodesExpanded,
            setNodesExpanded,
            sub: true,
            showArchived
          });
        })
      )}
    </TreeNode>
  );
}

function TreeNode({
  name,
  data,
  hasChildren,
  onChange,
  isOpen,
  nodesExpanded,
  setNodesExpanded,
  sub,
  children,
  showArchived
}) {
  if (!showArchived && data.active === false) {
    return null;
  }
  return (
    <div
      className={zoneTreeStyles.tree + ' ' + (hasChildren ?  zoneTreeStyles.branch : zoneTreeStyles.leaf)}
      css={`
        padding-left: ${sub ? 16 : 0}px;
      `}
    >
      <div
        className={(data.active === false ? zoneTreeStyles.inactive : '')}
      >
        <span>
          {hasChildren === true && (
            <Icon
              className={isOpen ? zoneTreeStyles.arrowDown : zoneTreeStyles.arrowRight}
              icon={ChevronRight}
              size={20}
              css={`
                &:hover {
                  color: ${styles.colors.secondaryRed};
                }
              `}
              onClick={() => {
                let items = nodesExpanded;
                if (isOpen) {
                  items = nodesExpanded.filter((item) => {
                    return item !== data.id
                  })
                } else {
                  items = [
                    ...items,
                    data.id
                  ];
                }
                setNodesExpanded(items);
              }}
            />
          )}
          {hasChildren !== true && (
            <Icon icon={Room} size={20} />
          )}
        </span>
        <span
          className={zoneTreeStyles.label}
          onClick={() => onChange(data.id)}
        >
          {name}
        </span>
      </div>
      {children.length > 0 && (
        <div
          className="children"
          css={`
            display: ${isOpen ? 'block' : 'none'}
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
}

function showAll(level) {
  let tmpNodes = [];
  if (level?.items?.length) {
    level.items.forEach(item => {
      tmpNodes.push(item.id);
      if (item?.items.length) {
        tmpNodes = [
          ...tmpNodes,
          ...showAll(item)
        ];
      }
    });
  }
  return tmpNodes;
}

export function ZoneTree({
  masterParentName,
  zonesLoading,
  data,
  onChange,
  showArchived = false,
  expandAll = false
}) {
  const [nodesExpanded, setNodesExpanded] = useState([]);
  const [lastExpansion, setLastExpansion] = useState(!expandAll);

  useEffect(() => {
    if(data.length) {
      if (expandAll !== lastExpansion) {
        if (expandAll) {
          const nodes = showAll({ id: null, items: data });
          setNodesExpanded([
            null,
            ...nodes
          ]);
        } else {
          setNodesExpanded([]);
        }
        setLastExpansion(expandAll);
      }
    }
  }, [data, expandAll, lastExpansion]);

  if (zonesLoading) {
    return <Loader color="primaryBlue" size={30} />;
  }
  
  return createLevel({
    level: { id: null, items: data, active: true },
    name: masterParentName,
    key: 0,
    onChange: onChange,
    nodesExpanded,
    setNodesExpanded,
    showArchived
  });
}

export default ZoneTree;

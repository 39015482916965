import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@grownode/ui";

const messages = {
	"auth/user-not-found": "Incorrect Email/Password, please try again.",
	"auth/wrong-password": "Incorrect Email/Password, please try again.",
	"auth/maintenance-mode": "Login Disabled for site maintenance",
	CLIENT_ALREADY_REGISTERED: (
		<span>
			A dashboard user already exists for that email, did you{" "}
			<Link as={NavLink} to="/forgot-password">
				forget your password
			</Link>
			?
		</span>
	),
	CLIENT_SSN_NOT_MATCHING: (
		<span>
			We're sorry, that email or SSN does not match our records. Please check
			the primary email and SSN associated with your FDR account.
		</span>
	),
	CLIENT_DOES_NOT_EXIST: (
		<span>
			We're sorry, that email or SSN does not match our records. Please check
			the primary email and SSN associated with your FDR account.
		</span>
	),
	CLIENT_LAW_FIRM_ID_NOT_MATCHING_DOMAIN: (
		<span>
			We're sorry, that email or SSN does not match our records. Please check
			the primary email and SSN associated with your FDR account.
		</span>
	),
	UNHANDLED_ERROR: "Oops! Something went wrong!"
};

export const FormError = ({ errorCode }) => {
	return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

import { useEffect } from "react";
import { setModel, setCompanyList, setCompanyContext, setUserGateways, setNewGateways } from "shared-reducers/models";
import firebase from "lib/firebase";

const db = firebase.firestore();

export function useDataModelsListener(uid, models, dispatch, companyId, userRecord) {
  useEffect(() => {
    if (window.dataListeners !== undefined) {
      window.dataListeners.forEach(listener => {
        for (const [key, func] of Object.entries(listener)) {
          if (typeof key === "string" && typeof func === "function") {
            func();
          }
        }
      });
    }
    if (uid !== null && companyId !== null && models !== undefined) {
      const list = models.map(model => {
        let doc = db
          .collection("companies")
          .doc(companyId)
          .collection("models")
          .doc(model);
        return {
          [model]: doc.onSnapshot(docSnapshot => {
            try {
              const modelData = docSnapshot.data();
              if (modelData !== undefined) {
                dispatch(setModel({ [model]: modelData }));
              }
            } catch (e) {
              console.log(e.message);
            }
          })
        };
      });

      const gatewaysSnap = db.collection("gateways")
        .where('uid', '==', uid)
        .where('companyId', '==', null)
        .onSnapshot(gatewaysSnapshot => {
          gatewaysSnapshot.docs.forEach(doc => {
            dispatch(setUserGateways({
              [doc.id]: doc.data()
            }));
          });
        });

      list.push({
        'privateGatways': gatewaysSnap
      });

      const newGatewaysSnap = db.collection("new-gateways")
        .doc(uid)
        .onSnapshot(newGatewaysSnapshot => {
          if (newGatewaysSnapshot.exists) {
            const data = newGatewaysSnapshot.data();
            dispatch(setNewGateways(data.gatewayList));
          }
        });

      list.push({
        'newGatways': newGatewaysSnap
      });

      let companySnap = db.collection("companies")
        .doc(companyId)
        .onSnapshot(companySnapshot => {
          const document = companySnapshot.data();
          if (document.members[uid] !== undefined) {
            dispatch(setCompanyContext({
              [companyId]: {
                ...document
              }
            }));
          }
        });

      list.push({
        'companyContext': companySnap
      });

      userRecord.memberOf.forEach(company => {
        let dbRef = db.collection("companies").doc(company);
        dbRef.get().then(data => {
          if (data.exists) {
            let document = data.data();
            if (document.members[uid] !== undefined) {
              dispatch(setCompanyList({
                [company]: {
                  name: document.name,
                  savedFilters: document.savedFilters,
                  zoneTree: document.zoneTree
                }
              }));
            }
          }
        });
      });

      window.dataListeners = list;
    }
  }, [uid, models, dispatch, companyId, userRecord]);

  return true;
}

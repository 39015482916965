import React, { useState, useEffect } from 'react';
import 'styled-components/macro';
import { Loader, Icon, styles } from '@grownode/ui';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { Room } from '@styled-icons/material-outlined/Room';
import treeStyles from './tree.module.scss';

function createLevel({
  level,
  key,
  name,
  onChange,
  nodesExpanded,
  setNodesExpanded,
  showArchived,
  subLevel = 0,
  selectedId,
  showMasterParent,
  idPrefix,
  prefix
}) {
  const hasChildren = level?.items?.length > 0 && level?.items?.filter(item => item.active !== false).length > 0;
  if (subLevel === 1) {
    prefix = level.id;
  }
  return (
    <TreeNode
      key={key}
      name={name}
      data={level}
      hasChildren={hasChildren}
      onChange={onChange}
      nodesExpanded={nodesExpanded}
      setNodesExpanded={setNodesExpanded}
      isOpen={nodesExpanded.includes(idPrefix ? `${prefix}-${level.id}` : level.id)}
      showArchived={showArchived}
      subLevel={subLevel}
      selectedId={selectedId}
      showMasterParent={showMasterParent}
      idPrefix={idPrefix}
      prefix={prefix}
    >
      {level?.items?.length && (
        level.items.map((item, i) => {
          return createLevel({
            level: item,
            key: key + '-' + i,
            name: item.label,
            onChange,
            nodesExpanded,
            setNodesExpanded,
            showArchived,
            subLevel: subLevel + 1,
            selectedId,
            idPrefix,
            prefix
          });
        })
      )}
    </TreeNode>
  );
}

function TreeNode({
  name,
  data,
  hasChildren,
  onChange,
  isOpen,
  nodesExpanded,
  setNodesExpanded,
  showArchived,
  subLevel,
  selectedId,
  showMasterParent,
  idPrefix,
  prefix,
  children
}) {
  if (!showArchived && data.active === false) {
    return null;
  }

  const itemId = idPrefix && prefix ? `${prefix}-${data.id}` : data.id;
  const isMasterParent = subLevel === 0;
  const cannotShow = isMasterParent && !showMasterParent;
  const itemSelectedClass = selectedId[0] === data.id && (idPrefix ? prefix === selectedId[1] : true) ? treeStyles.selected : null;
  const onMouseIn = typeof data.onMouseOver === 'function' ? data.onMouseOver : () => {};
  const onMouseBye = typeof data.onMouseOut === 'function' ? data.onMouseOut : () => {};
  
  return (
    <div
      className={treeStyles.tree + ' ' + (hasChildren ?  treeStyles.branch : treeStyles.leaf)}
      onMouseOver={onMouseIn}
      onMouseOut={onMouseBye}
    >
      {!cannotShow && (
        <div
          className={treeStyles.node + ' ' + itemSelectedClass + ' ' + (data.active === false ? treeStyles.inactive : '')}
          onClick={() => onChange(itemId, data)}
        >
          <span
            css={`padding-left: ${(subLevel * 16) + (data.showLeafIcon === false && !data.icon && !hasChildren ? 20 : 0)}px;`}
          >
            {hasChildren === true && (
              <Icon
                className={isOpen ? treeStyles.arrowDown : treeStyles.arrowRight}
                icon={ChevronRight}
                size={20}
                css={`
                  &:hover {
                    color: ${styles.colors.secondaryRed};
                  }
                `}
                onClick={ev => {
                  ev.stopPropagation();
                  let items = nodesExpanded;
                  if (isOpen) {
                    items = nodesExpanded.filter((item) => {
                      return item !== (idPrefix ? `${prefix}-${data.id}` : data.id)
                    })
                  } else {
                    items = [
                      ...items,
                      (idPrefix ? `${prefix}-${data.id}` : data.id)
                    ];
                  }
                  setNodesExpanded(items);
                }}
              />
            )}
            {(hasChildren !== true && data.showLeafIcon !== false && !data.icon) && (
              <Icon icon={Room} size={16} />
            )}
            {data.icon && (
              <>{data.icon}</>
            )}
          </span>
          <span
            className={treeStyles.label}
          >
            {name}
          </span>
        </div>
      )}
      {hasChildren && (
        <div
          className="children"
          css={`display: ${isOpen || data.id === null ? 'block' : 'none'}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

function showAll(level, subLevel = 0, idPrefix, prefix) {
  let tmpNodes = [];
  if (subLevel === 1) {
    prefix = level.id;
  }
  if (level?.items?.length) {
    level.items.forEach(item => {
      tmpNodes.push((idPrefix ? `${prefix}-${item.id}` : item.id));
      if (item?.items.length) {
        tmpNodes = [
          ...tmpNodes,
          ...showAll(item, subLevel++, idPrefix, prefix)
        ];
      }
    });
  }
  return tmpNodes;
}

export function Tree({
  masterParentName,
  showMasterParent = true,
  isLoading,
  data,
  onChange,
  showArchived = false,
  expandAll = false,
  selectedId = [],
  defaultExpanded = [],
  idPrefix = false,
  prefix = '',
}) {
  const [nodesExpanded, setNodesExpanded] = useState(defaultExpanded);
  const [lastExpansion, setLastExpansion] = useState(!expandAll);

  useEffect(() => {
    setNodesExpanded(defaultExpanded);
  }, [defaultExpanded]);

  useEffect(() => {
    if(data.length) {
      if (expandAll !== lastExpansion) {
        if (expandAll) {
          const nodes = showAll({ id: null, items: data }, idPrefix);
          setNodesExpanded([
            null,
            ...nodes
          ]);
        } else {
          setNodesExpanded(defaultExpanded);
        }
        setLastExpansion(expandAll);
      }
    }
  }, [data, expandAll, lastExpansion, defaultExpanded, idPrefix]);

  if (isLoading) {
    return (
      <div css={`text-align: center;`}>
        <Loader color="primaryBlue" size={30} />
      </div>
    );
  }
  return createLevel({
    level: { id: null, items: data, active: true },
    name: masterParentName,
    key: 0,
    onChange: onChange,
    nodesExpanded,
    setNodesExpanded,
    showArchived,
    selectedId,
    showMasterParent,
    idPrefix,
    prefix
  });
}

export default Tree;

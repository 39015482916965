import React from 'react';
import { Tile } from 'shared-components';
import { Icon } from '@grownode/ui';
import 'styled-components/macro';
import { getCanopy } from 'shared-selectors';
import { Area } from '@styled-icons/boxicons-regular/Area';

export const CanopyTile = ({
  thisSpace = null,
  spaces,
  desktopWidth,
  tabletWidth,
  mobileWidth
}) => {

  const canopyAtLevel = spaces ? getCanopy(spaces, thisSpace) : 0;

  return (
    <Tile
      title={<><Icon icon={Area} size={18} /> Canopy</>}
      id="spaces_canopy"
      desktopWidth={desktopWidth}
      tabletWidth={tabletWidth}
      mobileWidth={mobileWidth}
      isExpandable={false}
      content={
        <>
          <div
            css={`
              font-size: 28px;
              font-weight: 400;
              text-align: center;
              height: 1.2em;
            `}
          >
            {canopyAtLevel} <span css={`font-size: 12px;`}>Ft<sup>2</sup></span>
          </div>
          <div
            css={`
              text-align: center;
              font-size: 12px;
            `}
          >
            Plantable Area
          </div>
        </>
      }
    />
  )
};

export default CanopyTile;

import 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box } from 'atomic-layout';
import {
	Text,
	Panel,
	PanelTitle,
	Link,
	LoadingButton,
	FormMessage,
	PanelTitleHeading,
	Icon
} from '@grownode/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { DashPassword, DashInput, DashForm } from 'shared-components';
import { oobCodeData, resetPassword } from 'lib/api';

import { FormError, PasswordWizard } from './components';

export function ResetPassword() {
	const [error, setError] = useState();
	const [oobCodeInfo, setOobCodeInfo] = useState();
	const navigate = useNavigate();
	const requestedOobCode = new URLSearchParams(
		window.location.href.split('?')[1]
	).get('oobCode');

	useEffect(() => {
		async function getInfo() {
			if (requestedOobCode !== null) {
				try {
					const info = await oobCodeData({
						oobCode: requestedOobCode
					});
					if (info.error !== undefined) {
						navigate('/forgot-password?expired=true');
					} else {
						setOobCodeInfo(info);
					}
				} catch (e) {
					setError(e.message);
				}
			}
		}
		getInfo();
	}, [requestedOobCode, navigate]);

	if (!requestedOobCode) {
		return <Navigate to="/" />;
	}

	return (
    <Box>
      <Box maxWidth={500} paddingVertical={48} marginHorizontal="auto">
        <Panel paddingVertical={48} paddingHorizontal={60}>
          <PanelTitle
            large
            css={`
              text-align: center;
              margin-bottom: 40px !important;
            `}
          >
            <PanelTitleHeading large>Reset Your Password</PanelTitleHeading>
          </PanelTitle>

          <Formik
            initialValues={{
              email: oobCodeInfo !== undefined ? oobCodeInfo.email : '',
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .matches(/[A-Z]/, 'Password does not meet criteria')
                .matches(/[0-9]/, 'Password does not meet criteria')
                .min(7, 'Password does not meet criteria')
                .required('Required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords do not match')
                .required('Required'),
            })}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              try {
                const response = await resetPassword({
                  oobCode: requestedOobCode,
                  password: values.password,
                });
                if (response.data.error) {
                  throw new Error(response.data.error);
                } else {
                  navigate('/');
                }
              } catch (e) {
                setError(e.message);
              }
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <DashForm>
                <Text
                  css={`
                    font-weight: 100;
                  `}
                >
                  Enter a new password below.
                </Text>
                {error && (
                  <FormMessage variant="error">
                    <FormError errorCode={error} />
                  </FormMessage>
                )}
                <DashInput
                  id="email"
                  name="email"
                  label="Email"
                  value={oobCodeInfo !== undefined ? oobCodeInfo.email : ''}
                  disabled={true}
                  css={`
                    color: #4a4a4a !important;
                  `}
                />
                <PasswordWizard password={props.values.password} />
                <DashPassword
                  id="password"
                  name="password"
                  label="New Password"
                  successMsg="Password is good"
                  disabled={props.isSubmitting}
                />
                <DashPassword
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Re-enter New Password"
                  successMsg="Passwords match"
                  disabled={props.isSubmitting}
                />
                <LoadingButton
                  height={40}
                  type="submit"
                  variant="tertiary"
                  rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                  isLoading={props.isSubmitting}
                >
                  Reset Password
                </LoadingButton>
              </DashForm>
            )}
          </Formik>
          <Box
            marginTop={40}
            css={`
              text-align: center;
            `}
          >
            <Text as="span">Need help? Contact Customer Service</Text>
            <br />
            <Link href="tel:1-800-Grow-Node">1-800-Grow-Node</Link>
          </Box>
        </Panel>
      </Box>
    </Box>
  );
}

export default ResetPassword;

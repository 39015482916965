import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@grownode/ui';
import { NavLink } from 'react-router-dom';
import 'styled-components/macro';

export function Alerts() {
  const gatewayState = useSelector(state => state.models.gateways);
  const userState = useSelector(state => state.userRecord);
  const companyContext = useSelector(state => state.models.companyContext);
  const alerts = [];

  if (gatewayState && Object.keys(gatewayState).length === 0) {
    // Add an alert for having no gateways set up
    alerts.push({
      title: 'You have no gateways set up, click this alert to get started',
      link: '/hardware/gateways/new'
    });
  }

  // Alert for when the current company context doesn't have default time zone and display units set
  const currentContext = userState?.defaultCompany;
  if (companyContext && companyContext[currentContext] && (!companyContext[currentContext]?.defaultTimeZone || !companyContext[currentContext]?.defaultUnits)) {
    alerts.push({
      title: `'${companyContext[currentContext].name}' has no default Time Zone or Display Units set.`,
      link: `/settings/grows`
    });
  }

  return (
    <ul>
      {alerts.map((alert, key) => {
        return (
          <li key={key}>
            <Link as={NavLink} to={alert.link}>{alert.title}</Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Alerts;

import React, { useState, useEffect } from 'react';
import { Box, Composition } from 'atomic-layout';
import { Icon } from '@grownode/ui';
import { DashInput } from 'shared-components';
import "styled-components/macro";
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';

export const FilteringSelect = ({
  id,
  name,
  value,
  valueLabel,
  label,
  options,
  onChange,
  isSubmitting = false,
  maxOptions = 7,
  optionHeight = 33
}) => {
  const [dropOpen, setDropOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options || []);
  const [currentLabel, setCurrentLabel] = useState(valueLabel || value);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [menuWidth, setMenuWidth] = useState(0);
  const [menuScrollTop, setMenuScrollTop] = useState(0);

  const getLabelArray = () => {
    const labelArray = [];
    options.forEach(item => {
      labelArray.push(item.label);
    });
    return labelArray;
  };

  const handleKeys = ev => {
    switch(ev.keyCode) {
      case 27: // Esc key
        setDropOpen(false);
        break;
      case 13: // Enter Key
        ev.preventDefault();
        ev.stopPropagation();
        setCurrentLabel(filteredOptions[selectedIndex].label);
        setDropOpen(false);
        const labels = getLabelArray();
        if (labels.includes(filteredOptions[selectedIndex].label) && typeof onChange === 'function') {
          onChange(filteredOptions[selectedIndex].value, filteredOptions[selectedIndex].label);
        }
        break;
      case 38: // Up
        setDropOpen(true);
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
          scrollToOption(selectedIndex - 1);
        }
        break;
      case 40: // Down
        setDropOpen(true);
        if (selectedIndex < filteredOptions.length -1) {
          setSelectedIndex(selectedIndex + 1);
          scrollToOption(selectedIndex + 1);
        }
        break;
      default:
        // Filters options as we type
        break;
    }
  };

  const scrollToOption = index => {
    const selectedNode = document.querySelector('#FilteringSelect-' + id + ' .FilteringSelectOption-' + index);
    if (selectedNode && index > 6) {
      setMenuScrollTop(selectedNode.offsetHeight * (index - 6));
    }
  }

  const handleFocus = ev => {
    setDropOpen(true);
  };

  const handleBlur = ev => {
    window.setTimeout(() => {
      setDropOpen(false);
    }, 250);
  };

  const handleChange = ev => {
    setCurrentLabel(ev.target.innerHTML);
    const filteringResult = options.filter(item => {
      return item.label.indexOf(ev.target.value) === 0;
    });
    setSelectedIndex(0);
    const sortedOptions = optionsSorted(filteringResult);
    setFilteredOptions(sortedOptions);
    const labels = getLabelArray();
    if (labels.includes(ev.target.innerHTML) && typeof onChange === 'function') {
      onChange(ev.target.value, ev.target.innerHTML);
    }
  };

  const handleItemClick = (value, label) => {
    setCurrentLabel(label);
    setDropOpen(false);
    const labels = getLabelArray();
    if (labels.includes(label) && typeof onChange === 'function') {
      onChange(value, label);
    }
  };

  const optionsSorted = options => {
    return options.sort((a, b) => {
      if (a.label === b.label) {
        return 0;
      }
      return a.label > b.label ? 1 : -1;
    });
  };

  useEffect(() => {
    const sortedOptions = optionsSorted(options);
    setFilteredOptions(sortedOptions);
  }, [options]);

  useEffect(() => {
    setCurrentLabel(valueLabel);
  }, [valueLabel]);

  useEffect(() => {
    if (dropOpen) {
      const container = document.getElementById(id).parentNode.parentNode.parentNode;
      if (container) {
        // Accounting for negative margin for arrow clicker element
        setMenuWidth(container.offsetWidth - 6);
      }
    }
  }, [dropOpen, id]);

  const menuHeight = optionHeight * maxOptions;

  return (
    <Box id={'FilteringSelect-' + id}>
      <Composition
        areas={`search controls`}
        templateCols="auto 40px"
        templateRows="auto"
        alignItems="end"
      >
        {({ Search, Controls }) => (
          <>
            <Search>
              <DashInput
                id={id}
                name={name}
                value={currentLabel}
                label={label}
                type="text"
                autoComplete="off"
                css={`
                  border-right: none;
                `}
                onChange={handleChange}
                onKeyDown={handleKeys}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Search>
            <Controls>
              <Box
                css={`
                  height: 38px;
                  width: 40px;
                  margin-left: -5px;
                  background: #FFF;
                  border-top: 1px solid;
                  border-bottom: 1px solid;
                  border-right: 1px solid;
                  border-radius: 0px 6px 6px 0px;
                `}
              >
                <Icon
                  icon={ChevronDown}
                  size={30}
                  css={`
                    margin-top: 3px;
                    cursor: pointer;
                    &:hover {
                      opacity: 0.7;
                    }
                  `}
                  onClick={() => {
                    document.getElementById(id).focus();
                  }}
                />
              </Box>
            </Controls>
          </>
        )}
      </Composition>
      <Box
        scrollTop={menuScrollTop}
        css={`
          display: ${dropOpen ? 'block' : 'none'};
          position: absolute;
          z-index: 1000;
          background: #FFF;
          border: 1px solid;
          border-top: none;
          margin-top: -5px;
          width: ${menuWidth}px;
          border-top: 1px solid #CECECE;
          max-height: ${menuHeight}px;
          overflow-y: scroll;
        `}
      >
        <Box css={`margin-top: -${menuScrollTop}px;`}>
          {filteredOptions.length < 1 && (
            <Box
              css={`text-align: center; padding: 6px;`}
            >
              <em>No options match search</em>
            </Box>
          )}
          {filteredOptions.map((item, i) => {
            return (
              <Box
                key={i}
                className={'FilteringSelectOption-' + i}
                onClick={() => handleItemClick(item.value, item.label)}
                onMouseOver={() => {
                  setSelectedIndex(i);
                }}
                css={`
                  cursor: pointer;
                  padding: 6px;
                  background: ${selectedIndex === i ? '#CECECE' : 'transparent'};
                  height: ${optionHeight - 12}px;
                `}
              >
                {item.label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default FilteringSelect;

import React from 'react';
import 'styled-components/macro';
import { Layout, SpacesBlock } from 'shared-components';
import { SpacesTile } from 'shared-components/SpacesBlock/components';
import { useSelector } from 'react-redux';
import {
  Link,
  Breadcrumbs,
  BreadcrumbsItem
} from '@grownode/ui';
import { NavLink } from 'react-router-dom';
import { getSubSpaces } from 'shared-selectors';

export function Spaces() {
  const zones = useSelector((state) => state?.models?.zones || {});
  const userState = useSelector((state) => state?.userRecord);
  const companyState = useSelector((state) => state?.models?.companyContext);
  const currentCompany = (companyState && userState) ? companyState[userState?.defaultCompany] : {};
  const subSpaces = zones ? getSubSpaces(zones, 'root') : [];

  return (
    <Layout.BasicPage>
      <Breadcrumbs>
        <BreadcrumbsItem>
          <Link as={NavLink} to="/dashboard">{currentCompany?.name}</Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem>Spaces</BreadcrumbsItem>
      </Breadcrumbs>
      <SpacesTile
        spaces={zones}
        thisSpace={'root'}
        desktopWidth="13.3%"
        mobileWidth="100%"
        subSpaces={subSpaces}
      />
      <SpacesBlock currentSpace={'root'} />
    </Layout.BasicPage>
  );
}

export default Spaces;
